import React from 'react';
import ImgOne from '../../../images/product-imgs/IRG3/PK_IRG3 10W-40 MA2_1L_F.png';
import Layout from '../../../Layout/Layout';
import { Grid } from '@mui/material';
import ProductDetails from '../../productdetails';

const IRG3 = () => {
  return (
    <Layout>
      <Grid container>
        <Grid item xl={12} lg={12} md={12} xs={12}>
          <ProductDetails
            title="IRG3 10W-40 JASO MA2 Semi Synthetic 1L"
            price="Rs 1,670"
            img={ImgOne}
            product_desc="Idemitsu Riders' Gasoline Engine Oil - IRG3
            Enjoy the feeling of quick response even in urban areas. Formulated with Nano-Tailored Technolgy, IRG3 series engine oil offers engine protection in Stop & Go city traffic. So you can relax and focus on driving."
            adv_tit="Distinctive Characteristics for Unmatched Advantages:"
            p_adv_1="Engine protection and cleanliness"
            p_adv_2="Engine protection even in stop-and-go city traffic"
            p_adv_3="Excellent anti-wear protection"
            p_adv_4="Detergency at high temperature"
            p_adv_5="Enjoy the feeling of quick response even in urban areas"
          />
        </Grid>
      </Grid>
    </Layout>
  );
};

export default IRG3;
