import React from 'react';
import ImgOne from '../../../images/product-imgs/CVTF/PK CVTF 1L F.png';
// import ImgTwo from '../../../images/product-imgs/IFG3/PK_IFG3 0W-20_SP-GF-6A_4L_P.png';
import ProductDetails from '../../productdetails';
import Layout from '../../../Layout/Layout';
import { Grid } from '@mui/material';

const CVTF = () => {
  return (
    <Layout>
      <Grid container>
        <Grid item xl={12} lg={12} md={12} xs={12}>
          <ProductDetails
            title="Idemitsu Multi Vehicle CVTF 1L"
            price="Rs 3,150"
            img={ImgOne}
            product_desc="Idemitsu Multi Vehicle CVTF is the latest advanced continuous variable transmission fluid suitable for use in all modern CVT units. This special formulation consists of high quality hydro cracked base stock and various additives to enhance smooth & positive shifting under severe working conditions."
            adv_tit="Compatible with:"
            b_adv_1="Amix CVT Fluid-DC, CVT Fluid TC, CVT Fluid NS-1 / NS-2, CVTF 3320, Dia-Queen CVT Fluid_x0002_J1, ECVT Fluid / i-CVT Fluid, SCVT Fluid, CVT Oil, CVT Fluid Green-1 / Green IV, CVT Fluid 
            3320, Matic Fluid D, i-CVT/FG"
          />
        </Grid>
      </Grid>
    </Layout>
  );
};

export default CVTF;
