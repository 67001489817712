/* eslint-disable no-unused-vars */
import './style.css';
import React, { useEffect, useState } from 'react';
import { Grid, Typography } from '@mui/material';
import {
  ThemeColors,
  ThemeFontsFamily,
  ThemeFontWeight,
  FontSize,
} from '../../../../utils/Theme';
import Data from './store_data.json';
import Car from '../../../../images/services-images/car.png';
import Engine from '../../../../images/services-images/engine.png';
import Lubricant from '../../../../images/services-images/lubricant.png';
import ServicesResultCard from './resultcard';
import Buttons from '../../../../components/button/Buttons';
import SelectOpt from '../../../../components/selectopt';
import getAllData from '../../../../services/getAllData';
import Loader from '../../../../components/loader';
import RecaptchaComponent from '../../../../components/reCaptcha';
const VehicleFilter = () => {
  const [loading, setLoading] = useState(true);
  const [products, setproducts] = useState([]);
  const [type, setType] = useState('');
  const [optionsList, setOptionsList] = useState([]);
  const [makeOptions, setMakeOptions] = useState([]);
  const [make, setMake] = useState(null);
  const [modelOption, setModelOptions] = useState([]);
  const [model, setModel] = useState('');
  const [engineOptions, setEngineOptions] = useState([]);
  const [engine, setEngine] = useState('');
  const [fromYearOptions, setFromYearOptions] = useState([]);
  const [fromYear, setFromYear] = useState('');
  const [toYearOptions, setToYearOptions] = useState([]);
  const [toYear, setToYear] = useState('');
  const [oilData, setOilData] = useState([]);
  const key = process.env.REACT_APP_KEY;
  // console.log('str', products);

  const getProducts = async (recaptchaToken) => {
    try {
      const allProducts = await getAllData(recaptchaToken);
      // console.log('reCAPTCHA Token:', recaptchaToken);
      setproducts(allProducts);
      setLoading(false);
      // console.log(allProducts);
    } catch (error) {
      // console.log(error);
    }
  };

  // const [isVerified, setIsVerified] = useState(false);
  // const [recaptchaToken, setRecaptchaToken] = useState(null);
  // const recaptchaRef = useRef(null);
  useEffect(() => {
    // Remove duplicates vehicle type
    const vehicleTypes = [...new Set(products.map((item) => item.type))];
    // console.log(vehicleTypes);
    setOptionsList(vehicleTypes);
  }, [products]);

  const typeOpt = optionsList.map((item) => ({ label: item, value: item }));
  // get vehicle make
  const getVehicleMakes = (type, products) => {
    const filteredMake = products.filter((item) => item.type === type);
    const vehicleMake = [...new Set(filteredMake.map((item) => item.make))];
    setMakeOptions(vehicleMake);
    setModelOptions([]);
    setModel('');
    setEngine('');
    setToYear('');
    setFromYear('');
    setEngineOptions([]);
    setFromYearOptions([]);
    setToYearOptions([]);
    // console.log(vehicleMake);
  };

  useEffect(() => {
    getVehicleMakes(type, products);
  }, [type, products]);

  const makeOpt = makeOptions.map((item) => ({ label: item, value: item }));
  // const handleMaker = (value, e) => {
  //   console.log(e.target.value);
  // };

  // get vehicle model
  const getVehicleModel = (products, make) => {
    const filteredModel = products.filter((item) => item.make === make);
    const vehicleModel = [...new Set(filteredModel.map((item) => item.model))];
    setModelOptions(vehicleModel);
    setEngine('');
    setToYear('');
    setFromYear('');
    setEngineOptions([]);
    setFromYearOptions([]);
    setToYearOptions([]);
    // console.log(vehicleModel);
  };

  useEffect(() => {
    getVehicleModel(products, make);
  }, [products, make]);
  const modelOpt = modelOption.map((item) => ({ label: item, value: item }));

  // get vehicle engine
  const getVehicleEngine = (products, model) => {
    const filteredEngine = products.filter((item) => item.model === model);
    // console.log(filteredEngine);
    const vehicleEngine = [
      ...new Set(filteredEngine.map((item) => item.engine)),
    ];
    setEngineOptions(vehicleEngine);
    setToYear('');
    setFromYear('');
    setFromYearOptions([]);
    setToYearOptions([]);
    // console.log(vehicleEngine);
  };

  useEffect(() => {
    getVehicleEngine(products, model);
  }, [products, model]);
  const engineOpt = engineOptions.map((item) => ({ label: item, value: item }));

  // get vehicle from year
  const getVehicleFromYear = (products, engine) => {
    const filteredFromYear = products.filter((item) => item.engine === engine);
    const vehicleFromYear = [
      ...new Set(filteredFromYear.map((item) => item.fromYear)),
    ];
    setFromYearOptions(vehicleFromYear);
    setToYear('');
    setToYearOptions([]);
    // console.log(vehicleFromYear);
  };

  useEffect(() => {
    getVehicleFromYear(products, engine);
  }, [products, engine]);
  const fromYearOpt = fromYearOptions.map((item) => ({
    label: item,
    value: item,
  }));

  // get vehicle to year
  const getVehicleToYear = (products, fromYear, type, make, model, engine) => {
    const filteredToYear = products.filter(
      (item) =>
        item.type === type &&
        item.make === make &&
        item.model === model &&
        item.engine === engine &&
        item.fromYear === fromYear
    );
    console.log(filteredToYear);
    const vehicleToYear = [
      ...new Set(filteredToYear.map((item) => item.toYear)),
    ];
    console.log(vehicleToYear);
    setToYearOptions(vehicleToYear);
    // console.log(vehicleToYear);
  };

  useEffect(() => {
    getVehicleToYear(products, fromYear, type, make, model, engine);
  }, [products, fromYear, type, make, model, engine]);
  const toYearOpt = toYearOptions.map((item) => ({ label: item, value: item }));

  // get vehicle to year
  // const handleGetVehicleOil = () => {
  // };
  const getVehicleOils = (
    products,
    type,
    make,
    model,
    engine,
    fromYear,
    toYear
  ) => {
    const filteredOils = products.filter(
      (item) =>
        item.type === type &&
        item.make === make &&
        item.model === model &&
        item.engine === engine &&
        item.fromYear === fromYear &&
        item.toYear === toYear
    );
    console.log(filteredOils);
    setOilData(filteredOils);
  };

  useEffect(() => {
    getVehicleOils(products, type, make, model, engine, fromYear, toYear);
    getVehicleOils(products, type, make, model, engine, fromYear, toYear);
  }, [products, type, make, model, engine, fromYear, toYear]);

  return loading ? (
    <>
      <Loader />
      <RecaptchaComponent
        // ref={recaptchaRef}
        siteKey={key}
        action="LOGIN"
        onToken={getProducts}
      />
    </>
  ) : (
    <Grid container>
      <Grid item lx={12} lg={12} md={12} xs={12} sm={12}>
        <div className="services-product-filter">
          <div className="services-filter-imgs">
            <div className="services-img-group">
              <div className="services-img">
                <img src={Car} alt="VehicleImg" />
              </div>
            </div>
            <div className="services-img-group">
              <div className="services-img">
                <img src={Engine} alt="VehicleImg" />
              </div>
              <div className="services-img">
                <img src={Lubricant} alt="VehicleImg" />
              </div>
            </div>
          </div>
          <div className="service-search-main">
            <div className="services-chose-oil-heading">
              <div>
                <Typography
                  mb={2}
                  color={ThemeColors.BlackHeading}
                  sx={{
                    fontFamily: ThemeFontsFamily.Volkhov,
                    fontWeight: ThemeFontWeight.Bold,
                    fontSize: FontSize.Headings,
                    letterSpacing: '1.5px',
                  }}
                  variant="h1"
                >
                  CHOSE THE OIL
                </Typography>
              </div>
              <div>
                <Typography
                  mb={3}
                  color={ThemeColors.MainHeadings}
                  sx={{
                    fontFamily: ThemeFontsFamily.Volkhov,
                    fontWeight: ThemeFontWeight.Bold,
                    fontSize: FontSize.desc,
                    letterSpacing: '1.5px',
                  }}
                  variant="h1"
                >
                  FOR YOUR VEHICLE
                </Typography>
              </div>
            </div>
            <div className="service-search">
              <div className="searching-section-services">
                <div className="filter-search-field">
                  <div>
                    <select
                      className="select-input-opt"
                      onChange={(e) => {
                        setType(e.target.value);
                      }}
                      value={type}
                    >
                      <option selected hidden value="">
                        Vehicle Type
                      </option>
                      {optionsList.map((options, index) => {
                        return (
                          <option className="select-input-optn" value={options}>
                            {options}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  {/* <SelectOpt
                    placeholder={'Vehicle Type'}
                    handleChange={handleVehicle}
                    optionsList={vehicleTypes}
                    isDisabled={false}
                    bgColor={'#E7E7E7'}
                    value={vehicleType}
                  /> */}
                </div>
                <div className="filter-search-field">
                  <div>
                    <select
                      disabled={makeOptions.length === 0 ? true : false}
                      className="select-input-opt"
                      onChange={(e) => {
                        setMake(e.target.value);
                      }}
                      value={make}
                    >
                      <option selected hidden value="">
                        Vehicle Make
                      </option>
                      {makeOptions.map((options, index) => {
                        return (
                          <option className="select-input-optn" value={options}>
                            {options}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  {/* <SelectOpt
                    placeholder={'Vehicle Make'}
                    handleChange={handleMaker}
                    optionsList={vehicleMakers}
                    isDisabled={vehicleMakers.length === 0 ? true : false}
                    bgColor={'#E7E7E7'}
                    value={vehicleMaker}
                  /> */}
                </div>
                <div className="filter-search-field">
                  <div>
                    <select
                      disabled={modelOption.length === 0 ? true : false}
                      className="select-input-opt"
                      onChange={(e) => {
                        setModel(e.target.value);
                      }}
                      value={model}
                    >
                      <option selected hidden value="">
                        Vehicle Model
                      </option>
                      {modelOption.map((options, index) => {
                        return (
                          <option className="select-input-optn" value={options}>
                            {options}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  {/* <SelectOpt
                    placeholder={'Vehicle Model'}
                    handleChange={handleModel}
                    optionsList={vehicleModels}
                    isDisabled={vehicleModels.length === 0 ? true : false}
                    bgColor={'#E7E7E7'}
                    value={vehicleModel}
                  /> */}
                </div>
                <div className="filter-search-field">
                  <div>
                    <select
                      disabled={engineOptions.length === 0 ? true : false}
                      className="select-input-opt"
                      onChange={(e) => {
                        setEngine(e.target.value);
                      }}
                      value={engine}
                    >
                      <option selected hidden value="">
                        Vehicle Engine
                      </option>
                      {engineOptions.map((options, index) => {
                        return (
                          <option className="select-input-optn" value={options}>
                            {options}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  {/* <SelectOpt
                    placeholder={'Vehicle Modification'}
                    handleChange={handleModification}
                    optionsList={vehicleModifs}
                    isDisabled={vehicleModifs.length === 0 ? true : false}
                    bgColor={'#E7E7E7'}
                    value={vehicleModif}
                  /> */}
                </div>
                <div className="filter-search-field">
                  <div>
                    <select
                      disabled={fromYearOptions.length === 0 ? true : false}
                      className="select-input-opt"
                      onChange={(e) => {
                        setFromYear(e.target.value);
                      }}
                      value={fromYear}
                    >
                      <option selected hidden value="">
                        Vehicle From year
                      </option>
                      {fromYearOptions.map((options, index) => {
                        return (
                          <option className="select-input-optn" value={options}>
                            {options}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  {/* <SelectOpt
                    placeholder={'year'}
                    handleChange={handleYear}
                    optionsList={vehicleYears}
                    isDisabled={vehicleModifs.length === 0 ? true : false}
                    bgColor={'#E7E7E7'}
                    value={vehicleYear}
                  /> */}
                </div>
                <div className="filter-search-field">
                  <div>
                    <select
                      disabled={toYearOptions.length === 0 ? true : false}
                      className="select-input-opt"
                      onChange={(e) => {
                        setToYear(e.target.value);
                      }}
                      value={toYear}
                    >
                      <option selected hidden value="">
                        Vehicle To year
                      </option>
                      {toYearOptions.map((options, index) => {
                        return (
                          <option className="select-input-optn" value={options}>
                            {options}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  {/* <SelectOpt
                    placeholder={'year'}
                    handleChange={handleYear}
                    optionsList={vehicleYears}
                    isDisabled={vehicleModifs.length === 0 ? true : false}
                    bgColor={'#E7E7E7'}
                    value={vehicleYear}
                  /> */}
                </div>
                {/* <div className="filter-search-btn">
                  <Buttons
                    btnBg="#E11737"
                    buttonText={'Pick Up Oil'}
                    onClick={handleGetVehicleOil}
                  />
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <ServicesResultCard
          vehicleOil={oilData}
          // vehicleOil={vehicleOil}
          // vehicleModif={vehicleModif.label}
          // vehicleYear={vehicleYear.value}
        />
      </Grid>
    </Grid>
  );
};

export default VehicleFilter;
