import React, { useEffect, useState } from 'react';
import UserContext from './UserContext';
import PropTypes from 'prop-types';

const UserContextProvider = ({ children }) => {
  const [user, setUser] = useState('');
  const [token, setToken] = useState(localStorage.getItem('token'));
  const [userAddress, setUserAddress] = useState(
    JSON.parse(localStorage.getItem('userAddress'))
  );

  const userLocalStorage = (userData) => {
    localStorage.setItem('user', JSON.stringify(userData));
    setUser(userData); // Update state
  };
  const addUser = () => {
    setUser(JSON.parse(localStorage.getItem('user')));
  };

  const userAddressLocal = (userAdr) => {
    localStorage.setItem('userAddress', JSON.stringify(userAdr));
    setUserAddress(userAdr); // Update state
  };

  const tokenLocalStorage = (userToken) => {
    localStorage.setItem('token', userToken);
    setToken(userToken); // Update state
  };
  useEffect(() => {
    addUser();
  }, [token]);

  const logoutUser = () => {
    localStorage.removeItem('user');
    localStorage.removeItem('userAddress');
    localStorage.removeItem('token');
    setToken('');
    setUser(null);
    setUserAddress(null);
  };

  const loginUser = (userData, token) => {
    userLocalStorage(userData);
    tokenLocalStorage(token);
    // Any other logic needed for login so add here
  };

  return (
    <UserContext.Provider
      value={{
        user,
        setUser,
        tokenLocalStorage,
        logoutUser,
        loginUser, // Add this a loginUser function
        token,
        userLocalStorage,
        userAddressLocal,
        userAddress,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

UserContextProvider.propTypes = {
  children: PropTypes.node,
};

export default UserContextProvider;
