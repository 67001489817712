import './style.css';
import React from 'react';
import Heading from '../../../../../components/headings/Heading';
import Img1 from './../../../../../images/about-images/company-detail/ceo.png';

const Tab4 = () => {
  return (
    <div>
      <div>
        <div className="company-details-headings">
          <Heading
            className="company-details-headings"
            desc="Message from CEO"
          />
        </div>
        <div className="company-details-container">
          <div className="company-details">
            <div className="company-details-right-card">
              <div className="company-details-sections">
                <div className="company-details-nano">
                  <div className="company-details-nano-text">
                    <p>
                      At Idemitsu, we take immense pride in being a leading
                      provider of high-quality lubricant solutions in Pakistan.
                      Our commitment to excellence, innovation, and customer
                      satisfaction is the driving force behind everything we do.
                      We understand the vital role that lubricants play in
                      ensuring optimal performance and efficiency across various
                      sectors. With our extensive expertise and cutting-edge
                      technology, we are fully dedicated to delivering lubricant
                      solutions that cater to your specific needs and surpass
                      industry standards. Furthermore, our presence in Pakistan
                      signifies the strong relationship between our two nations.
                      We deeply value the cultural and economic ties that bind
                      Pakistan and Japan together. Through our lubricant
                      business, we aim to strengthen these bonds and contribute
                      to the growth and development of Pakistan. Once again, I
                      extend my heartfelt gratitude for your unwavering support
                      and partnership. We look forward to serving you with
                      excellence and reliability.
                    </p>
                  </div>
                  <div>
                    <div className="company-details-tab3-img">
                      <img src={Img1} alt={Img1} />
                    </div>
                  </div>
                </div>
                <div className="company-details-nano-text">
                  <div>
                    <p>Idemitsu Lube Pakistan (Pvt) Ltd</p>
                  </div>
                  <div>
                    <p>President & CEO</p>
                  </div>
                  <div>
                    <h3 className="company-details-section-title">
                      Ryota Takizawa
                    </h3>
                  </div>
                </div>
              </div>
              <div className="company-details-sections">
                <div className="company-details-tab4-quote">
                  <h1>“ Our Passion for Motion Creates Harmony “</h1>
                </div>
              </div>
              <div className="company-details-sections">
                <div>
                  <p>
                    Through{' '}
                    <strong>Tribology – the Science of Lubrication,</strong>{' '}
                    Idemitsu drives the innovation of technology to lead the
                    future. The world is always in motion, requiring efforts to
                    reduce friction between people and between things. As we
                    reflect on our journey, we are committed to leading the way
                    in shaping a brighter future for Pakistan. In the future, we
                    aim to continue producing innovative lubricants that we hope
                    will benefit the world not only by controlling friction
                    between things, but also by improving relations between
                    humans.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Tab4;
