import React from 'react';
import ImgOne from '../../../images/product-imgs/IFD3/PK_IFD3 10W-40_4L_SN-CF_F.png';
// import ImgTwo from '../../../images/product-imgs/IFG3/PK_IFG3 0W-20_SP-GF-6A_4L_P.png';
import ProductDetails from '../../productdetails';
import Layout from '../../../Layout/Layout';
import { Grid } from '@mui/material';

const IFD31 = () => {
  return (
    <Layout>
      <Grid container>
        <Grid item xl={12} lg={12} md={12} xs={12}>
          <ProductDetails
            title="IFD3 10W-40 SN/CF Semi Synthetic 4L"
            price="Rs 6,720"
            img={ImgOne}
            product_desc="Idemitsu Four-Wheelers' Dual Engine Oil - IFD3"
            adv_tit="Distinctive Characteristics for Unmatched Advantages:"
            adv_1="All season performance"
            adv_2="Dual-Purpose powerhouse"
          />
        </Grid>
      </Grid>
    </Layout>
  );
};

export default IFD31;
