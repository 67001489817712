import './style.css';
import React, { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';

const CookieConsent = () => {
  const [open, setOpen] = useState(false);
  // eslint-disable-next-line
  const [cookies, setCookie] = useCookies(['cookieConsent']);
  // console.log(cookies);
  const giveCookieConsent = () => {
    setCookie('cookieConsent', true, { path: '/' });
  };
  // console.log(giveCookieConsent);
  useEffect(() => {
    setTimeout(() => {
      setOpen(true);
    }, 2000);
  }, []);

  return (
    <div>
      {open && (
        <div className="cookie-consent">
          <div className="cookie-consent-div">
            <p className="cookie-text">
              We use cookies to enhance your user experience. By using our
              website, you agree to our use of cookies.{' '}
            </p>
            <div className="cookie-buttons">
              <button
                className="cookie-button-accept"
                onClick={giveCookieConsent}
              >
                Accept
              </button>
              <button
                onClick={giveCookieConsent}
                className="cookie-button-decline"
              >
                Decline
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
    // <div>
    //   <>
    //     <Dialog
    //       PaperProps={{
    //         sx: {
    //           backgroundColor: '#e61637',
    //           color: 'white',
    //           fontFamily: 'Poppins',
    //         },
    //       }}
    //       open={open}
    //       // onClose={handleClose}
    //       aria-labelledby="alert-dialog-title"
    //       aria-describedby="alert-dialog-description"
    //     >
    //       <DialogContent>
    //         <p className="cookie-text">
    //           We use cookies to enhance your user experience. By using our
    //           website, you agree to our use of cookies.{' '}
    //         </p>
    //       </DialogContent>
    //       <DialogActions>
    //         <button className="cookie-button" onClick={handleClose}>
    //           Disagree
    //         </button>
    //         <button
    //           className="cookie-button"
    //           onClick={giveCookieConsent}
    //           autoFocus
    //         >
    //           Agree
    //         </button>
    //       </DialogActions>
    //     </Dialog>
    //   </>
    // </div>
  );
};

export default CookieConsent;
