import './style.css';
import { Grid } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
// import {
//   FontSize,
//   ThemeColors,
//   ThemeFontsFamily,
//   ThemeFontWeight,
// } from '../../../../../utils/Theme';
import { CartContext } from '../../../../../context/cartContext';
import UserContext from '../../../../../context/UserContext';
import order from '../../../../../services/order/order';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import COD from '../../../../../images/product-imgs/cash-on-delivery.png';
import ItemTotal from '../../../ordersummary/orderitems/itemtotal';
import ItemsButton from '../../../../../components/itembutton';

const Billing = () => {
  const { cart, totalPrice, shippingFee, dispatch } = useContext(CartContext);
  const { user, userAddress, token } = useContext(UserContext);
  // console.log(cart);
  // console.log(userAddress);
  // adding todays date
  function getDate() {
    const today = new Date();
    const month = today.getMonth() + 1;
    const year = today.getFullYear();
    const date = today.getDate();
    return `${year}-${month}-${date}`;
  }

  // adding 7days later date
  // function getLaterDate() {
  //   const today = new Date();
  //   const month = today.getMonth() + 1;
  //   const year = today.getFullYear();
  //   const date = today.getDate();
  //   return `${year}-${month}-${date}`;
  // }

  // Function to get a date 7 days later
  function getDateSevenDaysLater() {
    const today = new Date();
    const sevenDaysLater = new Date(today);
    sevenDaysLater.setDate(today.getDate() + 7);
    return sevenDaysLater;
  }

  // Function to format date as YYYY-MM-DD
  function formatDateToNumeric(date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  }

  // Example usage
  const futureDate = getDateSevenDaysLater();
  const numericDate = formatDateToNumeric(futureDate);
  // console.log(numericDate); // This will log the date 7 days from today in YYYY-MM-DD format
  const navigate = useNavigate();
  const [paywith, setPaywith] = useState('card');
  const [personId, setPersonId] = useState('');
  const [adrId, setAdrId] = useState(Number(userAddress));
  const [companyId, setCompanyId] = useState(Number('3'));
  const [expectedDelivery, setExpectedDelivery] = useState(numericDate);
  const [amountBilled, setAmountBilled] = useState('');
  const [dateBilled, setDateBilled] = useState(getDate());
  const [nameOnCard, setNameOnCard] = useState('API Default entry');
  const [cardType, setCardType] = useState('Cash');
  const [cardNumberShort, setCardNumberShort] = useState('1234');
  const [cardExpiryDate, setCardExpiryDate] = useState('01-08-2024');
  // eslint-disable-next-line
  const [items, setItems] = useState(cart);
  const [billingAdrId, setBillingAdrId] = useState(Number(userAddress));

  const handleChange = (event) => {
    setPaywith(event.target.value);
  };
  useEffect(() => {
    setPersonId(Number(user.personId));
    setAmountBilled(Number(totalPrice + shippingFee));
  }, [user, totalPrice, shippingFee]);

  const handleClick = async (token) => {
    const data = {
      personId,
      billingAdrId,
      items,
      cardExpiryDate,
      cardNumberShort,
      cardType,
      nameOnCard,
      dateBilled,
      amountBilled,
      expectedDelivery,
      companyId,
      adrId,
    };
    // console.log(data);
    try {
      const resp = await order(data, token);
      // eslint-disable-next-line
      const body = resp.body.orderID;
      Swal.fire({
        icon: 'success',
        title: 'your order has been done',
        showConfirmButton: false,
        timer: 1500,
      });
      dispatch({ type: 'CLEAR_CART' });
      // Clear local storage
      localStorage.removeItem('cartItems');
      navigate('/orderdetails/orderlist');
      // console.log(resp);
      // console.log(body);
    } catch (error) {
      Swal.fire({
        icon: 'warning',
        title: 'something is wrong',
        showConfirmButton: false,
        timer: 1500,
      });
      // console.log(error);
    }
  };

  return (
    <Grid container mt={3} mb={3}>
      <Grid item xl={8} lg={8} md={12} sm={12} xs={12}>
        <div className="billing-container">
          <div className="paywith-container">
            <div className="paywith-heading">
              <h4>Pay with</h4>
            </div>
            <div className="paywith-line"></div>
            <div className="paywith-radio-btn">
              <div className="paywith-radio">
                <label>
                  <input
                    type="radio"
                    value="card"
                    checked={paywith === 'card'}
                    onChange={handleChange}
                  />
                  {/* <span className="list-label">Pay Through Card</span> */}
                  <span className="list-label">Cash</span>
                </label>
              </div>
              {/* <div className="paywith-radio">
                <label>
                  <input
                    type="radio"
                    value="wallet"
                    checked={paywith === 'wallet'}
                    onChange={handleChange}
                  />
                  <span className="map-label">Pay Through Wallet</span>
                </label>
              </div> */}
            </div>
          </div>
          {paywith === 'card' ? (
            <div>
              <div>
                <form action="" onSubmit="">
                  <div className="paywith-detail-container">
                    <div className="paywith-detial-fields">
                      <div className="block">
                        <div className="paywith-detial-input-field">
                          <label className="paywith-detial-label">
                            Card Number
                          </label>
                          <input
                            required
                            className="paywith-detial-input"
                            type="text"
                            placeholder="1234"
                            value={cardNumberShort}
                            onChange={(e) => setCardNumberShort(e.target.value)}
                          />
                        </div>
                        <div className="paywith-expdate">
                          <div className="paywith-detial-input-field-csz">
                            <label className="paywith-detial-label">
                              Expiraion Date
                            </label>
                            <input
                              required
                              className="paywith-detial-input"
                              type="text"
                              placeholder="MM-YYYY"
                              value={cardExpiryDate}
                              onChange={(e) =>
                                setCardExpiryDate(e.target.value)
                              }
                            />
                          </div>
                          <div className="paywith-detial-input-field">
                            <div className="paywith-detial-input-field-csz">
                              <label className="paywith-detial-label">
                                Expected Delivery
                              </label>
                              <input
                                required
                                className="paywith-detial-input"
                                type="text"
                                placeholder="Expected Delivery"
                                value={expectedDelivery}
                                onChange={(e) =>
                                  setExpectedDelivery(e.target.value)
                                }
                              />
                            </div>
                          </div>
                        </div>
                        <div className="paywith-expdate">
                          <div className="paywith-detial-input-field">
                            <div className="paywith-detial-input-field-csz">
                              <label className="paywith-detial-label">
                                Cardholder Name
                              </label>
                              <input
                                required
                                className="paywith-detial-input"
                                type="text"
                                placeholder="Name on your card"
                                value={nameOnCard}
                                onChange={(e) => setNameOnCard(e.target.value)}
                              />
                            </div>
                          </div>
                          <div className="paywith-detial-input-field">
                            <div className="paywith-detial-input-field-csz">
                              <label className="paywith-detial-label">
                                card type
                              </label>
                              <input
                                required
                                className="paywith-detial-input"
                                type="text"
                                placeholder="card type"
                                value={cardType}
                                onChange={(e) => setCardType(e.target.value)}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="paywith-expdate">
                          <div className="paywith-detial-input-field-block">
                            <div className="paywith-detial-input-field-csz">
                              <label className="paywith-detial-label">
                                personId
                              </label>
                              <input
                                required
                                className="paywith-detial-input"
                                type="text"
                                placeholder="Name on your card"
                                value={personId}
                                onChange={(e) => setPersonId(e.target.value)}
                              />
                            </div>
                          </div>
                          <div className="paywith-detial-input-field">
                            <div className="paywith-detial-input-field-csz">
                              <label className="paywith-detial-label">
                                dateBilled
                              </label>
                              <input
                                required
                                className="paywith-detial-input"
                                type="text"
                                placeholder="card type"
                                value={dateBilled}
                                onChange={(e) => setDateBilled(e.target.value)}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="paywith-detial-input-field-block">
                          <div className="paywith-detial-input-field-csz">
                            <label className="paywith-detial-label">
                              Shipping Address
                            </label>
                            <input
                              required
                              className="paywith-detial-input"
                              type="text"
                              placeholder=" Billing address"
                              value={billingAdrId}
                              onChange={(e) => setBillingAdrId(e.target.value)}
                            />
                          </div>
                        </div>
                        <div className="paywith-detial-input-field-block">
                          <div className="paywith-detial-input-field-csz">
                            <label className="paywith-detial-label">
                              addressId
                            </label>
                            <input
                              required
                              className="paywith-detial-input"
                              type="text"
                              placeholder="addressId"
                              value={adrId}
                              onChange={(e) => setAdrId(e.target.value)}
                            />
                          </div>
                        </div>

                        <div className="paywith-detial-input-field-block">
                          <div className="paywith-detial-input-field-csz">
                            <label className="paywith-detial-label">
                              company id
                            </label>
                            <input
                              required
                              className="paywith-detial-input"
                              type="text"
                              placeholder=" company id"
                              value={companyId}
                              onChange={(e) => setCompanyId(e.target.value)}
                            />
                          </div>
                        </div>
                        <div className="paywith-detial-input-field">
                          <div className="paywith-detial-input-field-csz">
                            <label className="paywith-detial-label">
                              total amount
                            </label>
                            <input
                              disabled
                              required
                              className="paywith-detial-input"
                              type="text"
                              placeholder="  total amount"
                              value={amountBilled}
                              onChange={(e) => setAmountBilled(e.target.value)}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="cash">
                        <div>
                          <img
                            className="cash-img"
                            src={COD}
                            alt="Cash On Delivery"
                          />
                        </div>
                        <div>
                          <p className="pd-desc">Cash On Delivery</p>
                        </div>
                      </div>
                      {/* <div className="paywith-detial-input-field">
                        <div className="order-paywith-detail-btn">
                          <Button
                            type="submit"
                            className="button"
                            variant="contained"
                            sx={{
                              backgroundColor: '#E11737',
                              padding: '10px 35px',
                              width: '100px',
                              height: '45px',
                              borderRadius: '100px',
                              color: ThemeColors.White,
                              fontFamily: ThemeFontsFamily.Poppins,
                              fontWeight: ThemeFontWeight.Regular,
                              fontSize: FontSize.regular,
                              textTransform: 'capitalize',
                              '&.MuiButton-root:hover': {
                                backgroundColor: '#E11737',
                              },
                            }}
                          >
                            Order
                          </Button>
                        </div>
                      </div> */}
                    </div>
                  </div>
                </form>
              </div>
            </div>
          ) : (
            <h1>wallet</h1>
          )}
        </div>
      </Grid>
      <Grid item xl={4} lg={4} md={12} sm={12} xs={12}>
        <ItemTotal totalPrice={totalPrice} shippingFee={shippingFee}>
          <ItemsButton
            btntxt="Confirm order"
            onClick={() => handleClick(token)}
          />
        </ItemTotal>
      </Grid>
    </Grid>
  );
};

export default Billing;
