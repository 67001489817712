import './style.css';
import { Grid } from '@mui/material';
import React from 'react';
import BannerSlider from '../../../components/bannerslider';
// import BannerImgOne from "../../../images/banner-imgs/products-banner-imgs/products-banner-img-01.jpg";
import BannerImgTwo from '../../../images/banner-imgs/products-banner-imgs/products-banner-img-02.jpg';
const ProductBanner = () => {
  const sliderData = [
    // {
    //   id: 1,
    //   imageSrc: BannerImgOne,
    //   atl: "products-banner-img",
    //   bannerResp: "products-banner-resp",
    //   isShowTextContent: false,
    // },
    {
      id: 1,
      imageSrc: BannerImgTwo,
      atl: 'products-banner-img',
      bannerResp: 'products-banner-resp',
      isShowTextContent: false,
    },
  ];
  return (
    <Grid container>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <BannerSlider sliderData={sliderData} />
      </Grid>
    </Grid>
  );
};

export default ProductBanner;
