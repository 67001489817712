import './style.css';
import React from 'react';
const IndustryCard = (props) => {
  const { src, alt, title, onClick } = props;
  return (
    <div className="industry-card">
      <img className="industry-card-img" src={src} alt={alt} />
      <div className="industry-card-body">
        <div>
          <h1>{title}</h1>
        </div>
        <div>
          <button onClick={onClick} className="industry-card-btn">
            Get query
          </button>
        </div>
      </div>
    </div>
  );
};

export default IndustryCard;
