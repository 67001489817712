import config from '../config';
import fetchUtil from '../fetchUtil';

/**
 * Fetches all orders.
 *
 */
const getOils = async (oilid, token) => {
  const url = `${config.baseUrl}/oil?oilId=${oilid}`;
  const params = {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  };
  let data = null;
  try {
    const { body } = await fetchUtil(url, params);
    data = body;
  } catch (error) {
    throw new Error(error);
  }
  return data;
};
export default getOils;
