import './style.css';
import React from 'react';
// import Map from '../../companymap/Map';
// import OverviewCard from '../../overviewcards/OverviewCard';
import Heading from '../../../../../components/headings/Heading';
import Img1 from './../../../../../images/about-images/company-detail/Nano-tailored.png';

const Tab3 = () => {
  return (
    <div>
      <div className="company-details-headings">
        <Heading
          className="company-details-headings"
          desc="Idemitsu Lube Pakistan (Private) Limited"
        />
      </div>
      <div className="company-details-container">
        <div className="company-details">
          <div className="company-details-right-card">
            <div className="company-details-sections">
              <p>
                Since the past 12 years, Idemitsu has been actively supplying
                Genuine Oil to all leading automobile manufacturers in Pakistan.
                In 2018, Idemitsu established its office in Pakistan as an
                affiliate of{' '}
                <a
                  href="https://www.idemitsu.com/en/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <strong className="Idemitsu-link">
                    Idemitsu Kosan Co., Ltd.{' '}
                  </strong>
                </a>
                Idemitsu Lube Pakistan (Private) Limited, takes pride in our
                legacy that spans over several decades.
              </p>
            </div>
            <div className="company-details-sections">
              <div>
                <h3 className="company-details-section-title">
                  Our Commitment to Pakistan
                </h3>
              </div>
              <div>
                <p>
                  At the heart of our success lies an unwavering commitment to
                  quality and innovation. Idemitsu’s commitment to excellence
                  promises high quality lubricants that enhance performance and
                  efficiency, exceeding expectations and meets the unique needs
                  of the Pakistani market. As a responsible corporate citizen,
                  our commitment goes beyond business operations. We strive to
                  build deep relationships with our clients and customers and to
                  contribute to the well-being of our society.
                </p>
              </div>
            </div>
            <div className="company-details-sections">
              <div>
                <h2 className="company-details-section-heading">
                  Idemitsu Lubricants: The Heart of Technology
                </h2>
              </div>
              <div>
                <p>
                  Standing by its core value of “Respect for Human Beings”,
                  Idemitsu cares to make a difference, for a healthier
                  environment and the betterment of people with its advanced
                  technology, as well as being the very heart and core of
                  innovations. To bring a better future for the people of
                  Pakistan, Idemitsu has launched its own brand, Idemitsu
                  Branded Motor Oil (IBMO) with the NEW Nano Tailored
                  Technology.
                </p>
              </div>
            </div>
            <div className="company-details-sections">
              <div>
                <h3 className="company-details-section-title">
                  Nano Tailored Oil
                </h3>
              </div>
              <div className="company-details-nano">
                <div className="company-details-nano-text">
                  <p>
                    Idemitsu uses cutting-edge Nanoscale Technology to develop
                    and formulate lubricants with one millionth of a millimeter
                    precision. Since even the smoothest looking gears have
                    nanoscale irregularities, we use electron microscopes to
                    inspect the tiniest details as we insist on precisely
                    tailoring formulas to meet particular needs. The road to
                    this achievement was long and hard, paved with much research
                    and testing from the perspective that engines are the heart
                    of vehicles and engine oil is the lifeblood that protects
                    and brings out their full, lively potential.
                  </p>
                </div>
                <div>
                  <div className="company-details-tab3-img">
                    <img src={Img1} alt={Img1} />
                  </div>
                </div>
              </div>
              <div>
                <p>
                  IDEMITSU IFG (Idemitsu Four Wheelers’ Gasoline Engine Oil) and
                  IDEMITSU IRG (Idemitsu Riders’ Gasoline Engine Oil) were
                  perfected by taking full advantage of the latest technology
                  and over 112 years of oil expertise.
                </p>
              </div>
            </div>
            <div className="company-details-sections">
              <div>
                <h3 className="company-details-section-title">
                  Let’s Strive Together!
                </h3>
              </div>
              <div>
                <p>
                  Connection with us on social media and join us in our exciting
                  journey towards the future, powered by innovation and
                  excellence.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Tab3;
