import React from 'react';
import { Grid } from '@mui/material';
import CategoryCard from './CategoryCard';
import Heading from '../../../components/headings/Heading';
import AutomotiveImg from './../../../images/automotive.png';
import IndustrialImg from './../../../images/daphne logo.png';
import { NavLink } from 'react-router-dom';
// import SpecialtyProductsImg from '../../../images/specialty products.png';

const Categories = () => {
  return (
    <>
      <Grid container>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <div className="home-categories-heading">
            <Heading title="" desc="Our Top Categories" />
          </div>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <div className="home-vehicle-card-container">
            <NavLink to={'/Products'}>
              <CategoryCard
                title={'Automotive'}
                src={AutomotiveImg}
                // desc="On the other hand, we denounce with righteous indignation and dislike men whi are so beguiled and demoralized by the charms of pleasure "
                alt={'Automotive'}
              />
            </NavLink>
            <NavLink to={'/Industrial'}>
              <CategoryCard
                title={'Industrial'}
                src={IndustrialImg}
                // desc="On the other hand, we denounce with righteous indignation and dislike men whi are so beguiled and demoralized by the charms of pleasure "
                alt={'Industrial'}
              />
            </NavLink>
          </div>
        </Grid>
      </Grid>
    </>
  );
};

export default Categories;
