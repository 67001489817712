import './style.css';
import React from 'react';
import { Grid } from '@mui/material';

const OrderHeading = (props) => {
  const { heading, desc } = props;
  return (
    <Grid container pb={4}>
      <Grid item xl={12} lg={12} md={12} xs={12}>
        <div className="order-headings">
          <div>
            <h2>{heading}</h2>
          </div>
          <div>
            <p>{desc}</p>
          </div>
        </div>
      </Grid>
    </Grid>
  );
};

export default OrderHeading;
