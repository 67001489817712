import React from 'react';
import ImgOne from '../../../images/product-imgs/IFD3/PK_IFD3 20W-50 SP-CF_4L_F.png';
// import ImgTwo from '../../../images/product-imgs/IFG3/PK_IFG3 0W-20_SP-GF-6A_4L_P.png';
import ProductDetails from '../../productdetails';
import Layout from '../../../Layout/Layout';
import { Grid } from '@mui/material';

const IFD32 = () => {
  return (
    <Layout>
      <Grid container>
        <Grid item xl={12} lg={12} md={12} xs={12}>
          <ProductDetails
            title="IFD3 10W-40 SN/CF Semi Synthetic 3L"
            price="Rs 5,130"
            des="Idemitsu Four-Wheelers' Dual Engine Oil - IFD3 10W-40 SN/CF Semi Synthetic 3L"
            img={ImgOne}
          />
        </Grid>
      </Grid>
    </Layout>
  );
};

export default IFD32;
