import { createContext, useEffect, useReducer } from 'react';
import getAllData from '../services/getAllData';
import reducer from '../reducer/productReducer';

const AppContext = createContext();
const initialState = {
  isLoading: false,
  isError: false,
  products: [],
};
const AppProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    const getProducts = async () => {
      dispatch({ type: 'SET-LOADING' });
      try {
        const allProducts = await getAllData();
        // console.log(allProducts);
        // console.log(allData);
        dispatch({ type: 'ALL-PRODUCTS', payload: allProducts });
      } catch (error) {
        dispatch({ type: 'API-ERROR' });
      }
    };
    getProducts();
  }, []);

  return (
    <AppContext.Provider value={{ ...state }}>{children}</AppContext.Provider>
  );
};

export { AppProvider, AppContext };
