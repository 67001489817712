import React from 'react';
import Layout from '../../../Layout/Layout';
import ProductBanner from '../bannersection';
import ProductRangeText from '../productrangetextsection';
import { Grid } from '@mui/material';
import Daphne from './content';

const Industrial = () => {
  return (
    <Layout>
      <Grid container className="products-page">
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <ProductBanner />
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <ProductRangeText
            title=" INDUSTRIAL RANGE"
            desc="Idemitsu is the leading lubricant manuf acturer from Japan,
                  established over 100 years of experience.
                  As the visionary creator of eco-friendly industrial oils, we are
                  committed to finding effective solutions that cater to both our
                  customers and the environment. We are committed to work towards
                  friends a sustainable society with Daphne's range of environmentally
                  friendly mainte nance and metal working oils.
                  Our dedication to technological innovation and respect for the
                  environment will ensure that we continue to deliver high quality
                  products that are ecologically-friendly for a greener future."
          />
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Daphne />
        </Grid>
      </Grid>
    </Layout>
  );
};

export default Industrial;
