import React, { useContext, useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { CartContext } from './../../../context/cartContext';
import UserContext from '../../../context/UserContext';

const Logout = () => {
  const { logoutUser } = useContext(UserContext);
  const { dispatch } = useContext(CartContext); // Access dispatch to clear cart(important Logic)

  useEffect(() => {
    // Clear cart in context
    dispatch({ type: 'CLEAR_CART' });

    // Clear local storage
    localStorage.removeItem('cartItems');

    // Perform logout
    logoutUser();
  }, [dispatch, logoutUser]);

  return <Navigate to={'/'} />;
};

export default Logout;
