import React, { useContext } from 'react';
import {
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  // tableCellClasses,
  TableHead,
  TableRow,
  Toolbar,
  Typography,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { CartContext } from '../../../../../context/cartContext';

const Items = () => {
  const { cart, deleteItem, decreaseCount, increaseCount } =
    useContext(CartContext);
  // console.log(cart);
  // const [quantity, setQuantity] = useState(0);
  // Function to increase the count
  // const increaseCount = () => {
  //   setQuantity(quantity + 1);
  // };

  // Function to decrease the count
  // const decreaseCount = () => {
  //   setQuantity(quantity > 0 ? quantity - 1 : 0);
  // };

  return (
    <Grid container>
      <Grid item mt={3} xl={12} lg={12} md={12} sm={12} xs={12}>
        <div className="order-items">
          <Toolbar
            variant="dense"
            sx={{ backgroundColor: '#e11737', height: '34px' }}
          >
            <Typography
              sx={{ flex: '1 1 100%' }}
              variant="h6"
              id="tableTitle"
              component="div"
              color="white"
            >
              All Items
            </Typography>
          </Toolbar>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 700 }} aria-label="customized table">
              <TableHead>
                <TableRow>
                  <TableCell
                    sx={{
                      fontFamily: 'Poppins',
                      fontSize: '13px',
                      fontWeight: '700',
                      textAlign: 'center',
                    }}
                  >
                    S NO.
                  </TableCell>
                  <TableCell
                    sx={{
                      fontFamily: 'Poppins',
                      fontSize: '13px',
                      fontWeight: '700',
                      textAlign: 'center',
                    }}
                  >
                    ITEM
                  </TableCell>
                  <TableCell
                    sx={{
                      fontFamily: 'Poppins',
                      fontSize: '13px',
                      fontWeight: '700',
                      textAlign: 'center',
                    }}
                  >
                    QTY
                  </TableCell>
                  <TableCell
                    sx={{
                      fontFamily: 'Poppins',
                      fontSize: '13px',
                      fontWeight: '700',
                      textAlign: 'center',
                    }}
                  >
                    PRICE
                  </TableCell>
                  <TableCell
                    sx={{
                      fontFamily: 'Poppins',
                      fontSize: '13px',
                      fontWeight: '700',
                      textAlign: 'center',
                    }}
                  >
                    ACTION
                  </TableCell>
                </TableRow>
              </TableHead>
              {cart?.map((ele) => {
                return (
                  <TableBody>
                    <TableRow
                      key={ele.oilId}
                      sx={{
                        '&:last-child td, &:last-child th': { border: 'none' },
                      }}
                    >
                      <TableCell>
                        <div className="item-sr-no">
                          <p>{ele.oilId}</p>
                        </div>
                      </TableCell>
                      <TableCell>
                        <div className="items">
                          <div className="items-bottle">
                            <img src={ele.product_image} alt="bottle" />
                          </div>
                          <div className="items-bottle-text">
                            <div>
                              <h3>{ele.title}</h3>
                            </div>
                            <div>
                              <p>{ele.description}</p>
                            </div>
                          </div>
                        </div>
                      </TableCell>
                      <TableCell>
                        <div className="item-qty">
                          <div className="item-qty-counter">
                            <div className="item-qty-counter-group">
                              <button
                                onClick={() => decreaseCount(ele.oilId)}
                                className="item-qty-counter-dec"
                              >
                                -
                              </button>
                              <input
                                className="item-qty-counter-input"
                                type="text"
                                readOnly
                                value={ele.quantity}
                              />
                              <button
                                onClick={() => increaseCount(ele.oilId)}
                                className="item-qty-counter-inc"
                              >
                                +
                              </button>
                            </div>
                          </div>
                        </div>
                      </TableCell>
                      <TableCell>
                        <div className="item-price">
                          <h3>{ele.price}</h3>
                        </div>
                      </TableCell>
                      <TableCell>
                        <div className="item-del-icon">
                          <IconButton onClick={() => deleteItem(ele.oilId)}>
                            <DeleteIcon />
                          </IconButton>
                        </div>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                );
              })}
            </Table>
          </TableContainer>
        </div>
      </Grid>
    </Grid>
  );
};

export default Items;
