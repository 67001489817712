import React, { useState } from 'react';
import emailjs from '@emailjs/browser';
import './style.css';
import { Grid, Typography } from '@mui/material';
import {
  ThemeColors,
  ThemeFontWeight,
  ThemeFontsFamily,
  FontSize,
} from '../../../../utils/Theme';

const AskUsAt = () => {
  const [isSubmitting, setIsSubmitting] = useState(true);
  const [checked, setChecked] = useState(false);
  const [name, setName] = useState('');
  const [email_id, setEmail_id] = useState('');
  const [phone, setPhone] = useState('');
  const [message, setMessage] = useState('');
  const [service, setService] = useState('');
  const sendEmail = (e) => {
    e.preventDefault();

    const serviceId = 'service_9gffku9';
    const templateId = 'template_qt580ha';
    const apiKey = 'eD3s90Den_lNYC2FA';

    const templateParams = {
      name: name,
      email_id: email_id,
      phone: phone,
      message: message,
      service: service,
    };

    emailjs.send(serviceId, templateId, templateParams, apiKey).then(
      (result) => {
        // console.log(result.text);
        alert('message has been send');
        setName('');
        setEmail_id('');
        setMessage('');
        setPhone('');
        setService('');
      },
      (error) => {
        console.log(error.text);
      }
    );
  };
  const handleChange = () => {
    setChecked(true);
    setIsSubmitting(false);
  };
  return (
    <Grid container>
      <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
        <div className="ask-us-at-section">
          <div className="headings">
            <Typography
              variant="h5"
              component="div"
              sx={{
                fontFamily: ThemeFontsFamily.Poppins,
                fontSize: FontSize.xxxlarge,
                fontWeight: ThemeFontWeight.SemiBold,
                color: ThemeColors.White,
                lineHeight: '36px',
                marginLeft: '30px',
                marginTop: '50px',
              }}
            >
              ASK US AT
            </Typography>
            <Typography
              variant="h2"
              component="div"
              sx={{
                fontFamily: ThemeFontsFamily.Volkhov,
                fontSize: FontSize.desc,
                fontWeight: ThemeFontWeight.Bold,
                color: ThemeColors.MainHeadings,
                lineHeight: '64.5px',
                marginLeft: '30px',
                marginTop: '10px',
              }}
            >
              IDEMITSU
            </Typography>
          </div>
          <form onSubmit={sendEmail}>
            <div className="ask-form-section">
              <div className="ask-us-at-form-left-section">
                <textarea
                  value={message}
                  placeholder="Your Message"
                  onChange={(e) => setMessage(e.target.value)}
                ></textarea>
                <div>
                  <input
                    className="term-input"
                    type="checkbox"
                    id="terms"
                    name="terms"
                    value="terms"
                    onChange={handleChange}
                    checked={checked}
                  />
                  <label className="term-label" for="terms">
                    {' '}
                    I agree to your privacy policy.
                  </label>
                  <br />
                </div>
                <button
                  disabled={isSubmitting}
                  type="submit"
                  className="form-submit-btn"
                >
                  Submit
                </button>
              </div>
              <div className="ask-us-at-form-right-section">
                <input
                  value={name}
                  type="text"
                  placeholder="Enter Name"
                  onChange={(e) => setName(e.target.value)}
                />
                <span className="border-bottom-img"></span>
                <input
                  value={email_id}
                  type="text"
                  placeholder="Enter Email"
                  onChange={(e) => setEmail_id(e.target.value)}
                />
                <span className="border-bottom-img"></span>
                <input
                  value={phone}
                  type="text"
                  placeholder="Enter Phone Number"
                  onChange={(e) => setPhone(e.target.value)}
                />
                <span className="border-bottom-img"></span>
                <input
                  value={service}
                  type="text"
                  placeholder="Enter Service You Require"
                  onChange={(e) => setService(e.target.value)}
                />
                <span className="border-bottom-img"></span>
              </div>
            </div>
          </form>
        </div>
      </Grid>
    </Grid>
  );
};

export default AskUsAt;
