import './style.css';
import React from 'react';
import { Grid, Card, CardContent, Typography } from '@mui/material';
import {
  ThemeColors,
  ThemeFontWeight,
  ThemeFontsFamily,
  FontSize,
} from './../../../../utils/Theme';
import Box from './../../../../images/about-images/map-imgs/Rectangle-1.png';
import Circle from './../../../../images/about-images/map-imgs/Ellipse.png';
import Star from './../../../../images/about-images/map-imgs/Star.png';
import Triangle from './../../../../images/about-images/map-imgs/Polygon.png';
import Triangle1 from './../../../../images/about-images/map-imgs/Polygon-1.png';
import Box1 from './../../../../images/about-images/map-imgs/Rectangle-2.png';

const OverviewCard = () => {
  return (
    <>
      <Grid container>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <div className="overview-cards">
            <div className="overview-card-content">
              <Card
                sx={{
                  maxWidth: 265,
                  backgroundColor: 'none',
                  borderRadius: '36px',
                  height: '220px',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                  textAlign: 'center',
                  boxShadow: 'none',
                }}
                className="about-overview-card"
              >
                <CardContent>
                  {/* <Typography
                    gutterBottom
                    color={ThemeColors.MainHeadings}
                    variant="h5"
                    component="div"
                    sx={{
                      fontSize: FontSize.Title,
                      fontFamily: ThemeFontsFamily.Poppins,
                      fontWeight: ThemeFontWeight.Bold,
                    }}
                  >
                    Lubricants
                  </Typography> */}
                  <img style={{ width: '3em' }} src={Box} alt={Box} />
                  <Typography
                    color={ThemeColors.TitleColor}
                    variant="body2"
                    sx={{
                      fontSize: FontSize.medium,
                      fontFamily: ThemeFontsFamily.Poppins,
                      fontWeight: ThemeFontWeight.Medium,
                      maxWidth: '200px',
                    }}
                  >
                    Lubricants
                  </Typography>
                </CardContent>
              </Card>
            </div>
            <div className="overview-card-content1">
              <div className="overview-item-grid">
                <Card
                  sx={{
                    maxWidth: 265,
                    backgroundColor: 'none',
                    borderRadius: '36px',
                    height: '220px',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    textAlign: 'center',
                    boxShadow: 'none',
                  }}
                  className="about-overview-card"
                >
                  <CardContent>
                    {/* <Typography
                      gutterBottom
                      color={ThemeColors.MainHeadings}
                      variant="h5"
                      component="div"
                      sx={{
                        fontSize: FontSize.Title,
                        fontFamily: ThemeFontsFamily.Poppins,
                        fontWeight: ThemeFontWeight.Bold,
                      }}
                    >
                      Petroleum
                    </Typography> */}
                    <img style={{ width: '3em' }} src={Circle} alt={Circle} />
                    <Typography
                      color={ThemeColors.TitleColor}
                      variant="body2"
                      sx={{
                        fontSize: FontSize.medium,
                        fontFamily: ThemeFontsFamily.Poppins,
                        fontWeight: ThemeFontWeight.Medium,
                        maxWidth: '200px',
                      }}
                    >
                      Petroleum
                    </Typography>
                  </CardContent>
                </Card>
              </div>
            </div>
            <div className="overview-card-content">
              <Card
                sx={{
                  maxWidth: 265,
                  backgroundColor: 'none',
                  borderRadius: '36px',
                  height: '220px',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                  textAlign: 'center',
                  boxShadow: 'none',
                }}
                className="about-overview-card"
              >
                <CardContent>
                  {/* <Typography
                    gutterBottom
                    color={ThemeColors.MainHeadings}
                    variant="h5"
                    component="div"
                    sx={{
                      fontSize: FontSize.Title,
                      fontFamily: ThemeFontsFamily.Poppins,
                      fontWeight: ThemeFontWeight.Bold,
                    }}
                  >
                    Petrochemical
                  </Typography> */}
                  <img style={{ width: '3em' }} src={Star} alt={Circle} />
                  <Typography
                    color={ThemeColors.TitleColor}
                    variant="body2"
                    sx={{
                      fontSize: FontSize.medium,
                      fontFamily: ThemeFontsFamily.Poppins,
                      fontWeight: ThemeFontWeight.Medium,
                      maxWidth: '200px',
                    }}
                  >
                    Petrochemical
                  </Typography>
                </CardContent>
              </Card>
            </div>
            <div className="overview-card-content">
              <Card
                sx={{
                  maxWidth: 265,
                  backgroundColor: 'none',
                  borderRadius: '36px',
                  height: '220px',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                  textAlign: 'center',
                  boxShadow: 'none',
                }}
                className="about-overview-card"
              >
                <CardContent>
                  {/* <Typography
                    gutterBottom
                    color={ThemeColors.MainHeadings}
                    variant="h5"
                    component="div"
                    sx={{
                      fontSize: FontSize.Title,
                      fontFamily: ThemeFontsFamily.Poppins,
                      fontWeight: ThemeFontWeight.Bold,
                    }}
                  >
                    Coal
                  </Typography> */}
                  <img
                    style={{ width: '3em' }}
                    src={Triangle1}
                    alt={Triangle1}
                  />
                  <Typography
                    color={ThemeColors.TitleColor}
                    variant="body2"
                    sx={{
                      fontSize: FontSize.medium,
                      fontFamily: ThemeFontsFamily.Poppins,
                      fontWeight: ThemeFontWeight.Medium,
                      maxWidth: '200px',
                    }}
                  >
                    Coal
                  </Typography>
                </CardContent>
              </Card>
            </div>
            <div className="overview-card-content">
              <Card
                sx={{
                  maxWidth: 265,
                  backgroundColor: 'none',
                  borderRadius: '36px',
                  height: '220px',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                  textAlign: 'center',
                  boxShadow: 'none',
                }}
                className="about-overview-card"
              >
                <CardContent>
                  {/* <Typography
                    gutterBottom
                    color={ThemeColors.MainHeadings}
                    variant="h5"
                    component="div"
                    sx={{
                      fontSize: FontSize.Title,
                      fontFamily: ThemeFontsFamily.Poppins,
                      fontWeight: ThemeFontWeight.Bold,
                    }}
                  >
                    Coal
                  </Typography> */}
                  <img style={{ width: '3em' }} src={Triangle} alt={Triangle} />
                  <Typography
                    color={ThemeColors.TitleColor}
                    variant="body2"
                    sx={{
                      fontSize: FontSize.medium,
                      fontFamily: ThemeFontsFamily.Poppins,
                      fontWeight: ThemeFontWeight.Medium,
                      maxWidth: '200px',
                    }}
                  >
                    Oil Exploration And Production
                  </Typography>
                </CardContent>
              </Card>
            </div>
            <div className="overview-card-content">
              <Card
                sx={{
                  maxWidth: 265,
                  backgroundColor: 'none',
                  borderRadius: '36px',
                  height: '220px',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                  textAlign: 'center',
                  boxShadow: 'none',
                }}
                className="about-overview-card"
              >
                <CardContent>
                  {/* <Typography
                    gutterBottom
                    color={ThemeColors.MainHeadings}
                    variant="h5"
                    component="div"
                    sx={{
                      fontSize: FontSize.Title,
                      fontFamily: ThemeFontsFamily.Poppins,
                      fontWeight: ThemeFontWeight.Bold,
                    }}
                  >
                    Coal
                  </Typography> */}
                  <img style={{ width: '3em' }} src={Box1} alt={Box1} />
                  <Typography
                    color={ThemeColors.TitleColor}
                    variant="body2"
                    sx={{
                      fontSize: FontSize.medium,
                      fontFamily: ThemeFontsFamily.Poppins,
                      fontWeight: ThemeFontWeight.Medium,
                      maxWidth: '200px',
                    }}
                  >
                    Others
                  </Typography>
                </CardContent>
              </Card>
            </div>
          </div>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item lg={12} md={12} xs={12} sm={12}>
          <div className="overview-detail-container">
            <div className="overview-detail-content">
              <div>
                <div className="overview-detial-list">
                  <div>
                    <div className="overview-item-grid">
                      <p
                        style={{
                          color: ThemeColors.ParagraphColor,
                          fontFamily: ThemeFontsFamily.Poppins,
                          fontWeight: ThemeFontWeight.Regular,
                          fontSize: FontSize.large,
                          marginTop: '10px',
                        }}
                      >
                        In its journey of expansion, in 2018, Idemitsu Lube
                        Pakistan (Pvt) Ltd. was established as a testament to
                        Idemitsu’s belief in the country’s economy and its
                        people. By recognizing the potential of the Pakistani
                        market, Idemitsu is dedicated to not just doing business
                        but fostering growth and contributing to the local
                        community.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Grid>
      </Grid>
    </>
  );
};

export default OverviewCard;
