import React from 'react';
import CompanyHistory from '../../companyhistory/CompanyHistory';
import Heading from '../../../../../components/headings/Heading';

const Tab2 = () => {
  return (
    <div>
      <div className="company-details-headings">
        <Heading className="company-details-headings" desc="History" />
      </div>
      <CompanyHistory />
    </div>
  );
};

export default Tab2;
