/**
 * Authors: Waris iqbal
 * Date Created: 23 june 24
 * Description: Config file for mode and serverEndpoint.
 */

const config = {};
// eslint-disable-next-line no-undef
config.buildMode = process.env.REACT_APP_STAGE || 'dev'; // production | dev | test
// While developing the new dashboard instead of deleting code we will just disable tabs
config.fullVersionAllowed = false && config.buildMode === 'dev';

// const url = 'http://127.0.0.1:9000';
const url = 'http://ec2-18-223-196-134.us-east-2.compute.amazonaws.com:9000';
/* if (config.buildMode === 'production') {
  url = 'https://eb-api.cassiansolutions.com';
} else {
  url = 'https://dev-api.cassiansolutions.com';
} */
config.baseUrl = url;

export default config;
