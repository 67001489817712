// import config from "src/services/config";
// import fetchAPIData from "./fetchUtil";

import config from '../config';
import fetchUtil from '../fetchUtil';

/**
 * Post adress
 *
 */
const order = async (odr, token) => {
  const body = JSON.stringify(odr);
  const url = `${config.baseUrl}/order`;
  const params = {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body,
  };
  let userCred = null;
  try {
    const response = await fetchUtil(url, params);
    userCred = response;
  } catch (error) {
    console.log(error);
    throw new Error(error);
  }
  return userCred;
};
export default order;
