import './style.css';
import React from 'react';
import { Grid } from '@mui/material';
import BannerImgTwo from '../../../../images/about-us-banner-img-02.jpg';
import BannerSlider from '../../../../components/bannerslider';
const AboutHero = () => {
  const sliderData = [
    // {
    //   id: 1,
    //   imageSrc: BannerImgOne,
    //   atl: "about-us-banner-img",
    //   bannerResp: "about-us-banner-resp",
    //   isShowTextContent: true,
    //   title:"About Us",
    //   titleClassName:"about-us-banner-title",
    //   subtitle:"Idemitsu Lubricants: The Heart of Technology",
    //   subtitleClassName:"about-us-banner-subtitle",
    //   para:"What drives Idemitsu is the passion towards excellence and commitment to providing top-tier lubrication solutions that enhance the performance and longevity of your engines and machinery. With a rich history spanning over a century, our journey has been marked by innovation, quality, and a relentless pursuit of cutting-edge technology.",
    //   paraClassName:"about-us-banner-para"
    // },
    {
      id: 1,
      imageSrc: BannerImgTwo,
      alt: 'about-us-banner-img',
      bannerResp: 'about-us-banner-resp',
      isShowTextContent: true,
      title: 'About Us',
      titleClassName: 'about-us-banner-title',
      subtitle: 'Idemitsu Lubricants: The Heart of Technology',
      subtitleClassName: 'about-us-banner-subtitle',
      para: 'What drives Idemitsu is the passion towards excellence and commitment to providing top-tier lubrication solutions that enhance the performance and longevity of your engines and machinery. With a rich history spanning over a century, our journey has been marked by innovation, quality, and a relentless pursuit of cutting-edge technology.',
      paraClassName: 'about-us-banner-para',
    },
  ];
  return (
    <Grid container>
      <Grid item lg={12} md={12} xs={12} sm={12}>
        <BannerSlider sliderData={sliderData} />
      </Grid>
    </Grid>
  );
};

export default AboutHero;
