import './style.css';
import React from 'react';
import loader from '../../images/hourglass.gif';

const Loader = () => {
  return (
    <div className="loader">
      <img className="loader-img" src={loader} alt="img" />
    </div>
  );
};

export default Loader;
