import React from 'react';
import CompanyDetail from '../../companydetail/CompanyDetail';

const Tab1 = () => {
  return (
    <div>
      <CompanyDetail />
    </div>
  );
};

export default Tab1;
