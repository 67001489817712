const productReducer = (state, action) => {
  switch (action.type) {
    case 'SET-LOADING':
      return {
        ...state,
        isLoading: true,
      };
    case 'ALL-PRODUCTS':
      return {
        ...state,
        isLoading: false,
        products: action.payload,
      };
    case 'API-ERROR':
      return {
        ...state,
        isLoading: false,
        isError: true,
      };

    default:
      break;
  }
};
export default productReducer;
