import './style.css';
import React from 'react';
import Img1 from '../../../../../../images/about-images/company-detail/little-cute-asian-girl-standing-among-purple-flower-field-su.jpg';
import Img2 from '../../../../../../images/about-images/company-detail/brother-sister-playing-with-kite-plane-field-sunset.jpg';
import Img3 from '../../../../../../images/about-images/company-detail/young-friends-clapping-hands.jpg';
import Img4 from '../../../../../../images/about-images/company-detail/cute-family-playing-summer-field.jpg';
import Img5 from '../../../../../../images/about-images/company-detail/Eco-friendly.jpg';
import Heading from '../../../../../../components/headings/Heading';
const CompanyDetails = () => {
  return (
    <>
      <div className="company-details-headings">
        <Heading className="company-details-headings" desc="Idemitsu" />
      </div>
      <div className="company-details-container">
        <div className="company-details">
          {/* <div className="company-details-left-card">
            <div className="cd-left-card-heading">
              <h4>Company Details</h4>
            </div>
            <div className="cd-left-card-ul">
              <ul>
                <li>
                  <div className="cd-left-card-details">
                    <h5>Company CEO :</h5>
                    <p>Katherine Coleman</p>
                  </div>
                </li>
                <li>
                  <div className="cd-left-card-details">
                    <h5>Address :</h5>
                    <p>New York United States</p>
                  </div>
                </li>
                <li>
                  <div className="cd-left-card-details">
                    <h5>Established :</h5>
                    <p>15 September ,2023</p>
                  </div>
                </li>
                <li>
                  <div className="cd-left-card-details">
                    <h5>Websites :</h5>
                    <p><a href="http://whitecollar.com">http://whitecollar.com</a></p>
                  </div>
                </li>
              </ul>
            </div>
          </div> */}
          <div className="company-details-right-card">
            <div className="company-details-sections">
              <p>
                Idemitsu Kosan Co., Ltd. was founded in 1911 by Sazo Idemitsu in
                Moji, Japan. Initially established as Idemitsu Shokai, the
                company began its journey as a lubricants company, later
                emerging globally into an energy company. Sazo Idemitsu’s vision
                was rooted in human respect and a{' '}
                <strong>passion for innovation</strong> that would shape the
                company’s trajectory for decades to come.
              </p>
            </div>
            <div className="company-details-sections">
              <div>
                <h3 className="company-details-section-title">Our Values</h3>
              </div>
              <div>
                <h2 className="company-details-section-heading">
                  Respect for Human Beings
                </h2>
              </div>
              <div>
                <p>
                  Idemitsu stands by the concept of{' '}
                  <strong> “respect for human beings”</strong>, which it has
                  been practicing in the conduct of business and has sought to
                  meet the high expectations of society and earn its trust. To
                  realize this ideal, Idemitsu strives to:
                </p>
              </div>
              <div>
                <ul>
                  <li>
                    Play a beneficial role in the society by creating an
                    environment where people trust each other and work together
                    to realize through business the tremendous potential of
                    “human beings”.
                  </li>
                  <li>
                    Ensure that each employee develops into a reliable person
                    and is respected in society, while upholding our vision,
                    high ideals and engaging in mutual improvement through
                    amicable competition.
                  </li>
                  <li>
                    Attach great importance to our promises to customers and
                    seek to be worthy of the trust vested in us by fulfilling
                    those promises.
                  </li>
                </ul>
              </div>
              <div className="company-details-sections-imgs">
                <div className="company-details-img">
                  <img src={Img1} alt={Img1} />
                </div>
                <div className="company-details-img">
                  <img src={Img2} alt={Img2} />
                </div>
                <div className="company-details-img">
                  <img src={Img3} alt={Img3} />
                </div>
                <div className="company-details-img">
                  <img src={Img4} alt={Img4} />
                </div>
              </div>
            </div>
            <div className="company-details-sections">
              <div>
                <h3 className="company-details-section-title">
                  Management Philosophy
                </h3>
              </div>
              <div>
                <h2 className="company-details-section-heading">
                  Truly Inspired
                </h2>
              </div>
              <div>
                <ul>
                  <li>
                    How often do we consider the countries and communities we
                    touch, and how much empathy do we have for the people living
                    there?
                  </li>
                  <li>
                    Are we contemplating what’s best for them and then doing our
                    utmost to act in their interests?
                  </li>
                  <li>
                    Each day, we must reflect on our actions; we must strive to
                    do better not only for ourselves, but also for others.
                  </li>
                  <li>
                    When we come together and our efforts are united, we make
                    the impossible possible.
                  </li>
                </ul>
              </div>
              <div>
                <strong>
                  With integrity, solidarity, and determination, we will
                  overcome any challenge we face.
                </strong>
              </div>
              <div>
                <h3 className="company-details-section-title">
                  Commitment to Sustainability
                </h3>
              </div>
              <div className="company-details-sections-imgs">
                <div className="company-details-section2-img">
                  <img src={Img5} alt={Img5} />
                </div>
              </div>
              <div>
                <p>
                  Idemitsu aims to contribute to the realization of a
                  carbon-neutral and circular society by demonstrating social
                  implementations and fulfilling its{' '}
                  <strong>
                    responsibility to support people and their daily lives and
                    the responsibility to protect the global environment now and
                    in the future.
                  </strong>
                </p>
              </div>
              <div>
                <strong>Initiatives:</strong>
              </div>
              <div>
                <ul>
                  <li>Reducing CO2 Emissions Throughout the Value Chain</li>
                  <li>
                    Promoting Energy Conservation and Zero – Emission Electric
                    Power
                  </li>
                  <li>Provision of Eco-Friendly Products and Services</li>
                  <li>Expansion of Renewable Energy Power Generation</li>
                  <li>Expansion of Biomass Fuel Supply</li>
                  <li>
                    Collaboration With Other Companies to Expand Renewable
                    Energy
                  </li>
                  <li>
                    Development and Social Implementation of Innovative
                    Technologies
                  </li>
                </ul>
              </div>
            </div>
            <div className="company-details-sections">
              <div>
                <h2 className="company-details-section-heading">
                  Our Passion for Innovation
                </h2>
              </div>
              <div>
                <h3 className="company-details-section-title">
                  Expansion and Diversification
                </h3>
              </div>
              <div>
                <p>
                  In the post-war era, Idemitsu Kosan ventured into refining
                  crude oil, marking a crucial step towards becoming an
                  integrated energy company. As Japan’s economy flourished,
                  Idemitsu continued to broaden its scope, entering new sectors
                  such as petrochemicals and electric power.
                </p>
              </div>
              <div>
                <h3 className="company-details-section-title">
                  Global Presence
                </h3>
              </div>
              <div>
                <p>
                  Idemitsu’s influence flows beyond Japan, contributing
                  significantly to diverse industries worldwide such as
                  petrochemical, petroleum, coal, oil exploration and
                  production, and lubricants. By establishing itself across
                  continents and through partnerships and collaborations with
                  industry leaders, Idemitsu’s commitment makes a positive
                  impact on a global scale.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CompanyDetails;
