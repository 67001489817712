import './style.css';
import React from 'react';
import { Grid } from '@mui/material';
import Year from './historyyears/Year';
// import {
//   FontSize,
//   ThemeColors,
//   ThemeFontWeight,
//   ThemeFontsFamily,
// } from '../../../../utils/Theme';
import image1 from './../../../../images/about-images/1911.png';
import image2 from './../../../../images/about-images/1913.png';
import image3 from './../../../../images/about-images/1914.png';
import image4 from './../../../../images/about-images/1945.png';
import image5 from './../../../../images/about-images/1947.png';
import image6 from './../../../../images/about-images/Idemitsu-old-logo.png';
import image7 from './../../../../images/about-images/1953.png';
import image8 from './../../../../images/about-images/1957.png';
import image9 from './../../../../images/about-images/1964.png';
import image10 from './../../../../images/about-images/1966.png';
import image11 from './../../../../images/about-images/1972.png';
import image12 from './../../../../images/about-images/1992.png';
import image13 from './../../../../images/about-images/1994.png';
import image14 from './../../../../images/about-images/1996.png';
import image15 from './../../../../images/about-images/2006.png';
import image16 from './../../../../images/about-images/idemitsu-new.png';
import image17 from './../../../../images/about-images/2018.png';

// function TabPanel(props) {
//   // const { children, value, index, ...other } = props;

//   // return (
//   //   <div
//   //     role="tabpanel"
//   //     hidden={value !== index}
//   //     id={`vertical-tabpanel-${index}`}
//   //     aria-labelledby={`vertical-tab-${index}`}
//   //     {...other}
//   //   >
//   //     {value === index && (
//   //       <Box sx={{ p: 2 }}>
//   //         <Typography component={'div'}>{children}</Typography>
//   //       </Box>
//   //     )}
//   //   </div>
//   // );
// }

// TabPanel.propTypes = {
//   children: PropTypes.node,
//   index: PropTypes.number.isRequired,
//   value: PropTypes.number.isRequired,
// };

// function a11yProps(index) {
//   return {
//     id: `vertical-tab-${index}`,
//     'aria-controls': `vertical-tabpanel-${index}`,
//   };
// }

const CompanyHistory = () => {
  // const [value, setValue] = React.useState(0);
  // const handleChange = (event, newValue) => {
  //   setValue(newValue);
  // };
  return (
    <>
      <Grid container justifyContent="center">
        <Grid
          item
          display="flex"
          justifyContent="center"
          lg={12}
          md={12}
          xs={12}
          sm={12}
        >
          <div className="history-menu-tabs">
            <div>
              <div>
                <Year
                  image={image1}
                  title="In 1911, Idemitsu..."
                  desc="On June 20, 1911, Idemitsu Shokai, the predecessor of Idemitsu Kosan, took its first steps in the oil sales business at the trading port of Moji in Kitakyushu, Japan.
                    At that time, Fukuoka Prefecture accounted for more than 70% of Japan's coal production. However, since his time as a student company founder Sazo Idemitsu, who hailed from Munakata in Fukuoka Prefecture, had focused on the future potential of petroleum rather than coal, and in his graduation thesis he described with certainty the future prospects arising from the economic advantages of petroleum."
                  desc1="At the time of the company's founding, the dominance of the two major foreign oil companies Standard Oil (now Exxon) of the United States and Rising Sun (now Shell) had broken down and bitter competition was unfolding among domestic oil producers, as conditions of uncertainty continued in the markets for petroleum products.
                    Idemitsu Shokai, which began as a distributor for Nippon Sekiyu, started first developing sales channels to coal mines and factories in the business of sale of lubricants (machine oil), adopting a policy of avoiding speculative trading and intermediary exploitation and instead seeking to benefit both producers and consumers through linking them directly with each other. Based in Moji, it grew its sales channels to cover the entire island of Kyushu, Osaka, Nagoya, and even mainland China."
                />
              </div>
              <div>
                <Year
                  image={image2}
                  title="In 1913, Idemitsu..."
                  desc="Launched sales of fuel oil for fishing ships."
                />
              </div>
              <div>
                <Year
                  image={image3}
                  title="In 1914, Idemitsu..."
                  desc="Sales channel opened overseas (former Manchuria)."
                />
              </div>
              <div>
                <Year
                  image={image5}
                  title="In 1945, Idemitsu..."
                  desc="On August 15, 1945, with the end of the Second World War Idemitsu lost all its businesses and overseas assets. All that remained were 2.6 million yen in debts (measured in their current value at that time) and about 1,000 employees in Japan and overseas.
                    Beginning at the end of 1945, about 800 employees who had been dispatched to the Korean Peninsula, mainland China, and Southeast Asia returned in succession. While most firms were cutting their workforces, Idemitsu adopted and implemented a policy of not laying off any employees."
                  desc1="Immediately after the end of the war, as petroleum controls remained in existence Idemitsu was in charge only of details of oil distribution at a number of branches including Wakamatsu, Beppu, and Nagoya – a task that had continued since during wartime. Since there was no work for repatriated staff to do, the company decided to endure the emergency situation by taking on a variety of businesses, such as recovery of oil from the bottom of former Japan Navy tanks, radio repairs and sales, printing, agriculture, production of soy sauce and vinegar, and marine products industry.
                    While most of these businesses ended in failure, the radio repairs and sales business opened about 50 shops in major cities across Japan, serving as a preparation for the future development of Idemitsu's retail network in the oil business."
                />
              </div>
              <div>
                <Year
                  image={image4}
                  title="In 1947, Idemitsu..."
                  desc="Idemitsu returned to the oil business as 29 of its outlets across Japan are named retailers for the Oil Distribution Public Corporation. "
                />
              </div>
              <div>
                <Year
                  image={image6}
                  title="In 1952, Idemitsu..."
                  desc="With the conclusion of the San Francisco Peace Treaty in September 1951, Japan regained a position as part of international society. In July of the following year, controls on consumption of petroleum products were released after 14 years.
                    Also, in May 1952 foreign currency was allocated to gasoline imports as well, and Idemitsu imported 5,000 kiloliters of gasoline (with an octane rating of 75 and an actual value of 77.2) from the U.S. West Coast, aboard the second-generation Nissho Maru, which had been commissioned in the previous year. It sold this gasoline under the Apollo brand name."
                  desc1="At that time, the octane rating of gasoline sold in Japan had been only about 60. Under these conditions, the newly introduced Apollo gasoline earned a reputation for increasing a vehicle's horsepower, making it possible to drive over the mountains in the Hakone region of Japan without stalling.
                    With the appearance of Apollo gasoline, the quality of gasoline in Japan improved, as each oil refining company progressively introduced oil reforming and cracking units."
                />
              </div>
              <div>
                <Year
                  image={image7}
                  title="In 1953, Idemitsu..."
                  desc="In March 1953, Idemitsu secretly sent the second-generation Nissho Maru to Iran, which was involved in a dispute with Great Britain after nationalizing its oil industry. Loaded with about 22,000 kiloliters of gasoline and diesel oil, the Nissho Maru returned to the Port of Kawasaki in May, greeted by welcoming crowds.
                    In response, the British Anglo-Iranian Oil Company (the predecessor of BP) sued Idemitsu in Tokyo District Court, claiming ownership of the Nissho Maru's cargo. This “Nissho Maru Incident” would be fought out in the courts. The judicial process was reported on in detail in the newspapers day after day, and in the end Idemitsu was victorious, as the Anglo-Iranian Oil Company dropped its case."
                  desc1="Imports of Iranian oil ended in 1956, after the unity of the major oil firms (international oil capital) again strengthened in Iran.
                    However, this incident was a predecessor of direct trades with oil-producing states and served as an impetus in turning the eyes of Japanese people to the Middle East. History also shows that the incident was seen in the Japan of that time, which had lost its confidence as a result of its loss in World War II, as a heroic deed that helped the nation regain its stature in international society.
                    "
                />
              </div>
              <div>
                <Year
                  image={image8}
                  title="In 1957, Idemitsu..."
                  desc="As a postwar petroleum policy, Japan adopted the method of refining oil in the market where it was consumed-that is, importing crude oil and refining it into products in Japan-with the intended course being one of gradually reducing and then eventually eliminating allocation of foreign currency to imports of oil products.
                    Immediately after being named a primary oil distributor, Idemitsu began preparations toward construction of a refinery. After receiving disposal of the former site of a Japan Navy fuel depot in the city of Tokuyama in Yamaguchi Prefecture, in March 1957 it completed the Tokuyama Refinery."
                  desc1="Major highlights of this refinery were its state-of-the-art automated equipment and the green belt planted around the perimeter of the facility-an initiative in plant greenification and beautification at a time before concerns had arisen about pollution-related problems. The color schemes of the refinery's equipment and structures were chosen to be in harmony with the surrounding scenery as well.
                    
                    "
                />
              </div>
              <div>
                <Year
                  image={image9}
                  title="In 1964, Idemitsu..."
                  desc="Idemitsu Petrochemical Co., Ltd. established, Tokuyama Plant completed"
                />
              </div>
              <div>
                <Year
                  image={image10}
                  title="In 1966, Idemitsu..."
                  desc="In response to the Suez Canal Incident of 1956, shipbuilders began constructing mammoth tankers with capacities of 66,000 tons or more, which could be competitive even when traveling over routes via Capetown. Starting with the Universe Admiral (85,000-ton capacity) in 1956, Idemitsu then went on to build the third-generation Nissho Maru, at the time the world's largest tanker with a capacity of 139,000 tons."
                  desc1="Also, in August 1962 Idemitsu Tanker was established in the international tanker business, as Idemitsu began full-fledged participation in the maritime shipping business for the first time since the war. In December 1966, the Idemitsu Maru (209,000-ton capacity), the world's first 200,000-ton-class very large crude carrier (VLCC) was commissioned.
                    Together with securing a stable supply of crude oil through using its own vessels, Idemitsu's construction of large tankers also contributed to the development of the shipbuilding industry in Japan."
                />
              </div>
              <div>
                <Year
                  image={image11}
                  title="In 1972, Idemitsu..."
                  desc="stablished Petrochemicals (Malaysia) Sdn. Bhd."
                />
              </div>
              <div>
                <Year
                  image={image12}
                  title="In 1992, Idemitsu..."
                  desc="Service stations opened in Puerto Rico (1992 - 2001)
                    Lubricants production plant completed in the U.S.
                    Production begins at the Snorre Field in the Norwegian North Sea"
                />
              </div>
              <div>
                <Year
                  image={image13}
                  title="In 1994, Idemitsu..."
                  desc="Dhahran Office established
                    Production begins at Ensham Coal Mine in Australia
                    Heavy oil cracking unit completed at Hokkaido Refinery"
                />
              </div>
              <div>
                <Year
                  image={image14}
                  title="In 1996, Idemitsu..."
                  desc="In June 1992, the Earth Summit was held in Rio de Janeiro, adopting a policy of sustainable development. In response, each member of Japan's oil industry developed its own action plan for environmental protection.
                    As the movement toward environmental protection gained steam, in December 1993 Idemitsu began sale of Idemitsu Super Zearth (high-octane) and Idemitsu Zearth (regular) gasoline, with benzene content reduced to no more than one percent."
                  desc1="At the time, benzene in the atmosphere had been identified as cancerous by a variety of organizations but the substance was not yet regulated in Japan. Beginning in April 1996, benzene content was restricted to no more than 5%-a level ratified in light of the quality level of gasoline on sale at the time. At that point, Idemitsu's products earned Eco Mark certification as earth-friendly gasoline.
                    "
                />
              </div>
              <div>
                <Year
                  image={image15}
                  title="In 2006, Idemitsu..."
                  desc="Businesses of Idemitsu Gas and Life Co., Ltd. and Mitsubishi Liquified Gas Co. merged
                    Idemitsu Unitech Co., Ltd. absorbs Idemitsu Plastics Co., Ltd.
                    Stock listed on First Section of the Tokyo Stock Exchange"
                />
              </div>
              <div>
                <Year
                  image={image17}
                  title="In 2018, Idemitsu..."
                  desc="Share Exchange Agreement between Idemitsu Kosan Co., Ltd and Showa Shell Sekiyu K.K. has been approved at extraordinary shareholder meeting"
                />
              </div>
              <div>
                <Year
                  image={image16}
                  title="In 2019, Idemitsu..."
                  desc="The Business Integration with Showa Shell Sekiyu K.K. has been implemented."
                />
              </div>
              <div>
                <Year
                  image={image17}
                  title="In 2023, Idemitsu..."
                  desc="Idemitsu Brand Motor Oil (IBMO) was launched in Pakistan."
                />
              </div>
            </div>
          </div>
        </Grid>
      </Grid>
    </>
  );
};

export default CompanyHistory;
