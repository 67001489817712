import React from 'react';
import { Grid } from '@mui/material';
// import {
//   FontSize,
//   ThemeColors,
//   ThemeFontWeight,
//   ThemeFontsFamily,
// } from '../../../../../utils/Theme';
import Doted from '../../../../../images/doted-line.png';
const ItemTotal = ({ children, shippingFee, totalPrice }) => {
  return (
    <>
      <Grid container>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <div className="order-summary">
            <div>
              <h2>ORDER SUMMARY</h2>
            </div>
            <div className="order-summary-border">
              <img src={Doted} alt={Doted} />
            </div>
            <div>
              <div className="order-summary-total">
                <div>
                  <p>SUB TOTAL</p>
                </div>
                <div>
                  <h3>{totalPrice}</h3>
                </div>
              </div>
              <div className="order-summary-total">
                <div>
                  <p>SHIPPING FEE</p>
                </div>
                <div>
                  <h3>{shippingFee}</h3>
                </div>
              </div>
            </div>
            {/* <div>
              <h4>Promo Code:</h4>
            </div> */}
            {/* <div>
              <input
                className="order-summary-input"
                type="text"
                placeholder="Please Enter Promocode..."
              />
            </div> */}
            {/* <div>
              <div className="order-summary-button">
                <Button
                  // onClick={handleClick}
                  className="button"
                  variant="contained"
                  sx={{
                    backgroundColor: '#0F9A57',
                    padding: '10px 35px',
                    width: '111px',
                    height: '27px',
                    borderRadius: '100px',
                    color: ThemeColors.White,
                    fontFamily: ThemeFontsFamily.Poppins,
                    fontWeight: ThemeFontWeight.Regular,
                    fontSize: FontSize.regular,
                    textTransform: 'capitalize',
                    '&.MuiButton-root:hover': {
                      backgroundColor: '#0F9A57',
                    },
                  }}
                >
                  Enter
                </Button>
              </div>
            </div> */}
            <div className="order-summary-border">
              <img src={Doted} alt={Doted} />
            </div>
            <div className="order-total">
              <div>
                <h4>Total:</h4>
              </div>
              <div>
                <h3>{shippingFee + totalPrice}</h3>
              </div>
            </div>
            <div className="order-summary-border">
              <img src={Doted} alt={Doted} />
            </div>
            <div>
              <div className="order-checkout-button">
                {/* <NavLink to={'/Order Details'}> */}
                {children}
                {/* </NavLink> */}
              </div>
            </div>
          </div>
        </Grid>
      </Grid>
    </>
  );
};

export default ItemTotal;
