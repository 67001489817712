/* eslint-disable no-unused-vars */
import './style.css';
import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Box, Tabs, Tab, Typography } from '@mui/material';
import Tab1 from './tab1';
import Tab2 from './tab2';
import Tab3 from './tab3';
import Tab4 from './tab4';
import {
  FontSize,
  ThemeColors,
  ThemeFontWeight,
  ThemeFontsFamily,
} from '../../../../utils/Theme';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 1 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

const MenuTabs = () => {
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <Grid container>
        <Grid item lg={12} md={12} xs={12} sm={12}>
          <div className="menu-tabs">
            <Box>
              <Tabs
                indicatorColor="tansparent"
                value={value}
                onChange={handleChange}
                variant="scrollable"
                scrollButtons="auto"
                aria-label="scrollable auto tabs example"
                className="menu-history-tab"
              >
                <Tab
                  sx={{
                    '&.Mui-selected': {
                      color: ThemeColors.MainHeadings,
                      '&.MuiTab-root': {
                        fontSize: FontSize.large,
                        fontWeight: ThemeFontWeight.Medium,
                        fontFamily: ThemeFontsFamily.Poppins,
                      },
                    },
                  }}
                  label="Idemitsu"
                  {...a11yProps(0)}
                />
                <Tab
                  sx={{
                    '&.Mui-selected': {
                      color: ThemeColors.MainHeadings,
                      '&.MuiTab-root': {
                        fontSize: FontSize.large,
                        fontWeight: ThemeFontWeight.Medium,
                        fontFamily: ThemeFontsFamily.Poppins,
                      },
                    },
                  }}
                  label="History"
                  {...a11yProps(1)}
                />
                <Tab
                  sx={{
                    '&.Mui-selected': {
                      color: ThemeColors.MainHeadings,
                      '&.MuiTab-root': {
                        fontSize: FontSize.large,
                        fontWeight: ThemeFontWeight.Medium,
                        fontFamily: ThemeFontsFamily.Poppins,
                      },
                    },
                  }}
                  label="Idemitsu Lube Pakistan"
                  {...a11yProps(2)}
                />
                <Tab
                  sx={{
                    '&.Mui-selected': {
                      color: ThemeColors.MainHeadings,
                      '&.MuiTab-root': {
                        fontSize: FontSize.large,
                        fontWeight: ThemeFontWeight.Medium,
                        fontFamily: ThemeFontsFamily.Poppins,
                      },
                    },
                  }}
                  label="Message from CEO"
                  {...a11yProps(3)}
                />
              </Tabs>
            </Box>
            <div>
              <TabPanel value={value} index={0}>
                <div>
                  <Tab1 />
                </div>
              </TabPanel>
              <TabPanel value={value} index={1}>
                <div>
                  <Tab2 />
                </div>
              </TabPanel>
              <TabPanel value={value} index={2}>
                <div>
                  <Tab3 />
                </div>
              </TabPanel>
              <TabPanel value={value} index={3}>
                <div>
                  <Tab4 />
                </div>
              </TabPanel>
            </div>
          </div>
        </Grid>
      </Grid>
    </>
  );
};

export default MenuTabs;
