import React from 'react';
import ImgOne from '../../../images/product-imgs/ATF/PK ATF 1L F.png';
// import ImgTwo from '../../../images/product-imgs/IFG3/PK_IFG3 0W-20_SP-GF-6A_4L_P.png';
import ProductDetails from '../../productdetails';
import Layout from '../../../Layout/Layout';
import { Grid } from '@mui/material';

const ATF = () => {
  return (
    <Layout>
      <Grid container>
        <Grid item xl={12} lg={12} md={12} xs={12}>
          <ProductDetails
            title="Idemitsu Multi Vehicle ATF 1L"
            price="Rs 3,025.64"
            img={ImgOne}
            product_desc="Idemitsu Multi Vehicle ATF is the latest technologically advanced automatic transmission fluid suitable for use in all modern AT units. This special formulation enhances smooth & positive shifting under severe working conditions."
            adv_tit="Compatible with:"
            b_adv_1="ATF DEXRON II E / 5D06 / 2384K / 3314 / 3317, ATF Fluid D-II / D-III / WS, ATF JWS 3317, Alumix Fluid ATF DII / DIII Multi / D3-SP, ATF Type T / T-II / T-III / T-IV, ATF M-III / M-V / FZ, Besco ATF II / III, Dia-Queen: ATF II / ATF AW / ATF-SPII / ATF SPII M / ATF J-2, DEXRON II-E, DEXRON III / VI / III-G, Diamond ATF SP-IIM / III, MERCON, Ultra ATF / ATF Z1 / ATF DW-1"
          />
        </Grid>
      </Grid>
    </Layout>
  );
};

export default ATF;
