import React from 'react';
import Layout from '../../Layout/Layout';
// import CareerBanner from './careerbanner';
import { Grid } from '@mui/material';
import CareerInfo from './careerinfo';
// import CareerJob from './careerjob';

const Careers = () => {
  return (
    <Layout>
      <Grid container>
        <div style={{ marginTop: '6em' }}>
          {/* <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <CareerBanner />
          </Grid> */}
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <CareerInfo />
          </Grid>
          {/* <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <CareerJob />
          </Grid> */}
        </div>
      </Grid>
    </Layout>
  );
};

export default Careers;
