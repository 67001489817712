import React, { useState, useEffect } from 'react';
import { Typography, Button } from '@mui/material';
import {
  ThemeColors,
  ThemeFontsFamily,
  ThemeFontWeight,
  FontSize,
} from '../../../../../utils/Theme';
import './style.css';
import Iframe from 'react-iframe';
import Buttons from '../../../../../components/button/Buttons';
import SelectOpt from '../../../../../components/selectopt';
const RetailStore = (props) => {
  const { jsonData, storeType } = props;
  const storeData = jsonData;
  const [selectedCountry, setselectedCountry] = useState('');
  const [filterCountry, setFilterCountry] = useState([]);
  const [selectedCities, setSelectedCities] = useState([]);
  const [selectedCity, setSelectedCity] = useState('');
  const [selectedAreas, setSelectedAreas] = useState([]);
  const [selectedArea, setSelectedArea] = useState('');
  const [selectedShops, setSelectedShops] = useState([]);
  const [selectedAreaMap, setSelectedAreaMap] = useState('');
  const uniqueCountries = [
    ...new Set(
      storeData
        .map((country) => {
          const store = country[0];
          return store.country;
        })
        .sort()
    ),
  ];

  const filterByCountry = (data, { value: countryName }) => {
    const filteredArray = data.filter(
      (item) => item[0].country === countryName
    );
    return filteredArray;
  };

  const handleCountry = (selectedCountry) => {
    setselectedCountry(selectedCountry);
    setSelectedCity('');
    setSelectedArea('');
    setSelectedShops([]);
    setSelectedAreaMap('');
  };
  const handleCity = (selectedCity) => {
    setSelectedCity(selectedCity);
    setSelectedArea('');
    setSelectedShops([]);
    setSelectedAreaMap('');
  };

  const handleArea = (selectedArea) => {
    setSelectedShops([]);
    setSelectedAreaMap('');
    setSelectedArea(selectedArea);
  };

  useEffect(() => {
    setFilterCountry(filterByCountry(storeData, selectedCountry));
  }, [storeData, selectedCountry]);

  useEffect(() => {
    const cities = filterCountry.map((countryData) => countryData[0].city_name);
    setSelectedCity('');
    setSelectedAreas([]);
    setSelectedAreaMap('');
    setSelectedCities(cities);
  }, [filterCountry]);

  useEffect(() => {
    if (selectedCity) {
      const cityData = filterCountry.find(
        (countryData) => countryData[0].city_name === selectedCity.value
      );

      if (cityData && cityData[0].areas) {
        const areasArray = cityData[0].areas.map(
          (areaObj) => areaObj.area_name
        );
        setSelectedAreas(areasArray);
      } else {
        setSelectedAreas([]);
        setSelectedAreaMap('');
      }
    }
  }, [selectedCity, filterCountry]);
  const handleSearchList = () => {
    if (selectedCountry.value && selectedCity.value && selectedArea.value) {
      const cityData = storeData.find(
        (countryData) => countryData[0].city_name === selectedCity.value
      );

      if (cityData && cityData[0].areas) {
        const selectedAreaData = cityData[0].areas.find(
          (area) => area.area_name === selectedArea.value
        );

        if (selectedAreaData && selectedAreaData.shops) {
          setSelectedShops(selectedAreaData.shops);
          setSelectedAreaMap(selectedAreaData.stores_google_map);
        } else {
          setSelectedShops([]);
          setSelectedAreaMap('');
        }
      } else {
        setSelectedShops([]);
        setSelectedAreaMap('');
      }
    } else {
      setSelectedShops([]);
      setSelectedAreaMap('');
    }
    //console.log(selectedCountry.value, " ", selectedCity.value, " ", selectedArea.value  )
  };
  const [selectedOption, setSelectedOption] = useState('mapView');
  const handleOptionChangeView = (event) => {
    setSelectedOption(event.target.value);
  };

  return (
    <>
      <div className="main-search-container">
        <div className="searching-section-where">
          <div className="filter-search-field">
            <SelectOpt
              placeholder={'Country'}
              handleChange={handleCountry}
              optionsList={uniqueCountries}
              isDisabled={false}
            />
          </div>
          <div className="filter-search-field">
            <SelectOpt
              placeholder={'City'}
              handleChange={handleCity}
              optionsList={selectedCities}
              value={selectedCity}
              isDisabled={selectedCities.length === 0 ? true : false}
            />
          </div>
          <div className="filter-search-field">
            <SelectOpt
              placeholder={'Area/Town/District'}
              handleChange={handleArea}
              optionsList={selectedAreas}
              value={selectedArea}
              isDisabled={selectedAreas.length === 0 ? true : false}
            />
          </div>
          <div className="filter-search-btn">
            <Buttons
              btnBg="#E11737"
              buttonText={'Search Now'}
              onClick={handleSearchList}
            />
          </div>
        </div>
        <div className="search-results">
          <div className="store-details">
            <div className="store-result-hr">
              <Typography
                mt={2}
                color={ThemeColors.BlackHeading}
                sx={{
                  fontFamily: ThemeFontsFamily.Volkhov,
                  fontWeight: ThemeFontWeight.SemiBold,
                  fontSize: FontSize.xxxlarge,
                  marginLeft: '12px',
                }}
                component="div"
                variant="h5"
              >
                IDEMITSU {storeType}
              </Typography>
              <div className="change-view-radio-btn">
                <label>
                  <input
                    type="radio"
                    value="mapView"
                    checked={selectedOption === 'mapView'}
                    onChange={handleOptionChangeView}
                  />
                  <span className="map-label">Map View</span>
                </label>
                <label>
                  <input
                    type="radio"
                    value="listView"
                    checked={selectedOption === 'listView'}
                    onChange={handleOptionChangeView}
                  />
                  <span className="list-label">List View</span>
                </label>
              </div>
            </div>
            {selectedOption === 'listView' ? (
              selectedShops && selectedCountry && selectedCity ? (
                selectedShops.map((shop, index) => {
                  return (
                    <div className="store-details-list" key={index}>
                      <div>
                        <div>
                          <Typography
                            mt={1}
                            color={ThemeColors.ShopName}
                            sx={{
                              fontFamily: ThemeFontsFamily.Poppins,
                              fontWeight: ThemeFontWeight.SemiBold,
                              fontSize: FontSize.xlarge,
                            }}
                            variant="h5"
                          >
                            {shop.shop_name}
                          </Typography>
                        </div>
                        <div>
                          <Typography
                            mt={1}
                            color={ThemeColors.ShopName}
                            sx={{
                              fontFamily: ThemeFontsFamily.Poppins,
                              fontWeight: ThemeFontWeight.Medium,
                              fontSize: FontSize.regular,
                              width: '290px',
                            }}
                            variant="h5"
                          >
                            {shop.shop_location}
                          </Typography>
                        </div>
                        <div>
                          <Typography
                            mt={1}
                            color={ThemeColors.ShopName}
                            sx={{
                              fontFamily: ThemeFontsFamily.Poppins,
                              fontWeight: ThemeFontWeight.Medium,
                              fontSize: FontSize.regular,
                              width: '290px',
                            }}
                            variant="h5"
                          >
                            {shop.phone}
                          </Typography>
                        </div>
                      </div>
                      <div>
                        <div>
                          <Typography
                            mt={1}
                            color={ThemeColors.ShopName}
                            sx={{
                              fontFamily: ThemeFontsFamily.Poppins,
                              fontWeight: ThemeFontWeight.Medium,
                              fontSize: FontSize.regular,
                            }}
                            variant="h5"
                          >
                            {selectedCity.label}
                          </Typography>
                        </div>
                        <div>
                          <Typography
                            mt={1}
                            color={ThemeColors.ShopName}
                            sx={{
                              fontFamily: ThemeFontsFamily.Poppins,
                              fontWeight: ThemeFontWeight.Medium,
                              fontSize: FontSize.regular,
                            }}
                            variant="h5"
                          >
                            {selectedCountry.label}
                          </Typography>
                        </div>
                      </div>
                      <div>
                        <div>
                          <Button
                            onClick={handleOptionChangeView}
                            className="button"
                            variant="contained"
                            sx={{
                              backgroundColor: ThemeColors.GreenButtonColor,
                              borderRadius: '30px',
                              padding: '10px 15px',
                              color: ThemeColors.White,
                              fontFamily: ThemeFontsFamily.Poppins,
                              fontWeight: ThemeFontWeight.Regular,
                              fontSize: FontSize.regular,
                              textTransform: 'none',
                              width: '150px',
                              '&.MuiButton-root:hover': {
                                backgroundColor: ThemeColors.GreenButtonColor,
                              },
                            }}
                          >
                            Get Direction
                          </Button>
                        </div>
                      </div>
                    </div>
                  );
                })
              ) : (
                <div className="store-details-list">
                  <div>
                    <div>
                      <Typography
                        mt={1}
                        color={ThemeColors.ShopName}
                        sx={{
                          fontFamily: ThemeFontsFamily.Poppins,
                          fontWeight: ThemeFontWeight.SemiBold,
                          fontSize: FontSize.xlarge,
                        }}
                        variant="h5"
                      >
                        Idemitsu Lube Pakistan (Private) Limited (ILPK)
                      </Typography>
                    </div>
                    <div>
                      <Typography
                        mt={1}
                        color={ThemeColors.ShopName}
                        sx={{
                          fontFamily: ThemeFontsFamily.Poppins,
                          fontWeight: ThemeFontWeight.Medium,
                          fontSize: FontSize.regular,
                          width: '290px',
                        }}
                        variant="h5"
                      >
                        Emerald Tower, 11th Floor, Office #1103,Block 5 Clifton,
                        Karachi, Karachi City, Sindh 74000
                      </Typography>
                    </div>
                    <div>
                      <Typography
                        mt={1}
                        color={ThemeColors.ShopName}
                        sx={{
                          fontFamily: ThemeFontsFamily.Poppins,
                          fontWeight: ThemeFontWeight.Medium,
                          fontSize: FontSize.regular,
                          width: '290px',
                        }}
                        variant="h5"
                      >
                        +92 21 111 007 111
                      </Typography>
                    </div>
                  </div>
                  <div>
                    <div>
                      <Typography
                        mt={1}
                        color={ThemeColors.ShopName}
                        sx={{
                          fontFamily: ThemeFontsFamily.Poppins,
                          fontWeight: ThemeFontWeight.Medium,
                          fontSize: FontSize.regular,
                        }}
                        variant="h5"
                      >
                        Karachi
                      </Typography>
                    </div>
                    <div>
                      <Typography
                        mt={1}
                        color={ThemeColors.ShopName}
                        sx={{
                          fontFamily: ThemeFontsFamily.Poppins,
                          fontWeight: ThemeFontWeight.Medium,
                          fontSize: FontSize.regular,
                        }}
                        variant="h5"
                      >
                        Pakistan
                      </Typography>
                    </div>
                  </div>
                  <div>
                    <div>
                      <Button
                        onClick={handleOptionChangeView}
                        className="button"
                        variant="contained"
                        sx={{
                          backgroundColor: ThemeColors.GreenButtonColor,
                          borderRadius: '30px',
                          padding: '10px 15px',
                          color: ThemeColors.White,
                          fontFamily: ThemeFontsFamily.Poppins,
                          fontWeight: ThemeFontWeight.Regular,
                          fontSize: FontSize.regular,
                          textTransform: 'none',
                          width: '150px',
                          '&.MuiButton-root:hover': {
                            backgroundColor: ThemeColors.GreenButtonColor,
                          },
                        }}
                      >
                        Get Direction
                      </Button>
                    </div>
                  </div>
                </div>
              )
            ) : (
              <div className="google-map">
                {selectedAreaMap ? (
                  <div className="mapouter">
                    <div className="gmap_canvas">
                      <Iframe
                        id="gmap_canvas"
                        url={selectedAreaMap}
                        frameborder="0"
                        scrolling="no"
                      />
                    </div>
                  </div>
                ) : (
                  <div>
                    <div className="mapouter">
                      <div className="gmap_canvas">
                        <Iframe
                          id="gmap_canvas"
                          url="https://shorturl.at/flqxO"
                          frameborder="0"
                          scrolling="no"
                        />
                      </div>
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default RetailStore;
