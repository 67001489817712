// import { useCookies } from 'react-cookie';
import './App.css';
// import CookieConsent from './components/cookies';
import MainRoutes from './routes/mainroutes';

function App() {
  // const [cookies] = useCookies(['cookieConsent']);
  return (
    <div>
      {/* <CookieConsent /> */}
      <MainRoutes />
      {/* {!cookies.cookieConsent && <CookieConsent />} */}
    </div>
  );
}

export default App;
