import React from 'react';
import { Button } from '@mui/material';
import {
  FontSize,
  ThemeColors,
  ThemeFontsFamily,
  ThemeFontWeight,
} from '../../utils/Theme';

const ItemsButton = ({ btntxt, onClick }) => {
  return (
    <Button
      onClick={onClick}
      className="button"
      variant="contained"
      sx={{
        backgroundColor: '#E11737',
        padding: '10px 35px',
        width: '270px',
        height: '44px',
        borderRadius: '100px',
        color: ThemeColors.White,
        fontFamily: ThemeFontsFamily.Poppins,
        fontWeight: ThemeFontWeight.Regular,
        fontSize: FontSize.regular,
        textTransform: 'capitalize',
        '&.MuiButton-root:hover': {
          backgroundColor: '#E11737',
        },
      }}
    >
      {btntxt}
    </Button>
  );
};

export default ItemsButton;
