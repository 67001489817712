import './style.css';
import React from 'react';
import Layout from '../../../Layout/Layout';
import { Grid } from '@mui/material';
import OrderHeading from '../ordersummary/orderheading';
import OrderPersonDetail from './orderpersondetails';

const OrderDetails = () => {
  return (
    <Layout>
      <Grid p={4} container className="order-details-page-bg">
        <Grid item xl={12} lg={12} md={12} xs={12}>
          <OrderHeading
            heading="Shipping Address"
            desc="Add address of the person you want to send items"
          />
          <OrderPersonDetail />
        </Grid>
      </Grid>
    </Layout>
  );
};

export default OrderDetails;
