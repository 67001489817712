import React, { useContext } from 'react';
import { Grid } from '@mui/material';
import ItemTotal from '../../ordersummary/orderitems/itemtotal';
import Details from './details';
import Items from '../../ordersummary/orderitems/items';
import { CartContext } from '../../../../context/cartContext';

const OrderPersonDetail = () => {
  const { totalPrice, shippingFee } = useContext(CartContext);

  return (
    <Grid container>
      <Grid item mb={3} xl={8} lg={8} md={12} sm={12} xs={12}>
        <Details />
        <Items />
      </Grid>
      <Grid item mb={3} xl={4} lg={4} md={12} sm={12} xs={12}>
        <ItemTotal
          totalPrice={totalPrice}
          shippingFee={shippingFee}
        ></ItemTotal>
      </Grid>
    </Grid>
  );
};

export default OrderPersonDetail;
