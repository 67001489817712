// import './style.css';
import React from 'react';
// import Layout from '../../../Layout/Layout';
import { Grid } from '@mui/material';
// import OrderView from './orderlist';
// import OrderHeading from '../ordersummary/orderheading';
import Layout from '../../Layout/Layout';
import OrderHeading from '../orderdetails/ordersummary/orderheading';
import Account from './account';

const Profile = () => {
  return (
    <Layout>
      <Grid container p={4} className="order-details-page-bg">
        <Grid item xl={12} lg={12} md={12} xs={12}>
          <OrderHeading heading="My Account" desc="Profie" />
          <Account />
        </Grid>
      </Grid>
    </Layout>
  );
};

export default Profile;
