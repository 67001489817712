import './style.css';
import { Grid } from '@mui/material';
import React from 'react';

const ProductRangeText = (props) => {
  const { title, desc } = props;
  return (
    <Grid container>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <div className="products-page-headings">
          <h2 className="product-page-heading-h2">{title}</h2>
        </div>
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <p className="products-page-para">{desc}</p>
      </Grid>
    </Grid>
  );
};

export default ProductRangeText;
