import React from 'react';
import { Grid } from '@mui/material';
import Items from '../../ordersummary/orderitems/items';
import ShipingPerson from './shippingperson';
import Billing from './billing';

const ShippingDetail = () => {
  return (
    <Grid container>
      <Grid item xl={8} lg={8} md={12} sm={12} xs={12}>
        <ShipingPerson />
      </Grid>
      <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
        <Billing />
      </Grid>
      <Grid item xl={8} lg={8} md={12} sm={12} xs={12}>
        <Items />
      </Grid>
    </Grid>
  );
};

export default ShippingDetail;
