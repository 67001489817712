import './style.css';
import React from 'react';
import { Grid } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faInstagram,
  faFacebookF,
  faLinkedinIn,
} from '@fortawesome/free-brands-svg-icons';
import Logo from '../../images/logo.png';
import { NavLink } from 'react-router-dom';
import FrequentAQs from '../../pages/contactpage/components/faqssection';

const Footer = () => {
  // const navigator=()=>{
  //   window.location.href="http://www.facebook.com"
  // }
  return (
    <Grid container>
      <Grid item lg={12} md={12} xs={12} sm={12}>
        <footer>
          <div className="footer-container">
            <div className="footer-nav-container">
              <div className="footer-logo-text">
                <div className="footer-logo">
                  <img src={Logo} alt="Idemitsu" />
                </div>
                {/* <div className="footer-text">
                  <p>The Trusted Choice of Leading Automakers Worldwide</p>
                </div> */}
              </div>
              <div className="footer-company-social-contacts">
                <div className="footer-company-details">
                  <div className="footer-cd-heading">
                    <h3>Company</h3>
                  </div>
                  <ul className="footer-cd-ul">
                    <li>
                      <NavLink to={'/About'}>About Us</NavLink>
                    </li>
                    <li>
                      <NavLink to={'/Products'}>Products</NavLink>
                    </li>
                    <li>
                      <NavLink to={'/Where to buy'}>Where to Buy</NavLink>
                    </li>
                    <li>
                      <NavLink to={'/Services'}>Services</NavLink>
                    </li>
                  </ul>
                </div>
                <div className="footer-company-contact">
                  <div className="footer-cc-heading">
                    <h3>Contact</h3>
                  </div>
                  <ul className="footer-cc-ul">
                    <li>
                      <NavLink to={'/Contact'}>Contact Us</NavLink>
                    </li>
                    <li>
                      <NavLink to={'/Careers'}>Careers</NavLink>
                    </li>
                    <li>
                      <NavLink to={'/Privacy'}>Privacy Policy</NavLink>
                    </li>
                    <li>
                      <NavLink to={FrequentAQs}>Help/FAQ</NavLink>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="footer-social-contacts">
                <ul className="footer-sc-ul">
                  <li>
                    <a
                      href="https://www.facebook.com/IdemitsuLubePakistan/"
                      target="blank"
                    >
                      <FontAwesomeIcon icon={faFacebookF}></FontAwesomeIcon>
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://pk.linkedin.com/company/idemitsu-lube-pakistan-private-limited"
                      target="blank"
                    >
                      <FontAwesomeIcon icon={faLinkedinIn}></FontAwesomeIcon>
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.instagram.com/idemitsu_lube_pakistan/"
                      target="blank"
                    >
                      <FontAwesomeIcon icon={faInstagram}></FontAwesomeIcon>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="footer-copy-right-text">
              <p>
                Copyright &copy; Idemitsu Lube Pakistan (Private) Limited. All
                Rights Reserved.
              </p>
            </div>
          </div>
        </footer>
      </Grid>
    </Grid>
  );
};

export default Footer;
