import { Grid } from '@mui/material';
import './style.css';
import React from 'react';
// import ContactUsCard from "../../../../components/contactcard";
import BannerSlider from '../../../../components/bannerslider';
// import BannerImgOne from "../../../../images/banner-imgs/contact-banner-imgs/contact-us-banner-img-01.jpg";
import BannerImgTwo from '../../../../images/banner-imgs/contact-banner-imgs/contact-us-banner-img-02.jpg';
const ContactUsBanner = () => {
  const sliderData = [
    // {
    //   id: 1,
    //   imageSrc: BannerImgOne,
    //   alt: "contact-us-banner-img",
    //   isShowTextContent: true,
    //   bannerResp: "contact-us-banner-resp",
    //   title:"Contact Us",
    //   titleClassName:"contact-us-banner-title",
    //   isShowContactCards:true,
    // },
    {
      id: 1,
      imageSrc: BannerImgTwo,
      alt: 'contact-us-banner-img',
      bannerResp: 'contact-us-banner-resp',
      isShowTextContent: true,
      title: 'Contact Us',
      titleClassName: 'contact-us-banner-title',
      isShowContactCards: true,
    },
  ];
  // const contactData = {
  //   fontAwesomeIcon : "fa-sharp fa-solid fa-phone",
  //   title : "Contact Us On",
  //   text : "911-987654321",
  // }
  return (
    <Grid container>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <div className="contact-us-banner-section">
          <BannerSlider sliderData={sliderData} />
        </div>
      </Grid>
    </Grid>
  );
};

export default ContactUsBanner;
