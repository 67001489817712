import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination, Navigation, EffectCreative } from 'swiper';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import 'swiper/css/effect-creative';
import './style.css';
import ContactUsCard from '../contactcard';
const BannerSlider = (props) => {
  const { sliderData } = props;
  const contactDataPhone = {
    fontAwesomeIcon: 'fa-sharp fa-solid fa-phone',
    title: 'Contact Us On:',
    text: '92 21 111 007 111',
  };
  // const contactDataEmail = {
  //   fontAwesomeIcon: 'fa-sharp fa-solid fa-phone',
  //   title: 'Email Us:',
  //   text: 'Info.idemitsupk@idemitsu.com',
  // };
  const contactDataAddress = {
    fontAwesomeIcon: 'fa-sharp fa-solid fa-address-card',
    title: 'Main Office:',
    text: 'Emerald Tower, 11th Floor, Office #1103,Block 5 Clifton, Karachi, Karachi City, Sindh 74000',
  };
  return (
    <>
      <div className="banner-slider">
        <Swiper
          spaceBetween={0}
          slidesPerView={1}
          centeredSlides={true}
          speed={1000}
          autoplay={{
            delay: 4000,
            disableOnInteraction: false,
            pauseOnMouseEnter: true,
          }}
          // grabCursor={true}
          effect={'creative'}
          creativeEffect={{
            prev: {
              shadow: true,
              translate: [0, 0, -400],
            },
            next: {
              translate: ['100%', 0, 0],
            },
          }}
          pagination={{
            clickable: true,
          }}
          loop={true}
          modules={[Autoplay, Pagination, Navigation, EffectCreative]}
          className="banner-swiper-slider"
        >
          {sliderData.map((slide, index) => {
            const {
              id,
              imageSrc,
              atl,
              bannerResp,
              isShowTextContent = false,
              title,
              titleClassName,
              subtitle,
              subtitleClassName,
              para,
              paraClassName,
              isShowContactCards = false,
            } = slide;
            return (
              <SwiperSlide
                className="swiper-slide"
                key={id}
                virtualIndex={index}
              >
                {index === 0 && isShowTextContent === true ? (
                  <div className={`banner-slide-content ${bannerResp}`}>
                    <div className="banner-img-container">
                      <img src={imageSrc} alt="" />
                    </div>
                    <div className="banner-text-container">
                      <h5 className={subtitleClassName}>{subtitle}</h5>
                      <h2 className={titleClassName}>{title}</h2>
                      <p className={paraClassName}>{para}</p>
                      {isShowContactCards === true && (
                        <div className="contact-cards-container">
                          <ContactUsCard contactData={contactDataPhone} />
                          <ContactUsCard contactData={contactDataAddress} />
                        </div>
                      )}
                    </div>
                  </div>
                ) : (
                  <div className={`banner-slide-content ${bannerResp}`}>
                    <div className="banner-img-container">
                      <img src={imageSrc} alt={atl} />
                    </div>
                  </div>
                )}
              </SwiperSlide>
            );
          })}
        </Swiper>
      </div>
    </>
  );
};

export default BannerSlider;
