import React from 'react';
import ImgOne from '../../../images/product-imgs/IFG3/PK_IFG3 5W-30_SP-GF-6A_4L_FS_4L_F.png';
import Layout from '../../../Layout/Layout';
import { Grid } from '@mui/material';
import ProductDetails from '../../productdetails';

const IFG32 = () => {
  return (
    <Layout>
      <Grid container>
        <Grid item xl={12} lg={12} md={12} xs={12}>
          <ProductDetails
            title="IFG3 5W-30 SP/GF-6A Fully Synthetic 4L"
            price="Rs  7,924"
            img={ImgOne}
            product_desc="Idemitsu Four-Wheelers' Gasoline Engine Oil - IFG3
            High Performance engine oil, formulated with Nano Tailored Technology.
            Offers extreme performance over the entire temperature range and improves oxidation stability, allowing excellent oil life.Benefits in highly stressed environments."
            adv_tit="Distinctive Characteristics for Unmatched Advantages:"
            adv_1="High anti-wear protection"
            adv_2="Detergency at high temperature"
          />
        </Grid>
      </Grid>
    </Layout>
  );
};

export default IFG32;
