// import config from "src/services/config";
// import fetchAPIData from "./fetchUtil";

import config from '../config';
import fetchUtil from '../fetchUtil';

// import config from '../config';
// import fetchUtil from '../fetchUtils';

/**
 * Fetches users tokens
 *
 */
const userRegister = async (user) => {
  const body = JSON.stringify(user);
  const url = `${config.baseUrl}/register`;
  const params = {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: '',
    },
    body,
  };
  let userCred = null;
  try {
    const response = await fetchUtil(url, params);
    // console.log(response);
    userCred = response;
    console.log(userCred);
  } catch (error) {
    throw new Error(error);
  }
  return userCred;
};
export default userRegister;
