import './style.css';
import React from 'react';
import { Grid } from '@mui/material';
// import Heading from '../../../../components/headings/Heading';
import CompanyDetails from './sections/companysection';
import Map from '../companymap/Map';
import OverviewCard from '../overviewcards/OverviewCard';
// import HeadOfficeDetails from './sections/headofficesection';

const CompanyDetail = () => {
  return (
    <>
      <Grid container>
        <Grid item xl={12} lg={12} md={12} xs={12} sm={12}>
          <CompanyDetails />
        </Grid>
        <Grid item xl={12} lg={12} md={12} xs={12} sm={12}>
          <Map />
        </Grid>
        <Grid item xl={12} lg={12} md={12} xs={12} sm={12}>
          <OverviewCard />
        </Grid>
      </Grid>
    </>
  );
};

export default CompanyDetail;
