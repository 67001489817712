import React from 'react';
import './style.css';
import { Link } from 'react-router-dom';
import MainCard from '../../../../components/productcard';
// import Loader from '../../../../components/loader';
import defimg from '../../../../images/product-imgs/no-img.png';

const ProductResultCard = ({ vehicleOil }) => {
  return (
    <>
      <div className="main-products-card-section">
        {vehicleOil.map((item, index) => {
          const defaultImage = defimg;
          const imageUrl = item.image[0]
            ? item.image[0].imageURL
            : defaultImage;
          const imag = encodeURIComponent(imageUrl);

          if (item.title === null) {
            return (
              <div style={{ marginTop: '40px' }}>
                <h1 className="items-page-para">
                  Thank you for your search. We do not have any products that
                  match this vehicle at this time. Idemitsu is always developing
                  new products to meet evolving customer needs. Please check
                  back again in the future and always see your owner’s manual to
                  ensure that you are using the proper fluids for your vehicle.
                </h1>
              </div>
            );
          } else {
            return (
              <div>
                <div>
                  <div key={item.oilId}>
                    <Link
                      to={`/ProductDetails?oilId=${item.oilId}&title=${item.title}&description=${item.description}&image=${imag}&price=${item.price}`}
                      className="product-details-link"
                    >
                      <MainCard
                        title={item.title}
                        src={imageUrl}
                        description={item.description}
                        current_price={item.price}
                        //   prev_price={prev_price}
                      />
                    </Link>
                  </div>
                </div>
                {/* <div className="services-product-search-container" key={index}>
                      <div className="services-search-card">
                        <div className="services-search-card-img">
                          <img style={{ width: '6em' }} src={item.image} alt="" />
                        </div>
                        <div className="services-search-card-text">
                          <h3>
                            <span>{item.oil_category}</span>
                          </h3>
                          <h3>{item.product_title}</h3>
                          <p>{item.product_des}</p>
                          <h4>
                            <i className="fa fa-tint" aria-hidden="true"></i>
                            {item.fluid_capacity}
                          </h4>
                        </div>
                      </div>
                    </div> */}
              </div>
            );
          }
        })}
      </div>
    </>
  );
};

export default ProductResultCard;
