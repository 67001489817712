import * as React from 'react';
import './style.css';
import Button from '@mui/material/Button';
import { useState } from 'react';
import { Grid } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import userRegister from '../../../services/authApis/userRegister';
import bgImage from '../../../images/auth-imgs/industrial-lubricants.jpg';
import Swal from 'sweetalert2';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

export default function Register() {
  const ref = React.useRef();
  const navigate = useNavigate();
  const [firstName, setFirstName] = useState('');
  const [lastName, setlastName] = useState('');
  const [phone, setPhone] = useState('');
  const [dob, setDob] = useState('');
  const [userName, setUserName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [changePassword, setChangePassword] = useState(true);
  const changeIcon = changePassword === true ? false : true;

  const handleSubmit = async (event) => {
    event.preventDefault();
    const items = {
      firstName,
      lastName,
      phone,
      dob,
      email,
      userName,
      password,
    };
    // console.log(items);

    try {
      const resp = await userRegister(items);
      if (resp.status === 200) {
        if (resp.body.message === 'User already Exist') {
          Swal.fire({
            icon: 'warning',
            title: resp.body.message,
            showConfirmButton: false,
            timer: 1500,
          });
        } else {
          Swal.fire({
            icon: 'success',
            title: resp.body.message,
            showConfirmButton: false,
            timer: 1500,
          });
          navigate('/auth/verifyCode');
        }
      }
    } catch (error) {
      // console.log(error);
      throw new Error(error);
    }
  };

  return (
    <Grid container bgcolor={'#F2F2F2'}>
      <Grid item lg={6} md={6} sm={12} xs={12}>
        <div className="register-bg">
          <img src={bgImage} alt={bgImage} />
        </div>
      </Grid>
      <Grid item mt={6} mb={6} lg={6} md={6} sm={12} xs={12}>
        <div className="register-box">
          <div>
            <h1 className="register-heading">Register Youself</h1>
          </div>
          <div>
            <>
              <form action="" onSubmit={handleSubmit}>
                <div className="register-input-div">
                  <input
                    className="register-input"
                    autoComplete="off"
                    type="text"
                    name="firstName"
                    id="firstName"
                    placeholder="first name"
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                  />
                </div>
                <div className="register-input-div">
                  <input
                    className="register-input"
                    autoComplete="off"
                    type="text"
                    name="lastName"
                    id="lastName"
                    placeholder="last name"
                    value={lastName}
                    onChange={(e) => setlastName(e.target.value)}
                  />
                </div>
                <div className="register-input-div">
                  <input
                    className="register-input"
                    autoComplete="off"
                    type="text"
                    name="phone"
                    id="phone"
                    placeholder="phone"
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                  />
                </div>
                <div className="register-input-div">
                  {/* <div>
                    <label>Date of birth</label>
                  </div> */}
                  <div className="register-input-date">
                    <input
                      className="register-input"
                      autoComplete="off"
                      // type="date"
                      name="dob"
                      id="dob"
                      ref={ref}
                      placeholder="Date of birth: mm/dd/yyyy"
                      value={dob}
                      onChange={(e) => setDob(e.target.value)}
                      onFocus={() => (ref.current.type = 'date')}
                      onBlur={() => (ref.current.type = 'date')}
                    />
                  </div>
                </div>
                <div className="register-input-div">
                  <input
                    className="register-input"
                    autoComplete="off"
                    type="email"
                    name="email"
                    id="email"
                    placeholder="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
                <div className="register-input-div">
                  <input
                    className="register-input"
                    autoComplete="off"
                    type="text"
                    name="username"
                    id="username"
                    placeholder="username"
                    value={userName}
                    onChange={(e) => setUserName(e.target.value)}
                  />
                </div>
                <div className="register-input-div">
                  <input
                    className="register-input"
                    autoComplete="off"
                    type={changePassword ? 'password' : 'text'}
                    name="password"
                    id="password"
                    placeholder="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                  <span
                    className="icon"
                    onClick={() => {
                      setChangePassword(changeIcon);
                    }}
                  >
                    {changeIcon ? <RemoveRedEyeIcon /> : <VisibilityOffIcon />}
                  </span>
                </div>
                <div className="register-input-div-button">
                  <Button
                    type="submit"
                    className="button"
                    variant="contained"
                    sx={{
                      backgroundColor: '#E11737',
                      padding: '10px 35px',
                      width: '180px',
                      height: '50px',
                      borderRadius: '100px',
                      color: '#ffffff',
                      fontSize: '18px',
                      textTransform: 'capitalize',
                      '&.MuiButton-root:hover': {
                        backgroundColor: '#E11737',
                      },
                    }}
                  >
                    Send code
                  </Button>
                </div>
              </form>
            </>
          </div>
          <div className="register-input-div">
            <h3 className="span-signup-text">
              Already have an account?{' '}
              <Link to="/auth/login">
                <span className="span-signup"> Sign in</span>
              </Link>
            </h3>
          </div>
        </div>
      </Grid>
    </Grid>
  );
}
