import './style.css';
import React, { useContext } from 'react';
import Layout from '../../../Layout/Layout';
import { Grid } from '@mui/material';
import OrderHeading from './orderheading';
import OrderItems from './orderitems';
import { CartContext } from '../../../context/cartContext';
import EmptyCart from '../../../images/product-imgs/shopping.png';
import { NavLink } from 'react-router-dom';

const OrderSummary = () => {
  const { cart } = useContext(CartContext);
  return (
    <Layout>
      <Grid container p={4} className="order-details-page-bg">
        <Grid item xl={12} lg={12} md={12} xs={12}>
          {cart.length === 0 ? (
            <div className="empty-cart">
              <NavLink to={'/products'}>
                <img className="cartimg" src={EmptyCart} alt={'Empty Cart'} />
              </NavLink>
              <p className="empty-cart-p">You have not added any thing yet</p>
            </div>
          ) : (
            <>
              <OrderHeading heading="Order Summary" />
              <OrderItems />
            </>
          )}
        </Grid>
      </Grid>
    </Layout>
  );
};

export default OrderSummary;
