import './style.css';
import React from 'react';
import { Grid, Typography } from '@mui/material';
import {
  ThemeColors,
  ThemeFontsFamily,
  ThemeFontWeight,
  FontSize,
} from '../../../../utils/Theme';
import Lines from '../../../../images/lines3.png';
import Automotive from '../../../../images/banner-imgs/services-banner-imgs/automotive.png';
import Industrial from '../../../../images/banner-imgs/services-banner-imgs/daphne.png';
import { NavLink } from 'react-router-dom';
// import SpecialtyProducts from '../../../../images/banner-imgs/services-banner-imgs/specialty-products.png';

const ServicesHero = () => {
  return (
    <div className="services-back" style={{ backgroundImage: `url(${Lines})` }}>
      <Grid container>
        <Grid item xl={12} lg={12} md={12} xs={12} sm={12}>
          <div className="services-banner">
            <div className="services-banner-content">
              <div className="services-banner-heading-group">
                <Typography
                  color={ThemeColors.MainHeadings}
                  sx={{
                    fontFamily: ThemeFontsFamily.Volkhov,
                    fontWeight: ThemeFontWeight.Bold,
                    fontSize: FontSize.Headings,
                    letterSpacing: '1.5px',
                  }}
                  variant="h2"
                >
                  Driving Performance,
                </Typography>

                <Typography
                  color={ThemeColors.BlackHeading}
                  sx={{
                    fontFamily: ThemeFontsFamily.Volkhov,
                    fontWeight: ThemeFontWeight.Bold,
                    fontSize: FontSize.Headings,
                    letterSpacing: '1.5px',
                  }}
                  variant="h2"
                >
                  Advancing Technology!
                </Typography>
                {/* <Typography
                  color={ThemeColors.MainHeadings}
                  sx={{
                    fontFamily: ThemeFontsFamily.Volkhov,
                    fontWeight: ThemeFontWeight.Bold,
                    fontSize: FontSize.Headings,
                    letterSpacing: "1.5px",
                  }}
                  variant="h1"
                >
                  sed do eiusmod
                </Typography> */}
              </div>
              <div className="services-banner-para-text">
                <Typography
                  color={ThemeColors.LightGrey}
                  sx={{
                    fontFamily: ThemeFontsFamily.Poppins,
                    fontWeight: ThemeFontWeight.Medium,
                    fontSize: FontSize.xlarge,
                    letterSpacing: '1.5px',
                  }}
                  variant="p"
                >
                  Elevating your driving experience with cutting-edge solutions.
                </Typography>
              </div>
              <div className="services-vehicle-img-group">
                <NavLink to={'/Products'}>
                  <div>
                    <div className="services-vehicle-card-content">
                      <div className="services-vehicle-card">
                        <div className="services-vehicle-card-img">
                          <img src={Automotive} alt={Automotive} />
                        </div>
                        <div className="services-vehicle-card-text">
                          <h2>Automotive</h2>
                          {/* <p>{desc}</p> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </NavLink>
                <NavLink to={'/Industrial'}>
                  <div>
                    <div className="services-vehicle-card-content">
                      <div className="services-vehicle-card">
                        <div className="services-vehicle-card-img">
                          <img src={Industrial} alt={Industrial} />
                        </div>
                        <div className="services-vehicle-card-text">
                          <h2>Industrial</h2>
                          {/* <p>{desc}</p> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </NavLink>
              </div>
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default ServicesHero;
