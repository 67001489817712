import React, { useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, A11y, FreeMode } from 'swiper';
import 'swiper/css/navigation';
import 'swiper/css';
import './style.css';
import TesimonialsData from './testimonials.json';
import { useTheme, useMediaQuery } from '@mui/material';

const TestimonialSlider = () => {
  const [prevBtn, setPrevBtn] = useState(null);
  const buttonPressed = (e) => {
    const button = e.target.closest('.slider-btn-container div');
    if (button) {
      button.classList.add('ts_btn-active');
      if (prevBtn !== null && prevBtn !== button) {
        prevBtn.classList.remove('ts_btn-active');
      }
      setPrevBtn(button);
    }
  };
  const slides = TesimonialsData;
  const theme = useTheme();
  const isMatch = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <>
      <div className="testimonials-section">
        <div className="ts_headings">
          <div className="testimonials-heading-text">
            <h3>testimonials</h3>
            <h2>What people say about Us.</h2>
          </div>
        </div>
        <div className="testimonials-slider-container">
          <Swiper
            id="testimonials"
            className="swiper-container"
            modules={[Navigation, A11y, FreeMode]}
            freeMode={false}
            spaceBetween={1}
            slidesPerView={2}
            loop={true}
            navigation={{
              nextEl: '.btn-next',
              prevEl: '.btn-prev',
            }}
            direction={'vertical'}
            centeredSlides={true}
            onSwiper={(swiper) => {}}
            onSlideChange={() => {}}
            allowTouchMove={false}
          >
            <div className="swiper-wrapper">
              {slides.map((item, index) => {
                const { id, comment, author_name, position, img } = item;
                return (
                  <SwiperSlide
                    className="swiper-slide"
                    key={id}
                    virtualIndex={index}
                  >
                    <div className="testimonials-card-img">
                      <img src={img} alt="avatar" />
                    </div>
                    <div className="testimonials-card">
                      <div className="testimonials-card-body">
                        <blockquote className="testimonials-card-text">
                          "{comment}"
                        </blockquote>
                        <div className="testimonials-card-name">
                          <h4>{author_name}</h4>
                          <p>{position}</p>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                );
              })}
            </div>
          </Swiper>

          {isMatch ? (
            <div className="slider-btn-container" onClick={buttonPressed}>
              <div className="btn-prev" id="ts-prev">
                <i className="fa-solid fa-chevron-left"></i>
              </div>
              <div className="btn-next" id="ts-next">
                <i className="fa-solid fa-chevron-right"></i>
              </div>
            </div>
          ) : (
            <div className="slider-btn-container" onClick={buttonPressed}>
              <div className="btn-prev" id="ts-prev">
                <i className="fa-solid fa-chevron-up"></i>
              </div>
              <div className="btn-next" id="ts-next">
                <i className="fa-solid fa-chevron-down"></i>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default TestimonialSlider;
