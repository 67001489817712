import React from "react";
import { Grid, Typography } from "@mui/material";
import "./style.css";
import {
  ThemeColors,
  ThemeFontWeight,
  ThemeFontsFamily,
  FontSize,
} from "../../../../../utils/Theme";
// import ProductImg from "../../../../../images/product-img-04.jpg";
// import ContactCard from "../../contactcard/ContactCard";
const SalesInformation = () => {
  return (
    <Grid container>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <div className="sale-info-card">
          <Typography
            variant="h2"
            component="div"
            className="red-border-img"
            sx={{
              fontFamily: ThemeFontsFamily.Volkhov,
              fontSize: FontSize.Title,
              fontWeight: ThemeFontWeight.Bold,
              color: ThemeColors.BlackHeading,
              position: "relative",
            }}
          >
            SALES SUPPORT
          </Typography>
          <Typography
            component="div"
            sx={{
              margin: "50px 0",
            }}
            className="sales-support-text"
          >
            <p>
              Our sales team is equipped with in-depth knowledge about our
              products/services, and they are more than happy to:
            </p>
            <ul>
              <li>
                Provide detailed information about our offerings and their
                features.
              </li>
              <li>
                Offer personalized recommendations tailored to your specific
                needs and preferences.
              </li>
              <li>
                Assist with pricing, discounts, and any ongoing promotions.
              </li>
              <li>
                Guide you through the purchasing process to ensure a seamless
                experience.
              </li>
            </ul>
          </Typography>
          {/* <div className="products-imgs-group">
            <div className="product-img">
              <img src={ProductImg} alt="" />
            </div>
            <div className="product-img">
              <img src={ProductImg} alt="" />
            </div>
            <div className="product-img">
              <img src={ProductImg} alt="" />
            </div>
          </div> */}
          {/* <div className="contactus-info-group-bg">
            <ContactCard />
            <ContactCard
              fontawesomeicon="fa-solid fa-envelope"
              title="Email Us"
              text="info@idemistu.pk"
            />
          </div> */}
        </div>
      </Grid>
    </Grid>
  );
};

export default SalesInformation;
