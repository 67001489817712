import './style.css';
import React, { useContext, useEffect, useState } from 'react';
import {
  Grid,
  // IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  // tableCellClasses,
  TableHead,
  TableRow,
  Toolbar,
  Typography,
} from '@mui/material';
// import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import defimg from '../../../../images/product-imgs/no-img.png';
import getOrders from '../../../../services/order/getOrders';
import UserContext from '../../../../context/UserContext';
import getOils from '../../../../services/oils/getOils';
import EmptyCart from '../../../../images/product-imgs/shopping.png';
import { NavLink } from 'react-router-dom';
import Loader from '../../../../components/loader';

const OrderView = () => {
  const { user, token } = useContext(UserContext);
  const [order, setOrder] = useState([]);
  const [loading, setLoading] = useState(true);
  const [allOrders, setAllOrders] = useState([]);

  const getOrder = async (user, token) => {
    try {
      // Getting all orders
      const orders = await getOrders(user.personId, token);
      console.log(orders.message);
      setOrder(orders);

      // Process all orders and their items
      const allOrderDetails = await Promise.all(
        orders.map(async (ele) => {
          // Destructure relevant fields from each order
          const { amountBilled, adrId, dateBilled } = ele;

          // Parse order items
          const parsedItems = JSON.parse(ele.items);

          // Fetch additional details for each item
          const result = await Promise.all(
            parsedItems.map(async (item) => {
              const res = await getOils(item.oilId, token);

              // Assume getOils returns an array with a single object
              const [
                {
                  benefits,
                  capacity,
                  imageData,
                  description,
                  label,
                  price,
                  title,
                },
              ] = res;

              // Construct a new order object for each item
              const order = {
                amountBilled,
                adrId,
                dateBilled,
                orderId: item.orderId,
                quantity: item.quantity,
                orderitemId: item.orderitemId,
                benefits,
                capacity,
                imageData,
                description,
                label,
                price,
                title,
              };

              return order;
            })
          );

          return result;
        })
      );
      setAllOrders(allOrderDetails);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  useEffect(() => {
    getOrder(user, token);
  }, [user, token]);

  return loading ? (
    <Loader />
  ) : (
    <Grid container>
      <Grid item mt={3} xl={12} lg={12} md={12} sm={12} xs={12}>
        {order.message === 'No results found.' ? (
          <div className="empty-cart">
            <NavLink to={'/products'}>
              <img className="cartimg" src={EmptyCart} alt={'Empty Cart'} />
            </NavLink>
            <p className="empty-cart-p">No Recent Order</p>
          </div>
        ) : (
          <div className="order-items">
            <Toolbar
              variant="dense"
              sx={{ backgroundColor: '#e11737', height: '34px' }}
            >
              <Typography
                sx={{ flex: '1 1 100%' }}
                variant="h6"
                id="tableTitle"
                component="div"
                color="white"
              >
                All Orders
              </Typography>
            </Toolbar>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 700 }} aria-label="customized table">
                <TableHead>
                  <TableRow>
                    <TableCell
                      sx={{
                        fontFamily: 'Poppins',
                        fontSize: '13px',
                        fontWeight: '700',
                        textAlign: 'center',
                      }}
                    >
                      Order#
                    </TableCell>
                    <TableCell
                      sx={{
                        fontFamily: 'Poppins',
                        fontSize: '13px',
                        fontWeight: '700',
                        textAlign: 'center',
                      }}
                    >
                      ITEM
                    </TableCell>
                    <TableCell
                      sx={{
                        fontFamily: 'Poppins',
                        fontSize: '13px',
                        fontWeight: '700',
                        textAlign: 'center',
                      }}
                    >
                      Placed on
                    </TableCell>
                    <TableCell
                      sx={{
                        fontFamily: 'Poppins',
                        fontSize: '13px',
                        fontWeight: '700',
                        textAlign: 'center',
                      }}
                    >
                      Total
                    </TableCell>
                    {/* <TableCell
                      sx={{
                        fontFamily: 'Poppins',
                        fontSize: '13px',
                        fontWeight: '700',
                        textAlign: 'center',
                      }}
                    >
                      ACTION
                    </TableCell> */}
                  </TableRow>
                </TableHead>
                {allOrders?.map((ele) => {
                  return (
                    <TableBody key={ele[0].orderId}>
                      <TableRow
                        key={ele[0].orderId}
                        sx={{
                          '&:last-child td, &:last-child th': { border: 0 },
                          borderBottom: '1px solid #e0e0e0',
                        }}
                      >
                        <TableCell
                          sx={{
                            textAlign: 'center',
                          }}
                        >
                          <div className="item-sr-no">
                            <p>{ele[0].orderId}</p>
                          </div>
                        </TableCell>
                        <TableCell
                          sx={
                            {
                              // textAlign: 'center',
                            }
                          }
                        >
                          {ele.map((item) => {
                            console.log(item);
                            const defaultImage = defimg;
                            const imageUrl = item.imageData[0]
                              ? item.imageData[0].imageURL
                              : defaultImage;
                            return (
                              <div className="items">
                                <div className="items-bottle">
                                  <img src={imageUrl} alt="bottle" />
                                </div>
                                <div className="items-bottle-text">
                                  <div>
                                    <h3>{item.title}</h3>
                                  </div>
                                  <div>
                                    <p>{item.description}</p>
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                        </TableCell>
                        <TableCell
                          sx={{
                            textAlign: 'center',
                          }}
                        >
                          <div className="item-price">
                            <h3>
                              {new Date(ele[0].dateBilled).toLocaleDateString()}
                            </h3>
                          </div>
                        </TableCell>
                        <TableCell
                          sx={{
                            textAlign: 'center',
                          }}
                        >
                          <div className="item-price">
                            <h3>{ele[0].amountBilled}</h3>
                          </div>
                        </TableCell>
                        {/* <TableCell
                            sx={{
                              display: 'flex',
                              justifyContent: 'center',
                            }}
                          >
                            <div className="item-del-icon">
                              <IconButton>
                                <DragIndicatorIcon />
                              </IconButton>
                            </div>
                          </TableCell> */}
                      </TableRow>
                    </TableBody>
                  );
                })}
              </Table>
            </TableContainer>
          </div>
        )}
      </Grid>
    </Grid>
  );
};

export default OrderView;
