/* eslint-disable no-unused-vars */
import './style.css';
import React, { useEffect, useState } from 'react';
import { Card, CardContent, Grid } from '@mui/material';
import SelectField from '../../../../components/reactselect';
import RecaptchaComponent from './../../../../components/reCaptcha/index';
// import Buttons from '../../../../components/button/Buttons';
// import axios from 'axios';
// import ServicesResultCard from '../../../servicespage/sections/vehiclefilter/resultcard';
import ProductResultCard from '../productresult';
// import getAllproducts from '../../../../services/getAllproducts';
import getAllData from '../../../../services/getAllData';
import Loader from '../../../../components/loader';
// import getAllproducts from '../../../../services/getAllproducts';

const FilterProduct = () => {
  const [loading, setLoading] = useState(true);
  const [products, setproducts] = useState([]);
  const [type, setType] = useState('');
  const [optionsList, setOptionsList] = useState([]);
  const [makeOptions, setMakeOptions] = useState([]);
  const [make, setMake] = useState(null);
  const [modelOption, setModelOptions] = useState([]);
  const [model, setModel] = useState('');
  const [engineOptions, setEngineOptions] = useState([]);
  const [engine, setEngine] = useState('');
  const [fromYearOptions, setFromYearOptions] = useState([]);
  const [fromYear, setFromYear] = useState('');
  const [toYearOptions, setToYearOptions] = useState([]);
  const [toYear, setToYear] = useState('');
  const [oilData, setOilData] = useState([]);
  const key = process.env.REACT_APP_KEY;
  // console.log('str', products);

  const getProducts = async (recaptchaToken) => {
    try {
      const allProducts = await getAllData(recaptchaToken);
      // console.log('reCAPTCHA Token:', recaptchaToken);
      setproducts(allProducts);
      setLoading(false);
      // console.log(allProducts);
    } catch (error) {
      // console.log(error);
    }
  };

  // const [isVerified, setIsVerified] = useState(false);
  // const [recaptchaToken, setRecaptchaToken] = useState(null);
  // const recaptchaRef = useRef(null);
  useEffect(() => {
    // Remove duplicates vehicle type
    const vehicleTypes = [...new Set(products.map((item) => item.type))];
    // console.log(vehicleTypes);
    setOptionsList(vehicleTypes);
  }, [products]);

  const typeOpt = optionsList.map((item) => ({ label: item, value: item }));
  // get vehicle make
  const getVehicleMakes = (type, products) => {
    const filteredMake = products.filter((item) => item.type === type);
    const vehicleMake = [...new Set(filteredMake.map((item) => item.make))];
    setMakeOptions(vehicleMake);
    setModelOptions([]);
    setModel('');
    setEngine('');
    setToYear('');
    setFromYear('');
    setEngineOptions([]);
    setFromYearOptions([]);
    setToYearOptions([]);
    // console.log(vehicleMake);
  };

  useEffect(() => {
    getVehicleMakes(type, products);
  }, [type, products]);

  const makeOpt = makeOptions.map((item) => ({ label: item, value: item }));
  // const handleMaker = (value, e) => {
  //   console.log(e.target.value);
  // };

  // get vehicle model
  const getVehicleModel = (products, make) => {
    const filteredModel = products.filter((item) => item.make === make);
    const vehicleModel = [...new Set(filteredModel.map((item) => item.model))];
    setModelOptions(vehicleModel);
    setEngine('');
    setToYear('');
    setFromYear('');
    setEngineOptions([]);
    setFromYearOptions([]);
    setToYearOptions([]);
    // console.log(vehicleModel);
  };

  useEffect(() => {
    getVehicleModel(products, make);
  }, [products, make]);
  const modelOpt = modelOption.map((item) => ({ label: item, value: item }));

  // get vehicle engine
  const getVehicleEngine = (products, model) => {
    const filteredEngine = products.filter((item) => item.model === model);
    // console.log(filteredEngine);
    const vehicleEngine = [
      ...new Set(filteredEngine.map((item) => item.engine)),
    ];
    setEngineOptions(vehicleEngine);
    setToYear('');
    setFromYear('');
    setFromYearOptions([]);
    setToYearOptions([]);
    // console.log(vehicleEngine);
  };

  useEffect(() => {
    getVehicleEngine(products, model);
  }, [products, model]);
  const engineOpt = engineOptions.map((item) => ({ label: item, value: item }));

  // get vehicle from year
  const getVehicleFromYear = (products, engine) => {
    const filteredFromYear = products.filter((item) => item.engine === engine);
    const vehicleFromYear = [
      ...new Set(filteredFromYear.map((item) => item.fromYear)),
    ];
    setFromYearOptions(vehicleFromYear);
    setToYear('');
    setToYearOptions([]);
    // console.log(vehicleFromYear);
  };

  useEffect(() => {
    getVehicleFromYear(products, engine);
  }, [products, engine]);
  const fromYearOpt = fromYearOptions.map((item) => ({
    label: item,
    value: item,
  }));

  // get vehicle to year
  const getVehicleToYear = (products, fromYear, type, make, model, engine) => {
    const filteredToYear = products.filter(
      (item) =>
        item.type === type &&
        item.make === make &&
        item.model === model &&
        item.engine === engine &&
        item.fromYear === fromYear
    );
    console.log(filteredToYear);
    const vehicleToYear = [
      ...new Set(filteredToYear.map((item) => item.toYear)),
    ];
    console.log(vehicleToYear);
    setToYearOptions(vehicleToYear);
    // console.log(vehicleToYear);
  };

  useEffect(() => {
    getVehicleToYear(products, fromYear, type, make, model, engine);
  }, [products, fromYear, type, make, model, engine]);
  const toYearOpt = toYearOptions.map((item) => ({ label: item, value: item }));

  // get vehicle to year
  const getVehicleOils = (
    products,
    type,
    make,
    model,
    engine,
    fromYear,
    toYear
  ) => {
    const filteredOils = products.filter(
      (item) =>
        item.type === type &&
        item.make === make &&
        item.model === model &&
        item.engine === engine &&
        item.fromYear === fromYear &&
        item.toYear === toYear
    );
    console.log(filteredOils);
    setOilData(filteredOils);
  };

  useEffect(() => {
    getVehicleOils(products, type, make, model, engine, fromYear, toYear);
  }, [products, type, make, model, engine, fromYear, toYear]);

  return loading ? (
    <>
      <Loader />
      <RecaptchaComponent
        // ref={recaptchaRef}
        siteKey={key}
        action="LOGIN"
        onToken={getProducts}
      />
    </>
  ) : (
    <Grid container>
      <Grid item lg={4} md={5} xs={12} sm={12}>
        <div className="product_filter">
          <Card
            sx={{
              width: 340,
              overflow: 'unset',
            }}
          >
            <CardContent>
              <div className="search_main">
                <div className="product_search">
                  <div className="searching_section">
                    <div className="filter_search_field">
                      <div>
                        <select
                          className="select-input-opt"
                          onChange={(e) => {
                            setType(e.target.value);
                          }}
                          value={type}
                        >
                          <option selected hidden value="">
                            Vehicle Type
                          </option>
                          {optionsList.map((options, index) => {
                            return (
                              <option
                                className="select-input-optn"
                                value={options}
                              >
                                {options}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                      {/* <SelectField
                        placeholder={'Vehicle type'}
                        handleChange={(opt) => setType(opt.value)}
                        options={typeOpt}
                        bgColor={'#E7E7E7'}
                        value={make}
                      /> */}
                    </div>
                    <div className="filter_search_field">
                      <div>
                        <select
                          disabled={makeOptions.length === 0 ? true : false}
                          className="select-input-opt"
                          onChange={(e) => {
                            setMake(e.target.value);
                          }}
                          value={make}
                        >
                          <option selected hidden value="">
                            Vehicle Make
                          </option>
                          {makeOptions.map((options, index) => {
                            return (
                              <option
                                className="select-input-optn"
                                value={options}
                              >
                                {options}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                      {/* <SelectField
                        placeholder={'Vehicle Make'}
                        handleChange={(opt) => setMake(opt.value)}
                        options={makeOpt}
                        isDisabled={makeOptions.length === 0 ? true : false}
                        bgColor={'#E7E7E7'}
                        value={make}
                      /> */}
                    </div>
                    <div className="filter_search_field">
                      <div>
                        <select
                          disabled={modelOption.length === 0 ? true : false}
                          className="select-input-opt"
                          onChange={(e) => {
                            setModel(e.target.value);
                          }}
                          value={model}
                        >
                          <option selected hidden value="">
                            Vehicle Model
                          </option>
                          {modelOption.map((options, index) => {
                            return (
                              <option
                                className="select-input-optn"
                                value={options}
                              >
                                {options}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                      {/* <SelectField
                        placeholder={'Vehicle Model'}
                        handleChange={(opt) => {
                          setModel(opt.value);
                        }}
                        options={modelOpt}
                        isDisabled={modelOption.length === 0 ? true : false}
                        bgColor={'#E7E7E7'}
                        value={vehicleModel}
                      /> */}
                    </div>
                    <div className="filter_search_field">
                      <div>
                        <select
                          disabled={engineOptions.length === 0 ? true : false}
                          className="select-input-opt"
                          onChange={(e) => {
                            setEngine(e.target.value);
                          }}
                          value={engine}
                        >
                          <option selected hidden value="">
                            Vehicle Engine
                          </option>
                          {engineOptions.map((options, index) => {
                            return (
                              <option
                                className="select-input-optn"
                                value={options}
                              >
                                {options}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                      {/* <SelectField
                        placeholder={'Vehicle Modification'}
                        handleChange={(opt) => {
                          setEngine(opt.value);
                        }}
                        options={engineOpt}
                        isDisabled={engineOptions.length === 0 ? true : false}
                        bgColor={'#E7E7E7'}
                        value={vehicleModif}
                      /> */}
                    </div>
                    <div className="filter_search_field">
                      <div>
                        <select
                          disabled={fromYearOptions.length === 0 ? true : false}
                          className="select-input-opt"
                          onChange={(e) => {
                            setFromYear(e.target.value);
                          }}
                          value={fromYear}
                        >
                          <option selected hidden value="">
                            Vehicle From year
                          </option>
                          {fromYearOptions.map((options, index) => {
                            return (
                              <option
                                className="select-input-optn"
                                value={options}
                              >
                                {options}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                      {/* <SelectField
                        placeholder={'from year'}
                        handleChange={(opt) => {
                          setFromYear(opt.value);
                        }}
                        options={fromYearOpt}
                        isDisabled={fromYearOptions.length === 0 ? true : false}
                        bgColor={'#E7E7E7'}
                        value={vehicleYear}
                      /> */}
                    </div>
                    <div className="filter_search_field">
                      <div>
                        <select
                          disabled={toYearOptions.length === 0 ? true : false}
                          className="select-input-opt"
                          onChange={(e) => {
                            setToYear(e.target.value);
                          }}
                          value={toYear}
                        >
                          <option selected hidden value="">
                            Vehicle To year
                          </option>
                          {toYearOptions.map((options, index) => {
                            return (
                              <option
                                className="select-input-optn"
                                value={options}
                              >
                                {options}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                      {/* <SelectField
                        placeholder={'to year'}
                        handleChange={(opt) => {
                          setToYear(opt.value);
                        }}
                        options={toYearOpt}
                        isDisabled={toYearOptions.length === 0 ? true : false}
                        bgColor={'#E7E7E7'}
                        value={vehicleYear}
                      /> */}
                    </div>
                    {/* <div className="filter_search_btn">
                        <Buttons
                          buttonText={'Pick Up Oil'}
                          onClick={handleGetVehicleOil}
                        />
                      </div> */}
                  </div>
                </div>
              </div>
            </CardContent>
          </Card>
        </div>
      </Grid>
      {/* {products.map((item) => {
          return <p>{item.type}</p>;
        })} */}
      <Grid item lg={8} md={7} sm={12} xs={12}>
        <ProductResultCard
          vehicleOil={oilData}
          // vehicleModif={vehicleModif.label}
          // vehicleYear={vehicleYear.value}
        />
      </Grid>
    </Grid>
  );
};

export default FilterProduct;
