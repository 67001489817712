import '../style.css';
import React, { useState } from 'react';
import {
  ThemeColors,
  ThemeFontWeight,
  ThemeFontsFamily,
  FontSize,
} from '../../../../../utils/Theme';
import { Grid } from '@mui/material';

const Year = (props) => {
  const { title, desc, image, desc1 } = props;
  // const [showTopPara, setShowTopPara] = useState(true);
  const [showBottomPara, setShowBottomPara] = useState(false);

  const toggleTopPara = () => {
    // setShowTopPara(false);
    setShowBottomPara(true);
  };

  const toggleBottomPara = () => {
    // setShowTopPara(true);
    setShowBottomPara(false);
  };

  return (
    <>
      <Grid container>
        <Grid
          item
          display="flex"
          justifyContent="center"
          xl={12}
          lg={12}
          md={12}
          xs={12}
          sm={12}
        >
          <div className="company-history-details">
            <div className="company-year-container">
              <div
                className={`year-content ${
                  showBottomPara ? 'bottom-para' : 'top-para'
                }`}
              >
                <div className="company-history-tab-img">
                  <img className="year-img" src={image} alt="History" />
                </div>
                <div>
                  <div className="year-detail-list">
                    <div>
                      <div style={{ marginBottom: '10px' }}>
                        <span
                          style={{
                            color: ThemeColors.MainHeadings,
                            fontFamily: ThemeFontsFamily.Poppins,
                            fontWeight: ThemeFontWeight.Bold,
                            fontSize: FontSize.Title,
                            marginTop: '10px',
                          }}
                        >
                          {title}
                        </span>
                      </div>
                      <div>
                        <p
                          style={{
                            color: ThemeColors.YearParagraphColor,
                            fontFamily: ThemeFontsFamily.Poppins,
                            fontWeight: ThemeFontWeight.Regular,
                            fontSize: FontSize.large,
                            marginTop: '10px',
                          }}
                        >
                          {showBottomPara ? desc1 : desc}
                          {desc?.length > 100 &&
                            !showBottomPara &&
                            desc1?.length > 0 && (
                              <p className="btn" onClick={toggleTopPara}>
                                Show more
                              </p>
                            )}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {desc1 && showBottomPara && (
                <div className="year-content bottom-para">
                  <p
                    style={{
                      color: ThemeColors.YearParagraphColor,
                      fontFamily: ThemeFontsFamily.Poppins,
                      fontWeight: ThemeFontWeight.Regular,
                      fontSize: FontSize.large,
                      marginTop: '10px',
                    }}
                  >
                    {desc1}
                    {desc1?.length > 0 && (
                      <p className="btn" onClick={toggleBottomPara}>
                        Show less
                      </p>
                    )}
                  </p>
                </div>
              )}
            </div>
          </div>
        </Grid>
      </Grid>
    </>
  );
};

export default Year;
