import './style.css';
import React from 'react';
import { Grid, Typography } from '@mui/material';
import {
  FontSize,
  ThemeColors,
  ThemeFontWeight,
  ThemeFontsFamily,
} from '../../../utils/Theme';

const CareerInfo = () => {
  return (
    <Grid container>
      <Grid
        item
        lg={12}
        md={12}
        sm={12}
        xs={12}
        sx={{ backgroundColor: '#F4F4F4' }}
      >
        <div className="career-info">
          <Typography
            variant="h2"
            component="div"
            sx={{
              fontFamily: ThemeFontsFamily.Volkhov,
              fontSize: FontSize.Title,
              fontWeight: ThemeFontWeight.Bold,
              color: ThemeColors.BlackHeading,
              position: 'relative',
              overflow: 'initial',
            }}
          >
            Join Idemitsu: Fueling Your Career with Excellence
          </Typography>
          <Typography
            variant="p"
            component="div"
            sx={{
              fontFamily: ThemeFontsFamily.Poppins,
              fontSize: FontSize.large,
              fontWeight: ThemeFontWeight.Regular,
              color: ThemeColors.ParagraphColor,
              margin: '50px 0',
              overflow: 'initial',
              lineHeight: '34px',
            }}
          >
            Welcome to Idemitsu, where dedication meets innovation! Our mission
            is to support customers’ efficiency through constant innovation and
            readiness to serve; to find effective solutions that benefit both,
            our customers and ecology. As we expand our horizons, we seek
            exceptional individuals to join our vibrant team.
          </Typography>
          <Typography
            variant="h2"
            component="div"
            sx={{
              fontFamily: ThemeFontsFamily.Volkhov,
              fontSize: FontSize.Title,
              fontWeight: ThemeFontWeight.Bold,
              color: ThemeColors.BlackHeading,
              position: 'relative',
              overflow: 'initial',
            }}
          >
            Why Idemitsu?
          </Typography>
          <Typography
            variant="p"
            component="div"
            sx={{
              fontFamily: ThemeFontsFamily.Poppins,
              fontSize: FontSize.large,
              fontWeight: ThemeFontWeight.Regular,
              color: ThemeColors.ParagraphColor,
              margin: '50px 0',
              overflow: 'initial',
              lineHeight: '34px',
            }}
          >
            Innovation: Become a part of a workplace that thrives on innovation
            and values your creative contributions. At Idemitsu, we're shaping
            the future of lubrication solutions.
            <br />
            Collaboration: We believe in the strength of collaboration. Join a
            team that collaborates seamlessly to achieve success and fosters an
            inclusive and supportive work environment.
            <br />
            Professional Growth: Idemitsu is committed to the growth and
            development of our employees. Benefit from training programs, and a
            commitment to helping you reach new heights in your career.
          </Typography>
          <Typography
            variant="h2"
            component="div"
            sx={{
              fontFamily: ThemeFontsFamily.Volkhov,
              fontSize: FontSize.Title,
              fontWeight: ThemeFontWeight.Bold,
              color: ThemeColors.BlackHeading,
              position: 'relative',
              overflow: 'initial',
            }}
          >
            What We Seek
          </Typography>
          <Typography
            variant="p"
            component="div"
            sx={{
              fontFamily: ThemeFontsFamily.Poppins,
              fontSize: FontSize.large,
              fontWeight: ThemeFontWeight.Regular,
              color: ThemeColors.ParagraphColor,
              margin: '50px 0',
              overflow: 'initial',
              lineHeight: '34px',
            }}
          >
            We're on the lookout for individuals who are passionate, adaptable,
            and eager to contribute to Idemitsu's legacy of excellence. If
            you're inspired by challenges and ready to be a key player in our
            dynamic team, we would love to hear from you.
          </Typography>
          {/* <Typography
            variant="h2"
            component="div"
            sx={{
              fontFamily: ThemeFontsFamily.Volkhov,
              fontSize: FontSize.Title,
              fontWeight: ThemeFontWeight.Bold,
              color: ThemeColors.BlackHeading,
              position: 'relative',
              overflow: 'initial',
            }}
          >
            How to Apply
          </Typography>
          <Typography
            variant="p"
            component="div"
            sx={{
              fontFamily: ThemeFontsFamily.Poppins,
              fontSize: FontSize.large,
              fontWeight: ThemeFontWeight.Regular,
              color: ThemeColors.ParagraphColor,
              margin: '50px 0',
              overflow: 'initial',
              lineHeight: '34px',
            }}
          >
            Ready to embark on a new career journey with Idemitsu? Share your
            resume with us at{' '}
            <span
              style={{
                textDecoration: 'underline',
                color: '#E11737',
                cursor: 'pointer',
              }}
            >
              info.idemitsupk@idemitsu
            </span>
            .com. We look forward to welcoming you to the Idemitsu family!
          </Typography> */}
        </div>
      </Grid>
    </Grid>
  );
};

export default CareerInfo;
