import React, { createContext, useReducer, useEffect } from 'react';
import reducer from '../reducer/cartReducer';

const CartContext = createContext();

const getCartData = () => {
  const localCartData = localStorage.getItem('cartItems');
  if (localCartData === null) {
    return [];
  } else {
    return JSON.parse(localCartData);
  }
};

const initialState = {
  cart: getCartData(),
  totalItems: 0,
  totalPrice: 0,
  shippingFee: 500,
};

const CartProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    dispatch({ type: 'TOTAL-ITEMS' });
    dispatch({ type: 'TOTAL-PRICE' });
    localStorage.setItem('cartItems', JSON.stringify(state.cart));
  }, [state.cart]);

  const addToCart = (
    oilId,
    title,
    description,
    current_price,
    quantity,
    product_image
  ) => {
    dispatch({
      type: 'ADD-TO-CART',
      payload: {
        oilId,
        title,
        description,
        current_price,
        quantity,
        product_image,
      },
    });
  };

  const deleteItem = (oilId) => {
    dispatch({
      type: 'DELETE-ITEM',
      payload: oilId,
    });
  };

  const decreaseCount = (oilId) => {
    dispatch({
      type: 'DECREASE-ITEM',
      payload: oilId,
    });
  };

  const increaseCount = (oilId) => {
    dispatch({
      type: 'INCREASE-ITEM',
      payload: oilId,
    });
  };

  return (
    <CartContext.Provider
      value={{
        ...state,
        addToCart,
        deleteItem,
        decreaseCount,
        increaseCount,
        dispatch,
      }}
    >
      {children}
    </CartContext.Provider>
  );
};

export { CartProvider, CartContext };
