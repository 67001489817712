import './style.css';
import React, { useState, useEffect } from 'react';
import {
  GoogleMap,
  useJsApiLoader,
  Marker,
  InfoWindow,
} from '@react-google-maps/api';
import Box from './../../../../images/about-images/map-imgs/Rectangle-1.png';
import Circle from './../../../../images/about-images/map-imgs/Ellipse.png';
import Star from './../../../../images/about-images/map-imgs/Star.png';
import Triangle from './../../../../images/about-images/map-imgs/Polygon.png';
import Triangle1 from './../../../../images/about-images/map-imgs/Polygon-1.png';
import Box1 from './../../../../images/about-images/map-imgs/Rectangle-2.png';
import { Typography } from '@mui/material';
import {
  FontSize,
  ThemeColors,
  ThemeFontWeight,
  ThemeFontsFamily,
} from '../../../../utils/Theme';
const containerStyle = {
  // width: '400px',
  height: '370px',
};
const center = {
  lat: 30.37532,
  lng: 69.345116,
};


const marker = [
  {
    id: 1,
    name: 'Pakistan',
    position: { lat: 30.37532, lng: 69.345116 },
    images: [Box],
  },
  {
    id: 2,
    name: 'Norway',
    position: { lat: 60.472023, lng: 8.468946 },
    images: [Triangle],
  },
  {
    id: 3,
    name: 'Russia',
    position: { lat: 61.52401, lng: 105.318756 },
    images: [Box],
  },
  {
    id: 4,
    name: 'Netherlands',
    position: { lat: 105.318756, lng: 5.291266 },
    images: [Circle],
  },
  {
    id: 5,
    name: 'Germany',
    position: { lat: 51.165691, lng: 10.451526 },
    images: [Star, Box],
  },
  {
    id: 6,
    name: 'Qatar',
    position: { lat: 25.354826, lng: 51.183884 },
    images: [Circle],
  },
  {
    id: 7,
    name: 'Oman',
    position: { lat: 21.473534, lng: 55.9754 },
    images: [Circle],
  },
  {
    id: 8,
    name: 'Uae',
    position: { lat: 23.424076, lng: 53.847816 },
    images: [Circle, Box],
  },
  {
    id: 9,
    name: 'India',
    position: { lat: 20.593683, lng: 78.962883 },
    images: [Box1, Box],
  },
  {
    id: 10,
    name: 'Malaysia',
    position: { lat: 4.210484, lng: 101.975769 },
    images: [Star, Box],
  },
  {
    id: 11,
    name: 'Malaysia',
    position: { lat: 4.210484, lng: 101.975769 },
    images: [Star, Box],
  },
  {
    id: 12,
    name: 'Thailand',
    position: { lat: 15.870032, lng: 100.992538 },
    images: [Star, Box],
  },
  {
    id: 13,
    name: 'China',
    position: { lat: 35.86166, lng: 104.195396 },
    images: [Star, Box, Triangle1],
  },
  {
    id: 14,
    name: 'South Korea',
    position: { lat: 35.907757, lng: 127.766922 },
    images: [Box1],
  },
  {
    id: 15,
    name: 'Japan',
    position: { lat: 36.204823, lng: 138.25293 },
    images: [Box1, Star, Box, Triangle1, Circle],
  },
  {
    id: 16,
    name: 'Taiwan',
    position: { lat: 23.697809, lng: 120.960518 },
    images: [Box1, Star, Box],
  },
  {
    id: 17,
    name: 'Vietnam',
    position: { lat: 14.058324, lng: 108.277199 },
    images: [Box1, Circle, Box, Triangle],
  },
  {
    id: 18,
    name: 'Philippines',
    position: { lat: 12.879721, lng: 121.774017 },
    images: [Box],
  },
  {
    id: 19,
    name: 'Combodia',
    position: { lat: 12.565679, lng: 104.990967 },
    images: [Box1],
  },
  {
    id: 20,
    name: 'Singapore',
    position: { lat: 1.352083, lng: 103.819839 },
    images: [Box, Circle, Star],
  },
  {
    id: 21,
    name: 'Indonesia',
    position: { lat: -2.068641, lng: 120.411475 },
    images: [Box, Triangle1],
  },
  {
    id: 22,
    name: 'Australia',
    position: { lat: -25.274399, lng: 133.775131 },
    images: [Circle, Triangle1],
  },
  {
    id: 23,
    name: 'Canada',
    position: { lat: 56.130367, lng: -106.346771 },
    images: [Box1, Box1],
  },
  {
    id: 24,
    name: 'Usa',
    position: { lat: 37.09024, lng: -95.712891 },
    images: [Box, Circle, Star],
  },
  {
    id: 25,
    name: 'Mexico',
    position: { lat: 23.634501, lng: -102.552788 },
    images: [Box],
  },
  {
    id: 27,
    name: 'Brazil',
    position: { lat: -14.235004, lng: -51.925282 },
    images: [Box],
  },
];
const Map = (props) => {
  const [hoveredMarker, setHoveredMarker] = useState(null);
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: 'AIzaSyBZH1kbnWgKQypHB9Y3M6Qt15NeElb_wlE',
  });
  useEffect(() => {
    if (isLoaded) {
      setMarkersReady(true);
    }
  }, [isLoaded]);
  const [markersReady, setMarkersReady] = useState(false);
  const handleMarkerMouseOver = (marker) => {
    setHoveredMarker(marker);
  };
  const handleMarkerMouseOut = () => {
    setHoveredMarker(null);
  };
  

  return isLoaded && markersReady ? (
    <div className="idemitsu-map">
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={center}
        zoom={4}
      // onLoad={onLoad}
      // onUnmount={onUnmount}
      >
        {marker.map((marker) => (
          <Marker
            key={marker.id}
            position={marker.position}
            onMouseOver={() => handleMarkerMouseOver(marker)}
            onMouseOut={handleMarkerMouseOut}
          >
            {hoveredMarker === marker && (
              <InfoWindow onCloseClick={handleMarkerMouseOut}>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    flexDirection: 'column',
                  }}
                >
                  <Typography
                    color={ThemeColors.TitleColor}
                    variant="body2"
                    sx={{
                      fontSize: FontSize.medium,
                      fontFamily: ThemeFontsFamily.Poppins,
                      fontWeight: ThemeFontWeight.Medium,
                      maxWidth: '200px',
                    }}
                  >
                    {hoveredMarker.name}:
                  </Typography>
                  <div>
                    {marker.images.map((image, index) => (
                      <img
                        key={index}
                        src={image}
                        alt="img"
                        style={{ width: '30px', margin: '10px' }}
                      />
                    ))}
                  </div>
                </div>
              </InfoWindow>
            )}
          </Marker>
        ))}
        {/* Child components, such as markers, info windows, etc. */}
        <></>
      </GoogleMap>
    </div>
  ) : (
    <></>
  );
};

export default Map;
