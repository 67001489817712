const cartReducer = (state, action) => {
  if (action.type === 'ADD-TO-CART') {
    // Existing add-to-cart logic
    let { oilId, title, description, current_price, quantity, product_image } =
      action.payload;
    // tackle existing products
    const existingItems = state.cart.find((ele) => ele.oilId === oilId);
    if (existingItems) {
      const updatedItems = state.cart.map((ele) => {
        if (ele.id === oilId) {
          const newAmount = ele.quantity + quantity;
          return {
            ...ele,
            quantity: newAmount,
          };
        } else {
          return ele;
        }
      });
      return {
        ...state,
        cart: updatedItems,
      };
    } else {
      let cartProduct = {
        oilId: oilId,
        title: title,
        description: description,
        price: current_price,
        quantity: quantity,
        product_image: product_image,
      };
      return {
        ...state,
        cart: [...state.cart, cartProduct],
      };
    }
  }

  if (action.type === 'DELETE-ITEM') {
    // Existing delete-item logic
    let updatedCart = state.cart.filter((ele) => ele.oilId !== action.payload);
    return {
      ...state,
      cart: updatedCart,
    };
  }

  if (action.type === 'DECREASE-ITEM') {
    // Existing decrease-item logic
    const updatedQuantity = state.cart.map((ele) => {
      if (ele.oilId === action.payload) {
        const decQuantity = ele.quantity > 1 ? ele.quantity - 1 : 1;
        return {
          ...ele,
          quantity: decQuantity,
        };
      } else {
        return ele;
      }
    });
    return {
      ...state,
      cart: updatedQuantity,
    };
  }

  if (action.type === 'INCREASE-ITEM') {
    // Existing increase-item logic
    const updatedQuantity = state.cart.map((ele) => {
      if (ele.oilId === action.payload) {
        const incQuantity = ele.quantity + 1;
        return {
          ...ele,
          quantity: incQuantity,
        };
      } else {
        return ele;
      }
    });
    return {
      ...state,
      cart: updatedQuantity,
    };
  }

  if (action.type === 'TOTAL-ITEMS') {
    // Existing total-items logic
    const totalItems = state.cart.reduce((acc, ele) => {
      const { quantity } = ele;
      acc = acc + quantity;
      return acc;
    }, 0);
    return {
      ...state,
      totalItems: totalItems,
    };
  }

  if (action.type === 'TOTAL-PRICE') {
    // Existing total-price logic
    const totalPrice = state.cart.reduce((acc, ele) => {
      // console.log(ele);
      // console.log(acc);
      const { price, quantity } = ele;
      // console.log(price.slice(3).replace(/,/g, '') * quantity);
      // const amount = Number(price.slice(3));
      // console.log(amount);
      acc = acc + Number(price.slice(3).replace(/,/g, '')) * quantity;
      // console.log(acc);
      return acc;
    }, 0);
    return {
      ...state,
      totalPrice: totalPrice,
    };
  }

  // New handler for CLEAR_CART
  if (action.type === 'CLEAR_CART') {
    return {
      ...state,
      cart: [],
      totalItems: 0,
      totalPrice: 0,
    };
  }

  return state;
};

export default cartReducer;
