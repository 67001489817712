/* eslint-disable no-unused-vars */
import React, { useContext, useState } from 'react';
import { Link, useLocation, NavLink } from 'react-router-dom';
import {
  Drawer,
  IconButton,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import MenuRoundedIcon from '@mui/icons-material/MenuRounded';
import UserContext from '../../../context/UserContext';
import { CartContext } from '../../../context/cartContext';

const NavDrawer = (props) => {
  const { totalItems } = useContext(CartContext);
  const { token } = useContext(UserContext);
  // const { links } = props
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };
  return (
    <>
      <Drawer open={open} onClose={handleClose}>
        <List>
          <ListItemButton onClick={handleClose} divider>
            <ListItemIcon>
              <ListItemText>
                <ul className="navDrawer">
                  <li>
                    <NavLink to={'/'}>Home</NavLink>
                  </li>
                  <li>
                    <NavLink to={'/About'}>About</NavLink>
                  </li>
                  <li>
                    <NavLink to={'/Services'}>Services</NavLink>
                  </li>
                  <li>
                    <NavLink to={'/Products'}>Products</NavLink>
                  </li>
                  <li>
                    <NavLink to={'/Where to buy'}>Where to Buy</NavLink>
                  </li>
                  <li>
                    <NavLink to={'/Contact'}>Contact us</NavLink>
                  </li>
                  <li>
                    <NavLink to={'/Careers'}>Career</NavLink>
                  </li>
                  {token ? (
                    <>
                      <li>
                        <NavLink to={'/orderdetails/orderlist'}>
                          My Orders
                        </NavLink>
                      </li>
                      <li>
                        <NavLink to={'/profile'}>My Profile</NavLink>
                      </li>
                      <li>
                        <NavLink to={'/auth/logout'}>Logout</NavLink>
                      </li>
                    </>
                  ) : (
                    <li>
                      <NavLink to={'/auth/login'}>
                        <div className="basket-ellipse">
                          <span className="basket-icon-img">
                            <i
                              class="fa-solid fa-right-to-bracket"
                              title="Login"
                            ></i>
                          </span>
                        </div>
                      </NavLink>
                    </li>
                  )}
                  <li>
                    <NavLink to={'/OrderSummary'}>
                      <div className="basket-ellipse">
                        <span className="basket-icon-text">{totalItems}</span>
                        <span className="basket-icon-img">
                          <i className="fa-solid fa-basket-shopping"></i>
                        </span>
                      </div>
                    </NavLink>
                  </li>
                </ul>
              </ListItemText>
            </ListItemIcon>
          </ListItemButton>
        </List>
      </Drawer>
      <IconButton
        sx={{ marginLeft: 'auto', color: '#000000' }}
        onClick={handleOpen}
      >
        <MenuRoundedIcon />
      </IconButton>
    </>
  );
};

export default NavDrawer;
