import React from 'react';
import ImgOne from '../../../images/product-imgs/IFG3/PK_IFG3 5W-30_SP-GF-6A_4L_FS_4L_F.png';
import Layout from '../../../Layout/Layout';
import { Grid } from '@mui/material';
import ProductDetails from '../../productdetails';

const IFG33 = () => {
  return (
    <Layout>
      <Grid container>
        <Grid item xl={12} lg={12} md={12} xs={12}>
          <ProductDetails
            title=" IFG3 5W-30 SP/GF-6A Fully Synthetic 3L"
            price="Rs 5,988"
            des="Idemitsu Four-Wheelers' Gasoline Engine Oil - IFG3 5W-30 SP/GF-6A Fully Synthetic 3L"
            img={ImgOne}
          />
        </Grid>
      </Grid>
    </Layout>
  );
};

export default IFG33;
