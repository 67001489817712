/* Styre Technologies */
import React, { useState } from 'react';
// react-router-dom components
import { Link, useNavigate } from 'react-router-dom';

// Images
import bgImage from './../../../images/auth-imgs/maintainence-oil.png';
import { Button, Grid } from '@mui/material';
import userVerifyCode from '../../../services/authApis/verifyCode';
import Swal from 'sweetalert2';

function VerifyCode() {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [code, setCode] = useState('');

  const handleVerify = async (event) => {
    event.preventDefault();
    const verify = {
      email,
      password,
      code,
    };
    // console.log(verify);

    try {
      const resp = await userVerifyCode(verify);
      if (resp.status === 200) {
        Swal.fire({
          icon: 'success',
          title: 'account has been registered',
          showConfirmButton: false,
          timer: 1500,
        });
        navigate('/auth/login');
      }
      // return resp;
    } catch (error) {
      // console.log(error);
      Swal.fire({
        icon: 'warning',
        title: 'something went wrong',
        showConfirmButton: false,
        timer: 1500,
      });
    }
  };

  return (
    <>
      <Grid container bgcolor={'#F2F2F2'}>
        <Grid item lg={6} md={6} sm={12} xs={12}>
          <div className="login-bg">
            <img src={bgImage} alt={bgImage} />
          </div>
        </Grid>
        <Grid item mt={6} mb={6} lg={6} md={6} sm={12} xs={12}>
          <div>
            <form action="" onSubmit={handleVerify}>
              <div className="login-box">
                <div>
                  <h1 className="login-heading">Authentication</h1>
                </div>
                <div className="login-input-div">
                  <input
                    autoComplete="off"
                    className="login-input"
                    type="email"
                    name="email"
                    id="email"
                    placeholder="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
                <div className="login-input-div">
                  <input
                    autoComplete="off"
                    className="login-input"
                    type="password"
                    name="password"
                    id="password"
                    placeholder="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </div>
                <div className="login-input-div">
                  <input
                    autoComplete="off"
                    className="login-input"
                    type="text"
                    name="code"
                    id="code"
                    placeholder="verification code"
                    value={code}
                    onChange={(e) => setCode(e.target.value)}
                  />
                </div>
                <div className="login-input-button-div">
                  <Button
                    type="submit"
                    className="button"
                    variant="contained"
                    sx={{
                      backgroundColor: '#E11737',
                      padding: '20px 35px',
                      width: '180px',
                      height: '50px',
                      borderRadius: '100px',
                      color: '#ffffff',
                      fontSize: '18px',
                      textTransform: 'capitalize',
                      '&.MuiButton-root:hover': {
                        backgroundColor: '#E11737',
                      },
                    }}
                  >
                    register
                  </Button>
                </div>
                <div className="login-input-div">
                  <h3 className="span-signup-text">
                    Don`t have an account ?
                    <Link to="/auth/register">
                      <span className="span-signup"> Sign up</span>
                    </Link>
                  </h3>
                </div>
              </div>
            </form>
          </div>
        </Grid>
      </Grid>
    </>
  );
}

export default VerifyCode;
