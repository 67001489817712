import React from 'react';
import ImgOne from '../../../images/product-imgs/IFG3/PK_IFG3 0W-20_SP-GF-6A_4L_F.png';
// import ImgTwo from '../../../images/product-imgs/IFG3/PK_IFG3 0W-20_SP-GF-6A_4L_P.png';
import ProductDetails from '../../productdetails';
import Layout from '../../../Layout/Layout';
import { Grid } from '@mui/material';

const IFG31 = () => {
  return (
    <Layout>
      <Grid container>
        <Grid item xl={12} lg={12} md={12} xs={12}>
          <ProductDetails
            title="IFG3 0W-20 SP/GF-6A Fully Synthetic 4L"
            price="Rs 8,950"
            img={ImgOne}
            product_desc="Idemitsu Four Wheelers' Gasoline Engine Oil - IFG3
            High Performance engine oil, formulated with Nano Tailored Technology.
            For complex lubrication needs of compact and high precision engines.
            Best suited oil for optimal fuel economy and ideal for all vehicles, including hybrids."
            adv_tit="Distinctive Characteristics for Unmatched Advantages:"
            adv_1="High anti-wear protection"
            adv_2="Detergency at high temperature"
          />
        </Grid>
      </Grid>
    </Layout>
  );
};

export default IFG31;
