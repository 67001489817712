import React from 'react';
import Layout from '../../Layout/Layout';
import { Grid } from '@mui/material';
import ProductBanner from './bannersection';
import ProductRangeText from './productrangetextsection';
// import ProductsSection from './productsfilteraboxandcardsection';
import './style.css';
import FilterProduct from './productfinder/filterproduct';
const Products = () => {
  return (
    <Layout>
      <Grid container className="products-page">
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <ProductBanner />
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <ProductRangeText
            title="PRODUCTS RANGE"
            desc="As engines are the heart of vehicles, at Idemitsu, we insist on
          precisely tailoring formulas to meet your vehicle needs. As a
          lubricant expert, we use cutting-edge technology to create
          high-quality oil for unrivaled precision."
          />
        </Grid>
        {/* <Grid item lg={12} md={12} sm={12} xs={12}>
          <ProductsSection />
        </Grid> */}
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <FilterProduct />
        </Grid>
      </Grid>
    </Layout>
  );
};

export default Products;
