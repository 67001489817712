import './style.css';
import React, { useContext } from 'react';
import { Grid } from '@mui/material';
import Items from './items';
import ItemTotal from './itemtotal';
import Swal from 'sweetalert2';
import { useLocation, useNavigate } from 'react-router-dom';
import { CartContext } from '../../../../context/cartContext';
import UserContext from '../../../../context/UserContext';
import ItemsButton from '../../../../components/itembutton';

const OrderItems = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { token, userAddress } = useContext(UserContext);
  const { cart, totalPrice, shippingFee } = useContext(CartContext);
  const handleClick = (token) => {
    if (!token) {
      Swal.fire({
        icon: 'warning',
        title: 'Login Frist',
        showConfirmButton: false,
        timer: 2000,
      });
      navigate('/auth/login', { state: { from: location } }, { replace: true });
      // return <Navigate to={'/auth/login'} />;
    } else if (cart.length === 0) {
      Swal.fire({
        icon: 'info',
        title: 'please add items to proceed',
        showConfirmButton: false,
        timer: 1500,
      });
      navigate('/Products');
    } else if (!userAddress) {
      Swal.fire({
        icon: 'success',
        title: 'Items added',
        showConfirmButton: false,
        timer: 1500,
      });
      navigate('/OrderDetails');
    } else {
      navigate('/ShipTo');
    }
  };
  return (
    <Grid container>
      <Grid item mb={3} xl={8} lg={8} md={12} sm={12} xs={12}>
        <Items />
      </Grid>
      <Grid item xl={4} lg={4} md={12} sm={12} xs={12}>
        <ItemTotal totalPrice={totalPrice} shippingFee={shippingFee}>
          <ItemsButton
            btntxt="Proceed to checkout"
            onClick={() => handleClick(token)}
          />
        </ItemTotal>
      </Grid>
    </Grid>
  );
};

export default OrderItems;
