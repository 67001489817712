import './style.css';
import React from 'react';
import {
  Grid,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  TextField,
} from '@mui/material';
import Grease from '../../../../images/Industrial products images/industrial grease.jpg';
import Oils from '../../../../images/Industrial products images/industrial oils.png';
import Maintainence from '../../../../images/Industrial products images/maintainence oil.png';
import Marine from '../../../../images/Industrial products images/marine oil.jpg';
import Metal from '../../../../images/Industrial products images/metal working oil.png';
import Wind from '../../../../images/Industrial products images/wind mill oil.png';
import Industrial from '../../../../images/Industrial products images/industrial lubricants 3.jpg';
import {
  FontSize,
  ThemeColors,
  ThemeFontWeight,
  ThemeFontsFamily,
} from '../../../../utils/Theme';
import MessageOutlinedIcon from '@mui/icons-material/MessageOutlined';
import IndustryCard from './industrycard';

const Daphne = () => {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <Grid container>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <div className="industrial">
          <div className="daphne-cards">
            <IndustryCard
              src={Oils}
              alt={Oils}
              title="Daphne Industrial Oils"
              onClick={handleClickOpen}
            />
            <IndustryCard
              src={Maintainence}
              alt={Maintainence}
              title=" Daphne Maintenance Oil"
              onClick={handleClickOpen}
            />
            <IndustryCard
              src={Metal}
              alt={Metal}
              title="Daphne Metal Working Fluids"
              onClick={handleClickOpen}
            />
            <IndustryCard
              src={Grease}
              alt={Grease}
              title="Daphne Industrial Grease"
              onClick={handleClickOpen}
            />
            <IndustryCard
              src={Wind}
              alt={Wind}
              title="Windmill Oil"
              onClick={handleClickOpen}
            />
            <IndustryCard
              src={Industrial}
              alt={Industrial}
              title="Daphne Industrial Lubricant"
              onClick={handleClickOpen}
            />
            <IndustryCard
              src={Marine}
              alt={Marine}
              title="Daphne Marine oil"
              onClick={handleClickOpen}
            />
          </div>
          <>
            <Dialog
              open={open}
              onClose={handleClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <div className="dialogue-box">
                <DialogTitle
                  id="alert-dialog-title"
                  sx={{
                    width: '17em',
                    textAlign: 'center',
                  }}
                >
                  <div>
                    <MessageOutlinedIcon sx={{ fontSize: 40 }} />
                  </div>
                  {'Leave a message we will get back to you soon.'}
                </DialogTitle>
                <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                    <div className="industry-dialogue">
                      <div className="industry-dialogue-field">
                        <TextField
                          id="outlined-basic"
                          label="Email"
                          variant="outlined"
                          fullWidth
                        />
                      </div>
                      <div className="industry-dialogue-field">
                        <TextField
                          id="outlined-multiline-static"
                          label="Message"
                          multiline
                          fullWidth
                          rows={4}
                        />
                      </div>
                    </div>
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button
                    onClick={handleClose}
                    variant="contained"
                    sx={{
                      backgroundColor: '#000000',
                      padding: '10px 35px',
                      width: '85px',
                      height: '45px',
                      borderRadius: '10px',
                      color: ThemeColors.White,
                      fontFamily: ThemeFontsFamily.Poppins,
                      fontWeight: ThemeFontWeight.Medium,
                      fontSize: FontSize.medium,
                      textTransform: 'capitalize',
                      '&.MuiButton-root:hover': {
                        backgroundColor: ThemeColors.MainHeadings,
                      },
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    onClick={handleClose}
                    variant="contained"
                    sx={{
                      backgroundColor: '#E11737',
                      padding: '10px 35px',
                      width: '85px',
                      height: '45px',
                      borderRadius: '10px',
                      color: ThemeColors.White,
                      fontFamily: ThemeFontsFamily.Poppins,
                      fontWeight: ThemeFontWeight.Medium,
                      fontSize: FontSize.medium,
                      textTransform: 'capitalize',
                      '&.MuiButton-root:hover': {
                        backgroundColor: ThemeColors.MainHeadings,
                      },
                    }}
                  >
                    Send
                  </Button>
                </DialogActions>
              </div>
            </Dialog>
          </>
        </div>
      </Grid>
    </Grid>
  );
};

export default Daphne;
