/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import './style.css';
import { Swiper, SwiperSlide } from 'swiper/react';
import { FreeMode, Navigation, Thumbs } from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/free-mode';
import 'swiper/css/thumbs';
// import Img from "../../../../../images/oil-bottle-img.png";

const ProductImgsThumbSlider = (props) => {
  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  const { sliderData, img, image, productImage } = props;
  return (
    <>
      <div className="product-imgs-thumb-slider">
        <div className="pits-single-slider">
          <Swiper
            spaceBetween={10}
            navigation={true}
            speed={1050}
            loop={true}
            thumbs={{
              swiper:
                thumbsSwiper && !thumbsSwiper.destroyed ? thumbsSwiper : null,
            }}
            modules={[FreeMode, Navigation, Thumbs]}
            className="swiper-container"
          >
            <div className="swiper-wrapper">
              <SwiperSlide
                className="swiper-slide"
                // key={id}
                // virtualIndex={index}
              >
                <div className="pits-single-img">
                  <img src={productImage} alt="bottle" />
                </div>
              </SwiperSlide>
            </div>
            {/* <div className="swiper-wrapper">
              <SwiperSlide className="swiper-slide">
                <div className="pits-single-img">
                  <img src={img} alt={img} />
                  <img src={image} alt={image} />
                </div>
              </SwiperSlide>
            </div> */}
          </Swiper>
        </div>
        <div className="pits-thumbs-slider">
          <Swiper
            onSwiper={setThumbsSwiper}
            spaceBetween={10}
            slidesPerView={4}
            freeMode={true}
            speed={1050}
            loop={true}
            watchSlidesProgress={true}
            modules={[FreeMode, Navigation, Thumbs]}
            className="swiper-container"
            breakpoints={{
              '@0.00': {
                slidesPerView: 2,
                spaceBetween: 10,
              },
              '@0.75': {
                slidesPerView: 4,
                spaceBetween: 10,
              },
              '@1.00': {
                slidesPerView: 4,
                spaceBetween: 10,
              },
              '@1.50': {
                slidesPerView: 4,
                spaceBetween: 10,
              },
            }}
          >
            <div className="swiper-wrapper">
              <SwiperSlide
                className="swiper-slide"
                // key={id}
                // virtualIndex={index}
              >
                <div className="pits-thumb-img">
                  <img src={productImage} alt="bottle" />
                </div>
              </SwiperSlide>
            </div>
          </Swiper>
        </div>
      </div>
    </>
  );
};

export default ProductImgsThumbSlider;
