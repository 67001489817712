import './style.css';
import React, { useContext } from 'react';
import { Grid } from '@mui/material';
import UserContext from '../../../../../context/UserContext';

const ShipingPerson = () => {
  const { user } = useContext(UserContext);
  return (
    <Grid container mb={3}>
      <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
        <div className="ship-detail-container">
          <div className="person-detail-container">
            <div className="person-detail-left">
              <div className="person-detail">
                <div>
                  <h3 className="pd-heading">First Name</h3>
                </div>
                <div>
                  <p className="pd-desc">{user.firstName}</p>
                </div>
              </div>
              <div className="person-detail">
                <div>
                  <h3 className="pd-heading">Last Name</h3>
                </div>
                <div>
                  <p className="pd-desc">{user.lastName}</p>
                </div>
              </div>
              <div className="person-detail">
                <div>
                  <h3 className="pd-heading">Username</h3>
                </div>
                <div>
                  <p className="pd-desc">{user.username}</p>
                </div>
              </div>
              <div className="person-detail">
                <div>
                  <h3 className="pd-heading">Email Address</h3>
                </div>
                <div>
                  <p className="pd-desc">{user.email}</p>
                </div>
              </div>
              <div className="person-detail">
                <div>
                  <h3 className="pd-heading">Phone No</h3>
                </div>
                <div>
                  <p className="pd-desc">{user.phone}</p>
                </div>
              </div>
            </div>
            {/* <div className="person-detail-right">
              
              
             
              
              
            </div> */}
          </div>
        </div>
      </Grid>
    </Grid>
  );
};

export default ShipingPerson;
