import './style.css';
import * as React from 'react';
import { styled } from '@mui/material/styles';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';

import {
  ThemeColors,
  ThemeFontWeight,
  ThemeFontsFamily,
  FontSize,
} from '../../../../../utils/Theme';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid transparent`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
  backgroundColor: '#F9F9F9',
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIcon sx={{ fontSize: '2rem', color: '#000' }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor: '#F9F9F9',
  flexDirection: 'row',
  alignItems: 'center',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: '0',
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid #E11737',
  borderBottom: '1px solid #E11737',
}));

export default function CustomizedAccordions() {
  const [expanded, setExpanded] = React.useState('panel1');

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <div>
      <Accordion
        expanded={expanded === 'panel1'}
        onChange={handleChange('panel1')}
      >
        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
          <div className="accordion-heading">
            <p>What types of lubricants do you offer?</p>
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <Typography
            sx={{
              fontFamily: ThemeFontsFamily.Poppins,
              fontSize: FontSize.large,
              fontWeight: ThemeFontWeight.Regular,
              color: ThemeColors.ParagraphColor,
              padding: '10px 0',
            }}
            variant="p"
          >
            At Idemitsu, we offer a comprehensive range of lubricants, including
            automotive, industrial lubricants, and specialty products. Our
            product lineup is designed to cater to various industries and
            applications, ensuring that we can meet the diverse needs of our
            customers. If you have any specific queries or requirements, please
            feel free to reach out to us, and our dedicated sales staff will
            promptly get in touch with you to provide the best possible solution
            for your lubricant needs.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === 'panel2'}
        onChange={handleChange('panel2')}
      >
        <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
          <div className="accordion-heading">
            <p>Are your lubricants compatible with all car models?</p>
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <Typography
            sx={{
              fontFamily: ThemeFontsFamily.Poppins,
              fontSize: FontSize.large,
              fontWeight: ThemeFontWeight.Regular,
              color: ThemeColors.ParagraphColor,
              padding: '10px 0',
            }}
            variant="p"
          >
            At Idemitsu, we take pride in offering a wide range of lubricants,
            including our Passenger Car Motor Oils (PCMO), which are designed to
            be compatible with a vast majority of car models. Our PCMO range
            have been carefully formulated to meet the specific requirements of
            different engine metallurgy and Original Equipment Manufacturers
            (OEM) tribology standards, as well as international standards. This
            makes Idemitsu the preferred choice for all types of vehicles. For
            Japanese vehicles, our Idemitsu Gasoline Engine Range is
            particularly well-suited, as it is formulated specifically for these
            vehicles. Our unique formulation takes into account various factors,
            such as environmental conditions, driving habits, and stop-and-go
            scenarios, to provide an unparalleled driving experience with
            protection beyond imagination.
            <br />
            <br />
            With Idemitsu lubricants, you can trust that your car's engine will
            be well taken care of, ensuring optimal performance and longevity.
            If you have any specific concerns or questions about the
            compatibility of our lubricants with your car model, please don't
            hesitate to reach out to us, and our knowledgeable team will be
            happy to assist you.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === 'panel3'}
        onChange={handleChange('panel3')}
      >
        <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
          <div className="accordion-heading">
            <p>How often should I change the engine oil in my car?</p>
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <Typography
            sx={{
              fontFamily: ThemeFontsFamily.Poppins,
              fontSize: FontSize.large,
              fontWeight: ThemeFontWeight.Regular,
              color: ThemeColors.ParagraphColor,
              padding: '10px 0',
            }}
            variant="p"
          >
            The frequency of engine oil change for your car depends on several
            factors, importantly, fuel quality, your driving habits etc.. To
            ensure optimal performance and engine health, it is best to follow
            the car manufacturer's recommended oil change interval. These
            recommendations can typically be found in your vehicle's owner's
            manual.
            <br />
            <br />
            If you have specific questions or unique driving conditions that
            might require more frequent oil changes, don't hesitate to contact
            our customer support team. Our knowledgeable experts will be happy
            to assist you in determining the most suitable oil change schedule
            based on your vehicle's condition and your driving habits. At
            Idemitsu, we prioritize your car's well-being, and our team is
            always ready to provide the best guidance for maintaining your
            vehicle's engine in top shape.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === 'panel4'}
        onChange={handleChange('panel4')}
      >
        <AccordionSummary aria-controls="panel4d-content" id="panel4d-header">
          <div className="accordion-heading">
            <p>
              What are the benefits of using synthetic lubricants over
              conventional ones?
            </p>
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <Typography
            sx={{
              fontFamily: ThemeFontsFamily.Poppins,
              fontSize: FontSize.large,
              fontWeight: ThemeFontWeight.Regular,
              color: ThemeColors.ParagraphColor,
              padding: '10px 0',
            }}
            variant="p"
          >
            Synthetic lubricants offer superior performance in extreme
            temperatures, better engine protection, improved fuel efficiency,
            and longer-lasting properties compared to conventional oils.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === 'panel5'}
        onChange={handleChange('panel5')}
      >
        <AccordionSummary aria-controls="panel5d-content" id="panel5d-header">
          <div className="accordion-heading">
            <p>
              What are the signs that my car needs oil change or other lubricant
              service?
            </p>
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <Typography
            sx={{
              fontFamily: ThemeFontsFamily.Poppins,
              fontSize: FontSize.large,
              fontWeight: ThemeFontWeight.Regular,
              color: ThemeColors.ParagraphColor,
              padding: '10px 0',
            }}
            variant="p"
          >
            The signs indicating oil change include low oil level, dark and
            gritty oil on the dipstick, engine knocking noises, reduced fuel
            efficiency, and warning lights on the dashboard. Regular maintenance
            schedules should be followed to prevent issues.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === 'panel6'}
        onChange={handleChange('panel6')}
      >
        <AccordionSummary aria-controls="pane6d-content" id="panel6d-header">
          <div className="accordion-heading">
            <p>
              Do you provide technical support or assistance in case of
              lubricant-related issues?
            </p>
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <Typography
            sx={{
              fontFamily: ThemeFontsFamily.Poppins,
              fontSize: FontSize.large,
              fontWeight: ThemeFontWeight.Regular,
              color: ThemeColors.ParagraphColor,
              padding: '10px 0',
            }}
            variant="p"
          >
            Absolutely! Our customer support team is here to assist you with any
            lubricant-related inquiries or technical issues you may encounter.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === 'panel7'}
        onChange={handleChange('panel7')}
      >
        <AccordionSummary aria-controls="pane7d-content" id="panel7d-header">
          <div className="accordion-heading">
            <p>
              Can your lubricants improve fuel efficiency and engine
              performance?
            </p>
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <Typography
            sx={{
              fontFamily: ThemeFontsFamily.Poppins,
              fontSize: FontSize.large,
              fontWeight: ThemeFontWeight.Regular,
              color: ThemeColors.ParagraphColor,
              padding: '10px 0',
            }}
            variant="p"
          >
            Yes! Our high-quality lubricants are formulated to enhance fuel
            efficiency and optimize engine performance.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === 'panel8'}
        onChange={handleChange('panel8')}
      >
        <AccordionSummary aria-controls="pane8d-content" id="panel8d-header">
          <div className="accordion-heading">
            <p>
              What is the recommended storage method for car lubricants to
              maintain their quality?
            </p>
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <Typography
            sx={{
              fontFamily: ThemeFontsFamily.Poppins,
              fontSize: FontSize.large,
              fontWeight: ThemeFontWeight.Regular,
              color: ThemeColors.ParagraphColor,
              padding: '10px 0',
            }}
            variant="p"
          >
            Stores lubricants in a cool, dry place, away from direct sunlight
            and extreme temperatures. Ensure containers are tightly sealed to
            prevent contamination.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === 'panel9'}
        onChange={handleChange('panel9')}
      >
        <AccordionSummary aria-controls="pane9d-content" id="panel9d-header">
          <div className="accordion-heading">
            <p>Where can I purchase your product?</p>
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <Typography
            sx={{
              fontFamily: ThemeFontsFamily.Poppins,
              fontSize: FontSize.large,
              fontWeight: ThemeFontWeight.Regular,
              color: ThemeColors.ParagraphColor,
              padding: '10px 0',
            }}
            variant="p"
          >
            We are available nationwide, in all major cities in selected retail
            stores. You may drop us a message with your Province and City, and
            our team will further guide you.
          </Typography>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
