import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import HomePage from '../pages/homepage';
import About from '../pages/aboutpage';
import Services from '../pages/servicespage';
import Buy from '../pages/wheretobuy';
import Contact from '../pages/contactpage';
import Products from '../pages/products';
import ProductDetails from '../pages/productdetails';
// import FAQ from '../pages/contactpage/components/faqssection';
import Privacy from '../pages/privacy';
import Careers from '../pages/career';
import IFG31 from '../pages/navproductdetail/ifg3-1';
import IFG32 from '../pages/navproductdetail/ifg3-2';
import IFG33 from '../pages/navproductdetail/ifg3-3';
import IFD31 from '../pages/navproductdetail/ifd3-1';
import IFD32 from '../pages/navproductdetail/ifd3-2';
import IFD33 from '../pages/navproductdetail/ifd3-3';
import IRG3 from '../pages/navproductdetail/irg3-1';
import ATF from '../pages/navproductdetail/atf';
import CVTF from '../pages/navproductdetail/cvtf';
import ScrollToTop from './scroll';
import Industrial from '../pages/products/industrial';
import OrderSummary from '../pages/orderdetails/ordersummary';
import OrderDetails from '../pages/orderdetails/orderdetails';
import ShipTo from '../pages/orderdetails/shipto';
import Login from '../pages/auth/login';
import Logout from '../pages/auth/logout';
import Register from '../pages/auth/register';
import VerifyCode from '../pages/auth/verifyCode';
import ResetPassword from '../pages/auth/resetPassword';
import ConfirmNewPassword from '../pages/auth/newPassword';
import OrderList from '../pages/orderdetails/orderview';
import Profile from '../pages/profile';
import CookieConsent from '../components/cookies';
import { useCookies } from 'react-cookie';

const MainRouters = () => {
  const [cookies] = useCookies(['cookieConsent']);
  return (
    <>
      <BrowserRouter>
        <ScrollToTop />
        <Routes>
          <Route exact path="/" element={<HomePage />} />
          <Route path="about" element={<About />} />
          <Route path="Services" element={<Services />} />
          <Route path="products" element={<Products />} />
          <Route path="ProductDetails" element={<ProductDetails />} />
          <Route path="IFG31" element={<IFG31 />} />
          <Route path="IFG32" element={<IFG32 />} />
          <Route path="IFG33" element={<IFG33 />} />
          <Route path="IFD31" element={<IFD31 />} />
          <Route path="IFD32" element={<IFD32 />} />
          <Route path="IFD33" element={<IFD33 />} />
          <Route path="IRG3" element={<IRG3 />} />
          <Route path="ATF" element={<ATF />} />
          <Route path="CVTF" element={<CVTF />} />
          <Route path="Where to buy" element={<Buy />} />
          <Route path="Contact" element={<Contact />} />
          <Route path="Privacy" element={<Privacy />} />
          <Route path="Careers" element={<Careers />} />
          <Route path="Industrial" element={<Industrial />} />
          <Route path="OrderSummary" element={<OrderSummary />} />
          <Route path="OrderDetails" element={<OrderDetails />} />
          <Route path="ShipTo" element={<ShipTo />} />
          <Route path="auth/login" element={<Login />} />
          <Route path="auth/logout" element={<Logout />} />
          <Route path="auth/register" element={<Register />} />
          <Route path="auth/verifyCode" element={<VerifyCode />} />
          <Route path="auth/ResetPassword" element={<ResetPassword />} />
          <Route
            path="auth/ConfirmNewPassword"
            element={<ConfirmNewPassword />}
          />
          <Route path="orderdetails/orderlist" element={<OrderList />} />
          <Route path="profile" element={<Profile />} />
          {/* <Route path="/Faq" element={<FAQ />} /> */}
        </Routes>
      </BrowserRouter>
      {!cookies.cookieConsent && <CookieConsent />}
    </>
  );
};

export default MainRouters;
