import React from 'react';
import './style.css';
import Layout from '../../Layout/Layout';
import { Grid } from '@mui/material';
import Heading from '../../components/headings/Heading';

const Privacy = () => {
  return (
    <Layout>
      <Grid container>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <div style={{ marginTop: '6em' }}>
            <Heading desc="Privacy Policy" />
            <div className="privacy-details-container">
              <div className="privacy-details">
                <div className="privacy-details-right-card">
                  <p>
                    Idemitsu Lube Pakistan (Private) Limited., (referred to as
                    "we," "us," or "our"), is committed to protecting the
                    privacy and security of your personal information. This
                    Privacy Policy outlines our practices concerning the
                    collection, use, and disclosure of your data when you visit
                    our website. By accessing our website, you consent to the
                    terms outlined in this policy.
                    <br />
                    <br />
                  </p>
                  <div>
                    <div style={{ marginBottom: '20px' }}>
                      <strong>1. Information We Collect:</strong>
                    </div>
                    <div>
                      <strong>
                        We may collect various types of information, including
                        but not limited to:
                      </strong>
                    </div>
                    <div>
                      <ul>
                        <li>
                          Personal Information: This may include your name,
                          email address, phone number, and other identifiers
                          when voluntarily provided by you through forms or
                          other interactions on our website.
                        </li>
                        <li>
                          Usage Data: We may collect information about your
                          browsing behavior and how you interact with our
                          website.
                        </li>
                        <li>
                          Cookies: We use cookies and similar tracking
                          technologies to enhance your experience and collect
                          data about your visit.
                        </li>
                        <li>
                          Log Data: Our servers may automatically record
                          information about your use of our website, such as
                          your IP address, browser type, and the pages you
                          visit.
                        </li>
                      </ul>
                      <br />
                      <br />
                      <strong>The Heart of Technology</strong>
                    </div>
                  </div>
                  <div>
                    <div style={{ marginBottom: '20px' }}>
                      <strong>2. How We Use Your Information:</strong>
                    </div>
                    <div>
                      <strong>
                        We may use the collected information for various
                        purposes, including:
                      </strong>
                    </div>
                    <div>
                      <ul>
                        <li>
                          To provide, maintain, and improve our website and
                          services.
                        </li>
                        <li>To respond to your inquiries and requests.</li>
                        <li>
                          To send you updates, newsletters, and other
                          communications.
                        </li>
                        <li>
                          To analyze and monitor usage patterns and trends.
                        </li>
                        <li>To protect our rights, safety, and property.</li>
                      </ul>
                      <br />
                    </div>
                  </div>
                  <div>
                    <div style={{ marginBottom: '20px' }}>
                      <strong>3. Sharing Your Information:</strong>
                    </div>
                    <div>
                      <strong>We may share your information with:</strong>
                    </div>
                    <div>
                      <ul>
                        <li>
                          Service Providers: Third-party service providers who
                          assist us in providing our services.
                        </li>
                        <li>
                          Legal Requirements: If required by law or to protect
                          our rights, we may share your data.
                        </li>
                        <li>
                          Affiliates: We may share information with our
                          affiliated companies.
                        </li>
                      </ul>
                      <br />
                    </div>
                  </div>
                  <div>
                    <div style={{ marginBottom: '20px' }}>
                      <strong>4. Your Choices: </strong>
                    </div>
                    <div>
                      <strong>
                        You may have choices regarding the information you
                        provide to us. You can:
                      </strong>
                    </div>
                    <div>
                      <ul>
                        <li>Opt out of receiving marketing communications.</li>
                        <li>Disable cookies through your browser settings.</li>
                      </ul>
                      <br />
                    </div>
                  </div>
                  <div>
                    <div style={{ marginBottom: '20px' }}>
                      <strong>5. Security:</strong>
                    </div>
                    <div></div>
                    <div>
                      <ul>
                        <li>
                          We take reasonable steps to protect your information,
                          but please be aware that no data transmission over the
                          internet is completely secure.
                        </li>
                      </ul>
                      <br />
                    </div>
                  </div>
                  <div>
                    <div style={{ marginBottom: '20px' }}>
                      <strong>6. Changes to This Privacy Policy: </strong>
                    </div>
                    <div></div>
                    <div>
                      <ul>
                        <li>
                          We may update this Privacy Policy to reflect changes
                          to our practices. We encourage you to periodically
                          review this policy.
                        </li>
                      </ul>
                      <br />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Grid>
      </Grid>
    </Layout>
  );
};

export default Privacy;
