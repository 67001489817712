/* eslint-disable no-unused-vars */
import React, { useContext, useState } from 'react';
import './style.css';
// import SelectField from "../../../../components/reactselect";
import ProductImgsThumbSlider from './thumbslider';
import { NavLink, useLocation } from 'react-router-dom';
// import SelectField from '../../../../components/reactselect';
import Buttons from '../../../../components/button/Buttons';
import { CartContext } from '../../../../context/cartContext';
const BuyAddtoCart = () => {
  const { addToCart } = useContext(CartContext);
  const [quantity, setQuantity] = useState(1);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  // console.log(searchParams);
  const oilId = searchParams.get('oilId');
  const title = searchParams.get('title');
  const description = searchParams.get('description');
  const current_price = searchParams.get('price');
  const defaultImage = decodeURIComponent(searchParams.get('image'));
  // const abcd = defaultImage.replace(/ /g, '%20');
  // console.log(defaultImage);
  // console.log(abcd);

  // const { img, tit, des, price, image2 } = props;
  // const {
  //   img,
  //   tit,
  //   des,
  //   price,
  //   image2,
  //   adv_tit,
  //   adv_1,
  //   adv_2,
  //   product_desc,
  //   p_adv_1,
  //   p_adv_2,
  //   p_adv_3,
  //   p_adv_4,
  //   p_adv_5,
  //   b_adv_1,
  // } = props;
  // const { tit, des, image2 } = props;
  // const [dataliter, setDataLiter] = useState('');
  // const optionsLiter = ['2 Liter', '3 Liter', '4 Liter'];

  // Function to increase the count
  const increaseCount = () => {
    setQuantity(quantity + 1);
  };

  // Function to decrease the count
  const decreaseCount = () => {
    setQuantity(quantity > 0 ? quantity - 1 : 0);
  };
  // const handleLiter = (selectedLiter) => {
  //   setDataLiter(selectedLiter);
  // };

  // const desc = searchParams.get('desc');
  // const adv_title = searchParams.get('adv_title');
  // const adv1 = searchParams.get('adv1');
  // const adv2 = searchParams.get('adv2');

  // console.log(
  //   'Received Data:',
  //   product_image,
  //   title,
  //   description,
  //   current_price
  // );
  // const filterImgsData = (imgsData, searchImg) => {
  //   const result = imgsData.filter((item) => {
  //     return item.alt.includes(searchImg);
  //   });
  //   return result;
  // };
  // const sliderData = [...filterImgsData(sliderImgs, title)];

  return (
    <>
      <div className="product-add-to-cart-section-one" id="pd_top_page">
        <div className="product-add-to-cart-container">
          <div className="product-add-to-cart-top">
            <div className="product-add-to-cart-imgs-slider">
              <ProductImgsThumbSlider
                // image={product_image}
                // img={img}
                // image2={image2}
                productImage={defaultImage}
              />
            </div>
            <div className="product-add-to-cart-details">
              <div className="cart-product-heading">
                <h2>{title}</h2>
                {/* <h2>{tit}</h2> */}
                <h4>
                  <span>In-Stock</span>
                </h4>
              </div>
              <div className="cart-product-price">
                <p>
                  <span className="cp-heading">price: </span>
                  <span className="cp-total">{current_price}</span>
                  {/* <span className="cp-total">{price}</span> */}
                </p>
              </div>
              <div className="cart-product-qty">
                <div className="cp-qty-counter">
                  <p>Quantity:</p>
                  <div className="cp-qty-counter-group">
                    <button
                      onClick={decreaseCount}
                      className="cp-qty-counter-dec"
                    >
                      -
                    </button>
                    <input
                      className="cp-qty-counter-input"
                      type="text"
                      readOnly
                      value={quantity}
                    />
                    <button
                      onClick={increaseCount}
                      className="cp-qty-counter-inc"
                    >
                      +
                    </button>
                  </div>
                </div>
                {/* <div className="cp-qty-liter">
                  <p>Liter:</p>
                  <SelectField
                    placeholder={'2 Liter'}
                    handleChange={handleLiter}
                    optionsList={optionsLiter}
                    isDisabled={false}
                    value={dataliter}
                    border={'1px solid #D0D0D0'}
                    height={'48px'}
                    bgColor={'#fff'}
                  />
                </div> */}
              </div>
              <div className="cart-buttons">
                <div className="cart-buy-button">
                  <NavLink
                    to={`/OrderSummary`}
                    onClick={() =>
                      addToCart(
                        oilId,
                        title,
                        description,
                        current_price,
                        quantity,
                        defaultImage
                      )
                    }
                    className="product-details-link"
                  >
                    <Buttons btnBg="#E11737" buttonText="Buy Now" />
                  </NavLink>
                </div>
                {/* <div className="cart-buy-button">
                  <Buttons btnBg="#969696" buttonText="Add To Cart" />
                </div> */}
              </div>
            </div>
          </div>
          <div className="product-add-to-cart-bottom">
            <h2>Product Description</h2>
            <div>
              <p>
                {description}
                {/* {desc} */}
                {/* {des}
                {product_desc} */}
              </p>
            </div>
            <div>
              {/* <p>{adv_title}</p> */}
              {/* <p className="product-cart-list">{adv_tit}</p> */}
            </div>
            {/* <div>
              <ul>
                <li>
                  <p>{p_adv_1}</p>
                </li>
                <li>
                  <p>{p_adv_2}</p>
                </li>
                <li>
                  <p>{p_adv_3}</p>
                </li>
                <li>
                  <p>{p_adv_4}</p>
                </li>
                <li>
                  <p>{p_adv_5}</p>
                </li>
              </ul>
            </div> */}
            {/* <div>
              <ul>
                <li>
                  <p>{b_adv_1}</p>
                </li>
              </ul>
            </div> */}
            <div>
              {/* <p className="product-cart-list">{adv1}</p> */}
              {/* <p className="product-cart-list">{adv_1}</p> */}
              {/* <p className="product-cart-list">{adv2}</p> */}
              {/* <p className="product-cart-list">{adv_2}</p>
              <p className="product-cart-list">{b_adv_1}</p>
              <p className="product-cart-list">{p_adv_1}</p>
              <p className="product-cart-list">{p_adv_2}</p>
              <p className="product-cart-list">{p_adv_3}</p>
              <p className="product-cart-list">{p_adv_4}</p>
              <p className="product-cart-list">{p_adv_5}</p> */}
              {/* <ul>
                <li>
                  <p>{adv1}</p>
                  <p>{adv_1}</p>
                </li>
                <li>
                  <p>{adv2}</p>
                  <p>{adv_2}</p>
                </li>
              </ul> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BuyAddtoCart;
