/* Styre Technologies */
import React, { useContext, useState } from 'react';
import './style.css';

// react-router-dom components
import { Link, useLocation, useNavigate } from 'react-router-dom';

// Images
import bgImage from '../../../images/auth-imgs/marine-oil.jpg';
import { Button, Grid } from '@mui/material';
import userLogin from '../../../services/authApis/login';
import UserContext from '../../../context/UserContext';
import Swal from 'sweetalert2';
import { CartContext } from '../../../context/cartContext';

function Login() {
  const location = useLocation();
  const navigate = useNavigate();
  const from = location.state?.from?.pathname || '/';
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  // const [message, setMessage] = useState('');
  const { setUser, tokenLocalStorage, userLocalStorage } =
    useContext(UserContext);
  const { cart } = useContext(CartContext);
  // console.log(cart);

  //   endpoint for login user
  const handleLogin = async (event) => {
    event.preventDefault();
    const login = {
      email,
      password,
    };
    // console.log(login);

    try {
      const resp = await userLogin(login);
      if (resp.status === 200) {
        Swal.fire({
          icon: 'success',
          title: resp.body.response.message,
          showConfirmButton: false,
          timer: 1500,
        });
        if (cart.length > 0) {
          navigate('/OrderDetails');
        } else {
          navigate(from, { replace: true });
        }
      } else if (resp.status === 500) {
        Swal.fire({
          icon: 'warning',
          title: resp.body.message,
          showConfirmButton: false,
          timer: 1500,
        });
      }
      const body = resp.body;
      const token = body.result.token;
      // console.log(token);
      const data = body.result.userData;
      // console.log(data);
      tokenLocalStorage(token);
      userLocalStorage(data);
      setUser({ data });
      // console.log(setUser({ data }));
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <Grid container bgcolor={'#F2F2F2'}>
        <Grid item lg={6} md={6} sm={12} xs={12}>
          <div className="login-bg">
            <img src={bgImage} alt={bgImage} />
          </div>
        </Grid>
        <Grid item mt={6} mb={6} lg={6} md={6} sm={12} xs={12}>
          <div>
            <form action="" onSubmit={handleLogin}>
              <div className="login-box">
                <div>
                  <h1 className="login-heading">Login Here</h1>
                </div>
                <div className="login-input-div">
                  <input
                    required
                    autoComplete="off"
                    className="login-input"
                    type="email"
                    name="email"
                    id="email"
                    placeholder="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
                <div className="login-input-div">
                  <input
                    required
                    autoComplete="off"
                    className="login-input"
                    type="password"
                    name="password"
                    id="password"
                    placeholder="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </div>
                <div className="login-input-div">
                  <Link to="/auth/ResetPassword">
                    <h3 className="span-signup-text">Forgot password?</h3>
                  </Link>
                </div>
                <div className="login-input-button-div">
                  <Button
                    type="submit"
                    // onClick={onClick}
                    className="button"
                    variant="contained"
                    sx={{
                      backgroundColor: '#E11737',
                      padding: '10px 35px',
                      width: '180px',
                      height: '50px',
                      borderRadius: '100px',
                      color: '#ffffff',
                      fontSize: '18px',
                      textTransform: 'capitalize',
                      '&.MuiButton-root:hover': {
                        backgroundColor: '#E11737',
                      },
                    }}
                  >
                    Login
                  </Button>
                </div>
                <div className="login-input-div">
                  <h3 className="span-signup-text">
                    Don`t have an account ?
                    <Link to="/auth/register">
                      <span className="span-signup"> Sign up</span>
                    </Link>
                  </h3>
                </div>
              </div>
            </form>
          </div>
        </Grid>
      </Grid>
    </>
  );
}

export default Login;
