import './style.css';
import React, { useContext, useState } from 'react';
import { Button, Grid } from '@mui/material';
// import Select from 'react-select';
// import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/material.css';
import { useNavigate } from 'react-router-dom';
import {
  FontSize,
  ThemeColors,
  ThemeFontWeight,
  ThemeFontsFamily,
} from '../../../../../utils/Theme';
import UserContext from '../../../../../context/UserContext';
import adressApi from '../../../../../services/adressApi/adressApi';
import Swal from 'sweetalert2';

const Details = () => {
  const navigate = useNavigate();
  const { user, userAddressLocal } = useContext(UserContext);
  const [type, setType] = useState('2');
  const [street, setStreet] = useState('');
  const [street2, setStreet2] = useState('');
  const [city, setCity] = useState('');
  const [zipCode, setZipCode] = useState('');
  const [country, setCountry] = useState('');
  const [googlMapUrl, setGooglMapUrl] = useState('mapUrl');
  const [personId, setPersonId] = useState(user.personId);
  // console.log(personId);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const userData = {
      street,
      street2,
      city,
      zipCode,
      type,
      country,
      googlMapUrl,
      personId,
    };
    // console.log(userData);

    try {
      const resp = await adressApi(userData);
      const body = resp.body.addressID;
      const message = resp.body.message;
      Swal.fire({
        icon: 'success',
        title: message,
        showConfirmButton: false,
        timer: 1500,
      });
      navigate('/ShipTo');
      // console.log(resp);
      // console.log(body);
      userAddressLocal(body);
    } catch (error) {
      Swal.fire({
        icon: 'warning',
        title: 'something wrong',
        showConfirmButton: false,
        timer: 1500,
      });
      // console.log(error);
    }
  };

  return (
    <Grid container>
      <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
        <form action="" onSubmit={handleSubmit}>
          <div className="detail-container">
            <div className="detial-fields">
              <div>
                {/* <div className="detial-select-field">
                <label className="detial-label">Country Or Region</label>
                <Select
                  styles={customStyles}
                  placeholder="Country"
                  options={options}
                  onChange={handleChange}
                  // isDisabled={isDisabled}
                  //   className="select-field"
                  //   isSearchable={false}
                  value={value}
                />
              </div> */}
                <div className="detial-input-field">
                  <label className="detial-label">Street</label>
                  <input
                    required
                    className="detial-input"
                    type="text"
                    placeholder="Enter your street address"
                    value={street}
                    onChange={(e) => setStreet(e.target.value)}
                    // value={user.data.first_name}
                  />
                </div>
                <div className="detial-input-field">
                  <label className="detial-label">Apartment, street, etc</label>
                  <input
                    required
                    className="detial-input"
                    type="text"
                    placeholder="Enter your Apartment, street, etc"
                    value={street2}
                    onChange={(e) => setStreet2(e.target.value)}
                  />
                </div>
              </div>
              <div className="detail-flex">
                <div className="detial-input-field">
                  <div className="detial-input-field-csz">
                    <label className="detial-label">City</label>
                    <input
                      required
                      className="detial-input"
                      type="text"
                      placeholder="Enter city name"
                      value={city}
                      onChange={(e) => setCity(e.target.value)}
                    />
                  </div>
                </div>
                {/* <div className="detial-select-field">
                <label className="detial-label">Select State or Province</label>
                <Select
                  styles={customStyles}
                  placeholder="Select State/Province..."
                  options={options}
                  onChange={handleChange}
                  // isDisabled={isDisabled}
                  //   className="select-field"
                  //   isSearchable={false}
                  value={value}
                />
              </div> */}
                <div className="detial-input-field">
                  <div className="detial-input-field-csz">
                    <label className="detial-label">Country</label>
                    <input
                      required
                      className="detial-input"
                      type="text"
                      placeholder="Enter your country"
                      value={country}
                      onChange={(e) => setCountry(e.target.value)}
                    />
                  </div>
                </div>
                <div className="detial-input-field">
                  <div className="detial-input-field-csz">
                    <label className="detial-label">Zip Code</label>
                    <input
                      required
                      className="detial-input"
                      type="text"
                      placeholder="Enter zip code"
                      value={zipCode}
                      onChange={(e) => setZipCode(e.target.value)}
                    />
                  </div>
                </div>
                <div className="detial-input-field-block">
                  <div className="detial-input-field-csz">
                    <label className="detial-label">Type</label>
                    <input
                      disabled
                      required
                      className="detial-input"
                      type="text"
                      placeholder=" Enter your type"
                      value={type}
                      onChange={(e) => setType(e.target.value)}
                    />
                  </div>
                </div>
                <div className="detial-input-field-block">
                  <div className="detial-input-field-csz">
                    <label className="detial-label">mapurl</label>
                    <input
                      disabled
                      required
                      className="detial-input"
                      type="text"
                      placeholder="map"
                      value={googlMapUrl}
                      onChange={(e) => setGooglMapUrl(e.target.value)}
                    />
                  </div>
                </div>
                <div className="detial-input-field-block">
                  <div className="detial-input-field-csz">
                    <label className="detial-label">personId</label>
                    <input
                      disabled
                      required
                      className="detial-input"
                      type="text"
                      placeholder="personId"
                      value={personId}
                      onChange={(e) => setPersonId(e.target.value)}
                    />
                  </div>
                </div>
              </div>
              <div
                style={{
                  display: 'flex',
                  // justifyContent: 'space-between',
                  flexWrap: 'wrap',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
              >
                {/* <div className="detial-input-field">
                  <div className="detial-input-field-csz">
                    <label className="detial-label">Phone Number</label>
                    <input
                      required
                      className="detial-input"
                      type="text"
                      placeholder=" Enter Phone Number"
                      value={phone}
                      onChange={(e) => setPhone(e.target.value)}
                    />
                    <PhoneInput
                      country={'pk'}
                      placeholder="Enter phone number"
                      value={phone}
                      onChange={(e) => setPhone(e.value)}
                    />
                  </div>
                </div> */}
                <div className="detial-input-field">
                  <div className="order-detail-btn">
                    <Button
                      type="submit"
                      //   onClick={onClick}
                      className="button"
                      variant="contained"
                      sx={{
                        backgroundColor: '#E11737',
                        padding: '10px 35px',
                        // width: '185px',
                        height: '55px',
                        borderRadius: '100px',
                        color: ThemeColors.White,
                        fontFamily: ThemeFontsFamily.Poppins,
                        fontWeight: ThemeFontWeight.Regular,
                        fontSize: FontSize.regular,
                        textTransform: 'capitalize',
                        '&.MuiButton-root:hover': {
                          backgroundColor: '#E11737',
                        },
                      }}
                    >
                      Confirm address
                    </Button>
                  </div>
                </div>
              </div>
              {/* <div style={{ display: 'flex', flexWrap: 'wrap' }}>
              <div className="detial-input-field">
                <div className="detial-input-field-csz">
                  <label className="detial-label">Email Address</label>
                  <input
                    className="detial-input"
                    type="text"
                    placeholder=" Enter your email address"
                  />
                </div>
              </div>
              <div className="detial-input-field">
                <div className="detial-input-field-csz">
                  <label className="detial-label">Confirm Email Address</label>
                  <input
                    className="detial-input"
                    type="text"
                    placeholder="Confirm email address"
                  />
                </div>
              </div>
            </div> */}
            </div>
          </div>
        </form>
      </Grid>
    </Grid>
  );
};

export default Details;
