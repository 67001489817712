import React from 'react';
import ShippingDetail from './shippingdetail';
import Layout from '../../../Layout/Layout';
import OrderHeading from '../ordersummary/orderheading';
import { Grid } from '@mui/material';
// import OrderItems from '../ordersummary/orderitems';

const ShipTo = () => {
  return (
    <Layout>
      <Grid container p={4} className="order-details-page-bg">
        <Grid item xl={12} lg={12} md={12} xs={12}>
          <OrderHeading heading="Ship To" desc="Shiping to the person" />
          <ShippingDetail />
        </Grid>
      </Grid>
    </Layout>
  );
};

export default ShipTo;
