import React from 'react';
import './style.css';
import Layout from '../../Layout/Layout';
import { Grid } from '@mui/material';
import BuyAddtoCart from './components/buyaddtocart';
import ProductInfo from './components/productinfo';
const ProductDetails = (props) => {
  const {
    title,
    price,
    des,
    img,
    image2,
    adv_tit,
    adv_1,
    adv_2,
    adv_3,
    product_desc,
    p_adv_1,
    p_adv_2,
    p_adv_3,
    p_adv_4,
    p_adv_5,
    b_adv_1,
  } = props;
  return (
    <Layout>
      <Grid container className="products-details-page-bg">
        <Grid item xl={12} lg={12} md={12} xs={12}>
          <BuyAddtoCart
            tit={title}
            price={price}
            des={des}
            img={img}
            image2={image2}
            adv_tit={adv_tit}
            adv_1={adv_1}
            adv_2={adv_2}
            adv_3={adv_3}
            p_adv_1={p_adv_1}
            p_adv_2={p_adv_2}
            p_adv_3={p_adv_3}
            p_adv_4={p_adv_4}
            p_adv_5={p_adv_5}
            b_adv_1={b_adv_1}
            product_desc={product_desc}
          />
        </Grid>
        <Grid item xl={12} lg={12} md={12} xs={12}>
          <ProductInfo />
        </Grid>
        {/* <Grid item xl={12} lg={12} md={12} xs={12}>
          <SimilarProducts />
        </Grid> */}
      </Grid>
    </Layout>
  );
};

export default ProductDetails;
