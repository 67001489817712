import React from "react";
import { Grid } from "@mui/material";
import "./style.css";
import BannerSlider from "../../../components/bannerslider";
// import BannerImgOne from "../../../images/banner-imgs/home-banner-imgs/home-banner-img-01.jpg";
import BannerImgOne from "./../../../images/banner-imgs/home-banner-imgs/home-banner-img-02.jpg";
import BannerImgTwo from "./../../../images/banner-imgs/home-banner-imgs/home-banner-img-03.jpg";
import BannerImgThree from "./../../../images/banner-imgs/home-banner-imgs/home-banner-img-04.jpg";
const Slider = () => {
  const sliderData = [
    {
      id: 1,
      imageSrc: BannerImgOne,
      atl: "home-banner-img",
      bannerResp: "home-banner-resp",
      isShowTextContent:false
    },
    {
      id: 2,
      imageSrc: BannerImgTwo,
      atl: "home-banner-img",
      bannerResp: "home-banner-resp",
    },
    {
      id: 3,
      imageSrc: BannerImgThree,
      atl: "home-banner-img",
      bannerResp: "home-banner-resp",
    }
  ];
  return (
    <Grid container>
      <Grid item lg={12} md={12} xs={12} sm={12}>
        <BannerSlider sliderData={sliderData} />
      </Grid>
    </Grid>
  );
};

export default Slider;
