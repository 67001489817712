import { useEffect } from 'react';

const RecaptchaComponent = ({ siteKey, action, onToken }) => {
  useEffect(() => {
    const loadRecaptchaScript = () => {
      if (!window.grecaptcha) {
        const script = document.createElement('script');
        script.src =
          'https://www.google.com/recaptcha/enterprise.js?render=6LdNnOUpAAAAAC2XXY8FQ90-QItG6nQPCWa9x0h6';
        script.async = true;
        script.defer = true;
        script.onload = onLoadCallback;
        document.body.appendChild(script);
      } else {
        onLoadCallback();
      }
    };
    const onLoadCallback = () => {
      if (window.grecaptcha) {
        window.grecaptcha.enterprise.ready(() => {
          window.grecaptcha.enterprise
            .execute(siteKey, { action: action })
            .then((token) => {
              onToken(token);
            });
        });
      }
    };
    loadRecaptchaScript();
  }, [siteKey, action, onToken]);
  return null;
};
export default RecaptchaComponent;
