import React from 'react';
import { Grid } from '@mui/material';
import BannerSlider from '../../../components/bannerslider';
// import BannerImgOne from '../../../images/banner-imgs/where-to-buy-banner-imgs/where-to-buy-banner-img-01.jpg';
import BannerImgTwo from '../../../images/banner-imgs/where-to-buy-banner-imgs/where-to-buy-banner-img-02.jpg';
import './style.css';
const HeroSection = () => {
  const sliderData = [
    // {
    //   id: 1,
    //   imageSrc: BannerImgOne,
    //   alt: "where-to-buy-img",
    //   isShowTextContent: true,
    //   title:"Idemitsu Brand Motor Oil (IBMO)",
    //   titleClassName:"where-to-buy-title",
    //   subtitle:"Where to buy",
    //   subtitleClassName:"where-to-buy-subtitle",
    //   bannerResp: "where-to-buy-resp",
    // },
    {
      id: 1,
      imageSrc: BannerImgTwo,
      alt: 'where-to-buy-img',
      bannerResp: 'where-to-buy-resp',
      isShowTextContent: true,
      title: 'Idemitsu Brand Motor Oil (IBMO)',
      titleClassName: 'where-to-buy-title',
      subtitle: 'Where to buy',
      subtitleClassName: 'where-to-buy-subtitle',
    },
  ];
  return (
    <Grid container className="where-to-buy-banner">
      <Grid
        item
        display="flex"
        alignItems="center"
        lg={12}
        md={12}
        xs={12}
        sm={12}
        className="where-to-buy-banner"
      >
        <BannerSlider sliderData={sliderData} />
      </Grid>
    </Grid>
  );
};

export default HeroSection;
