import fetchAPIData from './fetchUtil';
import config from './config';
/**
 * Fetches all devcices for a company.
 *
 */
const getAllData = async (recaptchaToken) => {
  const url = `${config.baseUrl}/marketing`;
  //   console.log(url);
  const params = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ token: recaptchaToken }),
  };
  let data = null;
  try {
    const { body } = await fetchAPIData(url, params);
    data = body;
    // console.log(data);
  } catch (error) {
    throw new Error(error);
  }
  return data;
};
export default getAllData;
