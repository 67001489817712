/* eslint-disable no-unused-vars */
import React, { useContext } from 'react';
import './style.css';
import { Link, useLocation, NavLink } from 'react-router-dom';
import {
  Button,
  AppBar,
  Toolbar,
  Typography,
  Divider,
  Stack,
  Tabs,
  Tab,
  useTheme,
  useMediaQuery,
  Grid,
  createTheme,
} from '@mui/material';
import PersonPinIcon from '@mui/icons-material/PersonPin';
import Logo from '../../images/logo.png';
import NavDrawer from './drawer/Drawer';
import { CartContext } from '../../context/cartContext';
import UserContext from '../../context/UserContext';

const Navbar = () => {
  const location = useLocation();
  const theme = createTheme({
    breakpoints: {
      values: {
        md: 1190,
      },
    },
  });
  const { totalItems } = useContext(CartContext);
  const { token } = useContext(UserContext);
  // const theme = useTheme();
  const isMatch = useMediaQuery(theme.breakpoints.down('md'));
  // const location = useLocation();
  return (
    <Grid container>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <AppBar
          sx={{
            position: 'static',
            boxShadow: 'none',
            padding: '35px 30px 7px 0px',
          }}
          className="navbar-position"
        >
          <Toolbar
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
            }}
            className="menu-nav-bar"
          >
            <NavLink to={'/'}>
              <img
                className="logo"
                // style={{ width: '14em' }}
                src={Logo}
                alt="logo"
              />
            </NavLink>
            {isMatch ? (
              <div className="nav-drawer">
                <NavDrawer />
              </div>
            ) : (
              <Stack
                direction="row"
                spacing={2}
                divider={<Divider orientation="vertical" flexItem />}
                style={{ marginLeft: 'auto' }}
              >
                <ul className="nav">
                  <li>
                    <NavLink to={'/'}>Home</NavLink>
                  </li>
                  <li>
                    <NavLink to={'/About'}>About Us</NavLink>
                  </li>
                  <li>
                    <NavLink to={'/Services'}>Services</NavLink>
                  </li>
                  {/* <NavLink to={'/Products'}>Products</NavLink> */}
                  <li className="nav-link">
                    {/* <NavLink to={'/Products'}>Products</NavLink> */}
                    <NavLink to={'/Products'}>Products</NavLink>
                    {/* <div className="dropdown">
                      <ul>
                        <li className="dropdown-link">
                          <NavLink to={'/Products'}>All Products</NavLink>
                        </li>
                        <li className="dropdown-link">
                          <p className="p">
                            Automotive Lubricants
                            <i className="fas fa-caret-down"></i>
                          </p>
                          <div className="dropdown second">
                            <ul>
                              <li className="dropdown-link">
                                <p className="p">
                                  Idemitsu Four-Wheelers’ Gasoline Engine Oil
                                  (IFG3)
                                </p>
                                <div className="dropdown second">
                                  <ul>
                                    <li className="dropdown-link">
                                      <NavLink to={'/IFG31'}>
                                        IFG3 0W-20 SP/GF-6A (4L)
                                      </NavLink>
                                    </li>
                                    <li className="dropdown-link">
                                      <NavLink to={'/IFG32'}>
                                        IFG3 5W-30 SP/GF-6A (4L)
                                      </NavLink>
                                    </li>
                                  </ul>
                                </div>
                              </li>
                              <li className="dropdown-link">
                                <p className="p">
                                  Idemitsu Fur-Wheelers’ Dual Engine Oil (IFD3)
                                </p>
                                <div className="dropdown second">
                                  <ul>
                                    <li className="dropdown-link">
                                      <NavLink to={'/IFD31'}>
                                        IFD3 10W-40 SN/CF (4L)
                                      </NavLink>
                                    </li>
                                    <li className="dropdown-link">
                                      <NavLink to={'/IFD33'}>
                                        IFD3 20W-50 SP/CF (4L)
                                      </NavLink>
                                    </li>
                                  </ul>
                                </div>
                              </li>
                              <li className="dropdown-link">
                                <p className="p">
                                  Idemitsu Riders’ Gasoline Engine Oil (IRG3)
                                </p>
                                <div className="dropdown second">
                                  <ul>
                                    <li className="dropdown-link">
                                      <NavLink to={'/IRG3'}>
                                        IRG3 10W-40 JASO MA2 (1L)
                                      </NavLink>
                                    </li>
                                  </ul>
                                </div>
                              </li>
                              <li className="dropdown-link">
                                <p className="p">Transmission Fluids</p>
                                <div className="dropdown second">
                                  <ul>
                                    <li className="dropdown-link">
                                      <NavLink to={'/ATF'}>
                                        Idemitsu Multi Vehicle ATF 1L
                                      </NavLink>
                                    </li>
                                    <li className="dropdown-link">
                                      <NavLink to={'/CVTF'}>
                                        Idemitsu Multi Vehicle CVTF 1L
                                      </NavLink>
                                    </li>
                                  </ul>
                                </div>
                              </li>
                             
                            </ul>
                          </div>
                        </li>
                        <li className="dropdown-link">
                          <NavLink to={'/Industrial'}>
                            Industrial Lubricants
                          </NavLink>
                        </li>
                      </ul>
                    </div> */}
                  </li>
                  <li>
                    <NavLink to={'/Where to buy'}>Where to Buy</NavLink>
                  </li>
                  <li>
                    <NavLink to={'/Contact'}>Contact Us</NavLink>
                  </li>
                  <li>
                    <NavLink to={'/Careers'}>Career</NavLink>
                  </li>

                  <li>
                    <NavLink to={'/OrderSummary'}>
                      <div className="basket-ellipse">
                        <span className="basket-icon-text">{totalItems}</span>
                        <span className="basket-icon-img">
                          <i className="fa-solid fa-basket-shopping"></i>
                        </span>
                      </div>
                    </NavLink>
                  </li>
                  {token ? (
                    <>
                      {/* <li>
                        <NavLink to={'/auth/logout'}>Logout</NavLink>
                      </li> */}
                      <li className="nav-link">
                        <div className="basket-ellipse">
                          <span className="basket-icon-img">
                            <i className="fa-solid fa-gear"></i>
                          </span>
                        </div>
                        <div className="dropdown">
                          <ul>
                            <li className="dropdown-link">
                              <NavLink to={'/profile'}>My Profile</NavLink>
                              {/* <NavLink to={'/orderdetails/orderlist'}>
                            <div className="basket-ellipse">
                              <span className="basket-icon-img">
                                <i className="fa-solid fa-user"></i>
                              </span>
                            </div>
                          </NavLink> */}
                            </li>
                            <li className="dropdown-link">
                              <NavLink to={'/orderdetails/orderlist'}>
                                My Orders
                              </NavLink>
                            </li>
                            <li className="dropdown-link">
                              <NavLink to={'/auth/logout'}>Logout</NavLink>
                            </li>
                          </ul>
                        </div>
                      </li>
                    </>
                  ) : (
                    <li>
                      <NavLink
                        to={'/auth/login'}
                        state={{ from: location }}
                        replace
                      >
                        <div className="basket-ellipse">
                          <span className="basket-icon-img">
                            <i
                              class="fa-solid fa-right-to-bracket"
                              title="Login"
                            ></i>
                          </span>
                        </div>
                      </NavLink>
                    </li>
                  )}
                </ul>
              </Stack>
            )}
          </Toolbar>
        </AppBar>
      </Grid>
    </Grid>
  );
};

export default Navbar;
